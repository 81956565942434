import React from 'react'

const RightArrow = ({ sizeClass }) => {
    return (
        <>
            <svg width="23" height="23" className={sizeClass} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.88721 11.1383H17.7205" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.3037 4.72168L17.7204 11.1383L11.3037 17.555" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </>
    )
}

export default RightArrow
