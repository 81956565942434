import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { plantRequestList } from '../../Stores/Action';
import RequestConfirmation from './Request';
import './index.scss';

export default function PlantRequest() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(plantRequestList());
  }, []);
  const plantRequestData = useSelector(
    (state) => state.plantRequest?.request?.data,
  );
  const role = useSelector((state) => state.userDetails?.user?.rolename);
  const [requestShow, setRequestShow] = useState(false);
  const [plantId, setPlantId] = useState('');
  const [sentance, setSentance] = useState('Plant request');
  const [exists, setExists] = useState('');

  return (
    <div className="request-header">
      <Table className="request-page" hover>
        <thead>
          <tr>
            {role === 'company' && <th>Employee</th>}
            <th>Type</th>
            <th>Plant Name</th>
            <th>Company</th>
            <th>Location</th>
            <th>Unit Rate</th>
            <th>Penalty Rate</th>
            <th>Action</th>
            <th>Status</th>
            <th>Requested At</th>
          </tr>
        </thead>
        <tbody>
          {plantRequestData /* eslint operator-linebreak: ["error", "after"] */ &&
            plantRequestData.map((item) => (
              <tr
                onClick={() => {
                  setRequestShow(true);
                  setPlantId(item.uuid);
                  setExists(item.exists);
                  setSentance(`Plant ${item.action} request`);
                }}
              >
                {role === 'company' && <td>{item.employeeName}</td>}
                <td>{item.type}</td>
                <td>{item.plantName}</td>
                <td>{item.companyName}</td>
                <td>{item.location}</td>
                <td>
                  {Math.round((item.unitRate + Number.EPSILON) * 100) / 100}
                </td>
                <td>
                  <div>
                    &#8377;&nbsp;
                    {Math.round((item.penaltyRate + Number.EPSILON) * 100) /
                      100}
                  </div>
                </td>
                <td>{item.action}</td>
                {item?.status === 'pending' && (
                  <td>
                    <Button
                      variant="info"
                      type="button"
                      title="Pending Request"
                      class="btn btn-primary btn-circle btn-lg"
                    />
                  </td>
                )}
                {item.status === 'approve' && (
                  <td>
                    <Button
                      type="button"
                      title="Approved"
                      variant="success"
                      class="btn btn-success btn-circle btn-lg"
                    />
                  </td>
                )}
                {item.status === 'reject' && (
                  <td>
                    <Button
                      type="button"
                      title="Rejected"
                      variant="danger"
                      class="btn btn-danger btn-circle btn-lg"
                    />
                  </td>
                )}
                <td>{moment(item.createdAt).format('DD-MM-YYYY hh:mm')}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div>
        {requestShow && plantId !== '' ? (
          <RequestConfirmation
            show={requestShow}
            plantId={plantId}
            exists={exists}
            sentance={sentance}
            onHide={() => {
              setRequestShow(false);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
