import React from "react";

const LinkedinIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_162_4758)">
          <g clip-path="url(#clip1_162_4758)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 14.4555C12 13.3186 12.9189 12.396 14.0514 12.396C15.1832 12.396 16.1021 13.3186 16.1028 14.4555C16.1028 15.5925 15.1839 16.5343 14.0514 16.5343C12.9189 16.5343 12 15.5925 12 14.4555ZM28.9957 29.7037L29 29.703V23.4129C29 20.3357 28.3401 17.9654 24.7569 17.9654C23.0344 17.9654 21.8784 18.9144 21.4065 19.8141H21.3567V18.2526H17.9592V29.703H21.4969V24.0332C21.4969 22.5404 21.7787 21.0969 23.6202 21.0969C25.4346 21.0969 25.4616 22.8005 25.4616 24.129V29.7037H28.9957ZM12.2823 18.2514H15.8242V29.7017H12.2823V18.2514Z"
              fill="black"
            />
          </g>
          <rect
            x="0.5"
            y="1.33643"
            width="39"
            height="39"
            rx="19.5"
            stroke="#141414"
          />
        </g>
        <defs>
          <clipPath id="clip0_162_4758">
            <rect y="0.836426" width="40" height="40" rx="20" fill="white" />
          </clipPath>
          <clipPath id="clip1_162_4758">
            <rect
              width="17"
              height="18"
              fill="white"
              transform="translate(12 11.8364)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default LinkedinIcon;
