import React from 'react';
import { useNavigate } from 'react-router-dom';

function Prices() {
  const navigate = useNavigate();
  const handlepack = (selected) => {
    navigate('contact#main', { state: { selected } });
  };

  return (
    <section className="prices content-wrapper">
      <div id="hero">
        <div className="pricecards">
          <div
            aria-hidden
            className="pricecard shadow"
            onClick={() => handlepack('basic')}
          >
            <a
              href="#main"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <ul>
                <li className="pack">Basic</li>
                <li id="basic" className="price bottom-bar">
                  $199.99
                </li>
                <li className="bottom-bar">500 GW</li>
                <li className="bottom-bar">2 Clients Allowed</li>
                <li className="bottom-bar">Send up to 3 GB</li>
                <li>
                  <button type="button" className="btn">
                    Learn More
                  </button>
                </li>
              </ul>
            </a>
          </div>
          <div
            aria-hidden
            className="pricecard shadow"
            onClick={() => handlepack('premium')}
          >
            <a
              href="#main"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <ul>
                <li className="pack">Premium</li>
                <li id="professional" className="price bottom-bar">
                  $249.99
                </li>
                <li className="bottom-bar">1 TW</li>
                <li className="bottom-bar">5 Clients Allowed</li>
                <li className="bottom-bar">Send up to 10 GB</li>
                <li>
                  <button type="button" className="btn active-btn">
                    Learn More
                  </button>
                </li>
              </ul>
            </a>
          </div>
          <div
            aria-hidden
            className="pricecard shadow"
            onClick={() => handlepack('gold')}
          >
            <a
              href="#main"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <ul>
                <li className="pack">Gold</li>
                <li id="master" className="price bottom-bar">
                  $399.99
                </li>
                <li className="bottom-bar">2 TW</li>
                <li className="bottom-bar">10 Clients Allowed</li>
                <li className="bottom-bar">Send up to 20 GB</li>
                <li>
                  <button type="button" className="btn">
                    Learn More
                  </button>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Prices;
