export const config = {
  headers: {
    'Content-type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
};

export const imageConfig = {
  headers: {
    'Content-type': 'multipart/form-data',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
};
