import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, CloseButton } from 'react-bootstrap';
import input from '../../components/Form/input';
import { addPlant, plantType } from '../../Stores/Action';
import './index.scss';

function AddPlant(props) {
  const dispatch = useDispatch();
  const [Company, setCompany] = useState([{ uuid: '' }]);
  const companyData = useSelector((state) => state?.company);
  const data = companyData?.company?.data?.clientCompanyList;
  const successmessage = useSelector((state) => state.plantData?.success);
  useEffect(() => {
    if (data?.length > 0) {
      setCompany(data);
    }
  }, []);
  // const [companyId, setCompanyId] = useState('');
  // const handleCompany = (e) => {
  //   setCompanyId(e.target.value);
  // };
  const [initialValues, setInitialValues] = useState({});

  const [formErrors, setformErrors] = useState({});
  const [errorShow, setErrorShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const { onHide, types, svalue, continent } = props;
  const handleHide = async () => {
    onHide();
  };

  const validate = (values) => {
    const errors = {};
    const Name = /^[a-zA-Z0-9 _-]*$/;
    if (!values.name) {
      errors.name = 'Plant Name is required!';
    } else if (!Name.test(values.name)) {
      errors.name = 'This is not a valid Name format!';
    }
    if (!values.type) {
      errors.type = 'Select Type';
    }
    if (!values.location) {
      errors.location = 'Location is required!';
    }
    if (!values.latitude) {
      errors.latitude = 'Latitude is required!';
    }
    if (!values.longtitude) {
      errors.longtitude = 'Longtitude is required!';
    }
    if (!values.unitRate) {
      errors.unitRate = 'Unit Rate is required!';
    }
    if (!values.keyString) {
      errors.keyString = 'Key string is required!';
    }
    if (!values.dbPassword) {
      errors.dbPassword = 'Database password is required!';
    }
    if (!values.dbServer) {
      errors.dbServer = 'Database Server is required!';
    }
    if (!values.socketLink) {
      errors.socketLink = 'Socket Link is required!';
    }
    if (!values.clientCompanyId) {
      errors.clientCompanyId = 'Company is required!';
    }
    return errors;
  };
  useEffect(() => {
    setformErrors(validate(initialValues));
  }, [initialValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setformErrors(validate(initialValues));
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      dispatch(
        addPlant({
          plant: {
            continent,
            name: initialValues.name,
            type: initialValues.type,
            location: initialValues.location,
            latitude: initialValues.latitude,
            longtitude: initialValues.longtitude,
            penaltyRate: continent === 'IN' ? initialValues.penaltyRate : 0,
            unitRate: initialValues.unitRate,
            keyString: initialValues.keyString,
            dbServer: initialValues.dbServer,
            dbPassword: initialValues.dbPassword,
            socketLink: initialValues.socketLink,
            clientCompanyId: initialValues.clientCompanyId,
          },
          filter: {
            type: types,
            employeeId: svalue?.employee?.value,
            clientId: svalue?.client?.value,
            companyId: svalue?.company?.value,
            pageSize: 10,
            pageCount: 0,
          },
        }),
      );
      setIsSubmit(true);
      setErrorShow(false);
      setformErrors('');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setIsSubmit(false);
    setErrorShow(false);
    setInitialValues((prevalue) => ({
      ...prevalue,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (successmessage && isSubmit) {
      dispatch(plantType());
      onHide();
    }
  });

  return (
    <div>
      <Modal
        {...props}
        backdropClassName="back-drop-plant"
        className="plant-modal"
        style={{ height: '100vh' }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="plant-header">
            <h2
              style={{
                fontWeight: 'bolder',
                color: 'GrayText',
              }}
            >
              Add Plant
            </h2>
            <CloseButton style={{ float: 'right' }} onClick={handleHide} />
          </div>

          <Form>
            {input({
              handleChange,
              errorShow,
              value: initialValues.name,
              errors: formErrors.name,
              name: 'name',
              label: 'Plant Name',
              maxLength: 100,
            })}

            <div className="flex form-input-controller">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Type</label>
              <div className="div-select">
                <select
                  className="select-employee"
                  name="type"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option
                    name="Select Plant Type"
                    aria-label="select"
                    value=""
                    selected
                    style={{ opacity: '0.5px' }}
                  >
                    Select Plant Type
                  </option>
                  <option value="solar">Solar</option>
                  <option value="onshore_wind">OnShore Wind</option>
                  <option value="offshore_wind">OffShore Wind</option>
                </select>
                <p className="text-danger">
                  {errorShow ? formErrors.type : null}
                </p>
              </div>
            </div>
            {/* {input({
              handleChange,
              errorShow,
              value: initialValues.type,
              errors: formErrors.type,
              name: 'type',
              label: 'Type',
            })} */}

            {input({
              handleChange,
              errorShow,
              value: initialValues.location,
              errors: formErrors.location,
              name: 'location',
              label: 'Location',
            })}
            {input({
              handleChange,
              errorShow,
              value: initialValues.longtitude,
              errors: formErrors.longtitude,
              name: 'longtitude',
              label: 'Longtitude',
            })}
            {input({
              handleChange,
              errorShow,
              value: initialValues.latitude,
              errors: formErrors.latitude,
              name: 'latitude',
              label: 'Latitude',
            })}

            {input({
              handleChange,
              errorShow,
              value: initialValues.unitRate,
              errors: formErrors.unitRate,
              name: 'unitRate',
              label: 'Unit Rate',
            })}

            {/*  eslint-disable-next-line */}
            {continent === 'IN' &&
              input({
                handleChange,
                errorShow,
                value: initialValues.penaltyRate,
                errors: formErrors.penaltyRate,
                name: 'penaltyRate',
                label: 'Penalty Rate',
              })}

            <div className="flex form-input-controller">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Company Name</label>
              <div className="div-select">
                <select
                  className="select-employee"
                  name="clientCompanyId"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option
                    name="select company"
                    aria-label="select"
                    value=""
                    selected
                    style={{ opacity: '0.5px' }}
                  >
                    select company
                  </option>
                  {Company.map((company) => (
                    <option key={company.companyname} value={company.uuid}>
                      {company.companyname}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errorShow ? formErrors.clientCompanyId : null}
                </p>
              </div>
            </div>
            {input({
              handleChange,
              errorShow,
              value: initialValues.keyString,
              errors: formErrors.keyString,
              name: 'keyString',
              label: 'Key String',
            })}
            {input({
              handleChange,
              errorShow,
              value: initialValues.dbServer,
              errors: formErrors.dbServer,
              name: 'dbServer',
              label: 'DB Server',
            })}
            {input({
              handleChange,
              errorShow,
              value: initialValues.dbPassword,
              errors: formErrors.dbPassword,
              name: 'dbPassword',
              label: 'DB Password',
            })}
            {input({
              handleChange,
              errorShow,
              value: initialValues.socketLink,
              errors: formErrors.socketLink,
              name: 'socketLink',
              label: 'Socket Link',
            })}
          </Form>
          <div className="btn-reset">
            <Button
              type="button"
              style={{ margin: '0 24px' }}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddPlant;
