import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import {
  clientFilter,
  companyFilter,
  employeeFilter,
  penaltyCards,
  penaltyPieChart,
  penaltyChart,
  plantFilter,
  plantList,
  prediction,
} from '../../Stores/Action';
import filter from '../../components/assets/filter.png';
import refresh from '../../components/assets/resetFilter.png';

export default function Filter(props) {
  const { single, type, chartType, svalue, setSValue, continent } = props;
  const dispatch = useDispatch();
  const role = useSelector((state) => state.userDetails.user?.rolename);
  const [IsDisable, setIsDisable] = useState({
    plant: true,
    client: true,
    company: true,
  });

  const types = useSelector((state) => state?.plantType?.plantType?.data);
  const typesData = types?.map((t) => {
    const i = { value: t.type, label: t.label };
    return i;
  });
  const clients = useSelector(
    (state) => state?.clientFilter?.clientFilter?.data,
  );
  const clientsData = clients?.map((c) => {
    const i = { value: c.uuid, label: c.userName };
    return i;
  });
  const companys = useSelector(
    (state) => state?.companyFilter?.companyFilter?.data,
  );
  const companysData = companys?.map((e) => {
    const i = { value: e.uuid, label: e.companyname };
    return i;
  });
  const employees = useSelector(
    (state) => state?.employeeFilter?.employeeFilter?.data,
  );
  const employeesData = employees?.map((e) => {
    const i = { value: e.uuid, label: e.userName };
    return i;
  });
  const plants = useSelector((state) => state?.plantFilter?.plantFilter?.data);
  const plantsData = plants?.map((p) => {
    const i = { value: p.uuid, label: p.plantName };
    return i;
  });

  useEffect(() => {
    single(false);
    type(typesData?.[0]?.value);
    if (role === 'company') {
      setIsDisable({
        plant: true,
        client: true,
        company: true,
      });
      // dispatch(employeeFilter({ type: typesData?.[0]?.value }));
    }
    // dispatch(companyFilter({ type: typesData?.[0]?.value }));
    if (role === 'employee') {
      setIsDisable({
        plant: true,
        client: true,
        company: false,
      });
    }
    if (role === 'client') {
      setIsDisable({
        plant: true,
        client: false,
        company: false,
      });
    }

    // if (role === 'employee' || role === 'company') {
    //   dispatch(clientFilter({ type: typesData?.[0]?.value }));
    // }
    // dispatch(plantFilter({ type: typesData?.[0]?.value }));
  }, []);

  const handleReset = () => {
    setSValue({
      ...svalue,
      employee: '',
      client: '',
      company: '',
      plant: '',
    });
    single(false);

    type(svalue.type?.value);
    if (continent === 'IN') {
      dispatch(penaltyCards({ type: svalue.type?.value }));
      dispatch(penaltyPieChart({ type: svalue.type?.value }));
      dispatch(penaltyChart({ chartType, type: svalue.type?.value }));
    }
    if (role === 'company') {
      setIsDisable({
        plant: true,
        client: true,
        company: true,
      });
      dispatch(
        employeeFilter({
          continent,
          type: svalue.type?.value,
        }),
      );
    }
    if (role === 'employee' || role === 'client') {
      setIsDisable({
        plant: true,
        client: true,
        company: false,
      });
      dispatch(
        companyFilter({
          continent,
          type: svalue.type?.value,
        }),
      );
    }
    dispatch(
      plantList({
        continent,
        pageSize: 10,
        pageCount: 0,
        type: svalue.type?.value,
      }),
    );
  };
  const handleFilter = (e) => {
    dispatch(
      plantList({
        continent,
        type: svalue.type?.value,
        employeeId: svalue?.employee?.value,
        clientId: svalue?.client?.value,
        companyId: svalue?.company?.value,
      }),
    );
    if (continent === 'IN') {
      dispatch(
        penaltyCards({
          type: svalue.type?.value,
          employeeId: svalue?.employee?.value,
          clientId: svalue?.client?.value,
          companyId: svalue?.company?.value,
          plantId: e?.plantid || svalue?.plant?.value,
        }),
      );
      dispatch(
        penaltyPieChart({
          type: svalue.type?.value,
          employeeId: svalue?.employee?.value,
          clientId: svalue?.client?.value,
          companyId: svalue?.company?.value,
          plantId: e?.plantid || svalue?.plant?.value,
        }),
      );
      dispatch(
        penaltyChart({
          chartType,
          type: svalue.type?.value,
          employeeId: svalue?.employee?.value,
          clientId: svalue?.client?.value,
          companyId: svalue?.company?.value,
          plantId: e?.plantid || svalue?.plant?.value,
        }),
      );
    }
  };
  const handlePlantType = (e) => {
    single(false);
    type(e.value);
    if (role === 'company') {
      setIsDisable({
        plant: true,
        client: true,
        company: true,
      });
      dispatch(
        employeeFilter({
          continent,
          type: e.value,
        }),
      );
    }
    dispatch(
      companyFilter({
        continent,
        type: e.value,
      }),
    );
    if (role === 'employee' || role === 'client') {
      setIsDisable({
        plant: true,
        client: true,
        company: false,
      });
    }
    if (role === 'employee' || role === 'company') {
      dispatch(clientFilter({ continent, type: e.value }));
    }
    // dispatch(plantList({ pageSize: 10, pageCount: 0, type: e.value }));
    dispatch(
      plantFilter({
        continent,
        type: e.value,
      }),
    );
    // dispatch(
    //   penaltyPieChart({
    //     type: e.value,
    //   }),
    // );
    // dispatch(
    //   penaltyCards({
    //     type: e.value,
    //   }),
    // );
    // dispatch(
    //   penaltyChart({
    //     chartType,
    //     type: e.value,
    //   }),
    // );
  };

  const handleEmployeeList = (e) => {
    single(false);
    setIsDisable({
      company: false,
      client: true,
      plant: true,
    });

    dispatch(
      companyFilter({
        continent,
        type: svalue.type?.value,
        employeeId: e.value,
      }),
    );
  };
  const handleClientList = (e) => {
    single(false);
    setIsDisable({
      plant: false,
      client: false,
      company: false,
    });
    dispatch(
      plantFilter({
        type: svalue.type?.value,
        continent,
        clientId: e.value,
      }),
    );
  };

  const handleCompanyList = (e) => {
    single(false);
    if (role === 'client') {
      setIsDisable({
        company: false,
        client: false,
        plant: false,
      });
      dispatch(
        plantFilter({
          continent,
          type: svalue.type?.value,
          companyId: e.value,
        }),
      );
    }
    if (role === 'employee' || role === 'company') {
      setIsDisable({
        company: false,
        client: false,
        plant: true,
      });
      dispatch(
        clientFilter({
          continent,
          type: svalue.type?.value,
          companyId: e.value,
        }),
      );
    }
  };
  const handlePlantList = (e) => {
    single(true);
    dispatch(prediction(e.value));
    handleFilter({ plantid: e.value });
  };
  useEffect(() => {
    if (
      // eslint-disable-next-line
      companys?.length === 1 &&
      // eslint-disable-next-line
      continent &&
      // eslint-disable-next-line
      svalue.type?.value &&
      !IsDisable.company
    ) {
      single(false);
      if (role === 'client') {
        setIsDisable({
          company: false,
          client: false,
          plant: false,
        });
        dispatch(
          plantFilter({
            continent,
            type: svalue.type?.value,
            companyId: companysData?.[0].value,
          }),
        );
      } else {
        setIsDisable({
          company: false,
          client: false,
          plant: true,
        });
        dispatch(
          clientFilter({
            continent,
            type: svalue.type?.value,
            companyId: companysData?.[0].value,
          }),
        );
      }
      setSValue({ ...svalue, company: companysData?.[0] });
    }
  }, [companys]);
  useEffect(() => {
    if (clients?.length === 1 && continent && svalue.company) {
      single(false);
      setIsDisable({ plant: false, client: false, company: false });
      setSValue({ ...svalue, client: clientsData?.[0] });
      dispatch(
        plantFilter({
          continent,
          type: svalue.type?.value,
          clientId: clientsData?.[0].value,
        }),
      );
    }
  }, [clients]);
  useEffect(() => {
    if (plants?.length === 1 && svalue.type?.value && !IsDisable.plant) {
      single(true);
      setSValue({
        ...svalue,
        plant: plantsData?.[0],
      });
      dispatch(prediction(plantsData?.[0].value));
      handleFilter();
    }
  }, [plants]);

  const defaultSelectedValue = useMemo(() => {
    // to update the local state
    type(typesData?.[0]?.value);
    setSValue({ type: typesData?.[0]?.value });

    return typesData?.[0];
  }, []);

  return (
    <div>
      {/* <Container> */}
      <Row>
        <Col sm>
          <h6 className="filter-label" htmlFor="inputData">
            Plant Type
          </h6>
          <Select
            defaultValue={defaultSelectedValue}
            name="inputData"
            id="inputData"
            className="select"
            options={typesData}
            value={svalue.type}
            onChange={(e) => {
              setSValue({
                ...svalue,
                type: e,
                client: '',
                company: '',
                plant: '',
                employee: '',
              });
              handlePlantType(e);
            }}
          />
        </Col>

        {role === 'company' && (
          <Col sm>
            <h6 className="filter-label" htmlFor="inputData">
              Employee
            </h6>
            <Select
              id="inputData"
              name="inputData"
              className="select"
              options={employeesData}
              value={svalue.employee}
              onChange={(e) => {
                setSValue({
                  ...svalue,
                  employee: e,
                  client: '',
                  company: '',
                  plant: '',
                });
                handleEmployeeList(e);
              }}
            />
          </Col>
        )}

        <Col sm>
          <h6 className="filter-label" htmlFor="inputData">
            Company
          </h6>
          <Select
            name="inputData"
            id="inputData"
            className="select"
            isDisabled={IsDisable.company}
            options={companysData}
            value={svalue.company}
            onChange={(e) => {
              setSValue({ ...svalue, company: e, client: '', plant: '' });
              handleCompanyList(e);
            }}
          />
        </Col>
        {(role === 'employee' || role === 'company') && (
          <Col sm>
            <h6 className="filter-label" htmlFor="inputData">
              Client
            </h6>
            <Select
              name="inputData"
              id="inputData"
              className="select"
              isDisabled={IsDisable.client}
              options={clientsData}
              value={svalue.client}
              onChange={(e) => {
                setSValue({ ...svalue, client: e, plant: '' });
                handleClientList(e);
              }}
            />
          </Col>
        )}
        <Col sm>
          <h6 className="filter-label" htmlFor="inputData">
            Plants
          </h6>
          <Select
            name="inputData"
            className="select"
            id="inputData"
            isDisabled={IsDisable.plant}
            options={plantsData}
            value={svalue.plant}
            onChange={(e) => {
              setSValue({ ...svalue, plant: e });
              handlePlantList(e);
            }}
          />
        </Col>
        {/* <Col> */}
        {/* eslint-disable-next-line */}
        {/* <label className="reset-label" htmlFor="reset">reset filter</label> */}
        <button
          type="button"
          id="reset"
          className="reset-btn"
          title="Apply filters"
          onClick={() => {
            handleFilter();
          }}
        >
          <img height={20} width={20} src={filter} alt="refresh" />
        </button>
        <button
          type="button"
          id="reset"
          className="reset-btn"
          title="Reset filters"
          onClick={() => {
            handleReset();
          }}
        >
          <img height={20} width={20} src={refresh} alt="refresh" />
        </button>
        {/* </Col> */}
      </Row>
      {/* </Container> */}
    </div>
  );
}
