import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../services/configHeader';
import { CONTACT_REQUEST, CONTACT_SUCCESS, CONTACT_FAIL } from '../Types';

const authAPI = `${process.env.REACT_APP_API_URL}auth`;

const Contact = (payload) => async (dispatch) => {
  try {
    await dispatch({ type: CONTACT_REQUEST });

    const { data } = await axios.get(`${authAPI}/contact`, payload, config);
    await dispatch({ type: CONTACT_SUCCESS, payload: data.message });
    toast.success('We will connect with you soon');
  } catch (error) {
    dispatch({
      type: CONTACT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error('Error from server Side');
  }
};

export { Contact };
