import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../services/configHeader';
import reduxStore from '../Store';
import {
  PLANT_LIST_VIEW_REQUEST,
  PLANT_LIST_VIEW_SUCCESS,
  PLANT_LIST_VIEW_FAIL,
  PLANT_VIEW_REQUEST,
  PLANT_VIEW_SUCCESS,
  PLANT_VIEW_FAIL,
  PLANT_ADD_REQUEST,
  PLANT_ADD_SUCCESS,
  PLANT_ADD_FAIL,
  EDIT_PLANT_VIEW_REQUEST,
  EDIT_PLANT_VIEW_SUCCESS,
  EDIT_PLANT_VIEW_FAIL,
  DELETE_PLANT_REQUEST,
  DELETE_PLANT_SUCCESS,
  DELETE_PLANT_FAIL,
  MULTI_PLANT_ADD_REQUEST,
  MULTI_PLANT_ADD_SUCCESS,
  MULTI_PLANT_ADD_FAIL,
} from '../Types';
import { plantType } from './Filter';

const plantAPI = `${process.env.REACT_APP_API_URL}plant`;

const plantList = (plantData) => async (dispatch) => {
  try {
    dispatch({ type: PLANT_LIST_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const page = plantData
      ? `?pageSize=${plantData.pageSize}&pageCount=${plantData.pageCount}`
      : '';
    const employeefilter = plantData?.employeeId
      ? `&employeeId=${plantData?.employeeId}`
      : '';
    const clientfilter = plantData?.clientId
      ? `&clientId=${plantData?.clientId}`
      : '';
    const filter = plantData?.companyId
      ? `&companyId=${plantData.companyId}`
      : '';
    const type = plantData?.type ? `&type=${plantData.type}` : '';
    const continent = plantData?.continent
      ? `&continent=${plantData.continent}`
      : '';
    // const filter = plantData?.type ? `&type=${plantData.type}` : '';
    const { data } = await axios.get(
      `${plantAPI}/plantList${page}${type}${employeefilter}${clientfilter}${filter}${continent}`,
      config,
    );

    await dispatch({ type: PLANT_LIST_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLANT_LIST_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const singlePlant = (plantid) => async (dispatch) => {
  try {
    dispatch({ type: PLANT_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${plantAPI}/plantDetails?plantId=${plantid}`,
      config,
    );
    await dispatch({ type: PLANT_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLANT_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const addPlant = (plantData) => async (dispatch) => {
  try {
    dispatch({ type: PLANT_ADD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const continent = plantData?.plant?.continent
      ? `?continent=${plantData.plant.continent}`
      : '';
    const { data } = await axios.post(
      `${plantAPI}/addPlant${continent}`,
      plantData.plant,
      config,
    );
    await dispatch({ type: PLANT_ADD_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(plantList(plantData.filter));
  } catch (error) {
    dispatch(plantList(plantData.filter));

    dispatch({
      type: PLANT_ADD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const editPlant = (plantData) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_PLANT_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${plantAPI}/editPlant`,
      plantData.data,
      config,
    );
    await dispatch({ type: EDIT_PLANT_VIEW_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(plantList(plantData.filter));
  } catch (error) {
    dispatch(plantList(plantData.filter));

    dispatch({
      type: EDIT_PLANT_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const deletePlant = (plantData) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PLANT_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.delete(`${plantAPI}/deletePlant`, {
      ...config,
      data: plantData,
    });
    await dispatch({ type: DELETE_PLANT_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(plantList({ type: plantData.type }));
    dispatch(plantType());
  } catch (error) {
    dispatch({
      type: DELETE_PLANT_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const addMultiPlant = (plantData) => async (dispatch) => {
  try {
    dispatch({ type: MULTI_PLANT_ADD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.post(
      `${plantAPI}/addMultiPlant`,
      plantData,
      config,
    );
    dispatch(plantList());

    await dispatch({ type: MULTI_PLANT_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch(plantList());
    dispatch({
      type: MULTI_PLANT_ADD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

export {
  plantList,
  singlePlant,
  addPlant,
  editPlant,
  deletePlant,
  addMultiPlant,
};
