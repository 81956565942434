import axios from 'axios';
import { toast } from 'react-toastify';
import { config, imageConfig } from '../../services/configHeader';
import reduxStore from '../Store';
import {
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  PERMISSION_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_VIEW_FAIL,
  USER_VIEW_REQUEST,
  USER_VIEW_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_SUCCESS,
  IMAGE_UPDATE_FAIL,
  CLIENT_LIST_VIEW_REQUEST,
  CLIENT_LIST_VIEW_SUCCESS,
  CLIENT_LIST_VIEW_FAIL,
  CLIENT_VIEW_REQUEST,
  CLIENT_VIEW_SUCCESS,
  CLIENT_VIEW_FAIL,
  CLIENT_ADD_REQUEST,
  CLIENT_ADD_SUCCESS,
  CLIENT_ADD_FAIL,
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAIL,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  MULTI_CLIENT_ADD_REQUEST,
  MULTI_CLIENT_ADD_SUCCESS,
  MULTI_CLIENT_ADD_FAIL,
} from '../Types';

const clientAPI = `${process.env.REACT_APP_API_URL}client`;
const profileAPI = `${process.env.REACT_APP_API_URL}profile`;
const ResetPassword = (payload) => async (dispatch) => {
  try {
    dispatch({ type: USER_RESET_PASSWORD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${clientAPI}/resetPassword`,
      payload,
      config,
    );
    await dispatch({ type: USER_RESET_PASSWORD_SUCCESS, payload: data });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const profileView = () => async (dispatch) => {
  try {
    dispatch({ type: USER_VIEW_REQUEST });
    dispatch({ type: PERMISSION_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(`${profileAPI}/viewProfile`, config);

    await dispatch({ type: USER_VIEW_SUCCESS, payload: data });
    await dispatch({
      type: PERMISSION_SUCCESS,
      payload: data.data.permissions,
    });
  } catch (error) {
    dispatch({
      type: USER_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    dispatch({
      type: PERMISSION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    if (error.response && error.response.data.error.message) {
      toast.error(error.response.data.error.message);
    }

    // toast.error(
    //   error.response && error.response.data.error.message
    //     ? error.response.data.error.message
    //     : error.message,
    // );
  }
};

const profileImageUpdate = (formData) => async (dispatch) => {
  try {
    dispatch({ type: IMAGE_UPDATE_REQUEST });

    imageConfig.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    // config.headers['Content-type'] = 'multipart/form-data';

    const { data } = await axios.put(
      `${profileAPI}/editProfileImage`,
      formData,
      imageConfig,
    );
    await dispatch({ type: IMAGE_UPDATE_SUCCESS, payload: data });
    dispatch(profileView());
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: IMAGE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    dispatch({
      type: PERMISSION_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const profileUpdate = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${profileAPI}/editUser`,
      formData,
      config,
    );
    await dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    dispatch(profileView());
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    dispatch({
      type: PERMISSION_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const clientList = (pageData) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_LIST_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const page = pageData
      ? `?pageSize=${pageData.pageSize}&pageCount=${pageData.pageCount}`
      : '';
    const { data } = await axios.get(`${clientAPI}/clientList${page}`, config);

    await dispatch({ type: CLIENT_LIST_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CLIENT_LIST_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const clientView = (clientID) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${clientAPI}/clientDetail?clientId=${clientID}`,
      config,
    );
    await dispatch({ type: CLIENT_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CLIENT_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const addClient = (clientData) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_ADD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.post(
      `${clientAPI}/addClient`,
      clientData,
      config,
    );
    await dispatch({ type: CLIENT_ADD_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(clientList());
  } catch (error) {
    dispatch(clientList());

    dispatch({
      type: CLIENT_ADD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const deleteClient = (clientData) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CLIENT_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.delete(`${clientAPI}/deleteClient`, {
      ...config,
      data: clientData,
    });
    await dispatch({ type: DELETE_CLIENT_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(clientList());
  } catch (error) {
    dispatch({
      type: DELETE_CLIENT_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const clientEdit = (clientData) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_CLIENT_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${clientAPI}/editClient`,
      clientData,
      config,
    );
    await dispatch({ type: EDIT_CLIENT_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(clientList());
  } catch (error) {
    dispatch({
      type: EDIT_CLIENT_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const addMultiClient = (clientData) => async (dispatch) => {
  try {
    dispatch({ type: MULTI_CLIENT_ADD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.post(
      `${clientAPI}/addMultiClient`,
      clientData,
      config,
    );
    dispatch(clientList());

    await dispatch({ type: MULTI_CLIENT_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch(clientList());
    dispatch({
      type: MULTI_CLIENT_ADD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

export {
  ResetPassword,
  profileUpdate,
  profileView,
  clientList,
  clientView,
  addClient,
  deleteClient,
  clientEdit,
  addMultiClient,
  profileImageUpdate,
};
