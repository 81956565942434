import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';

import Dashboard from './Dashboard';
import EuDashboard from './Dashboard/EuDashboard';
import NotFound from '../components/NotFound';
import NoPagePermission from '../components/NoPagePermission';
import ResetPassword from './ResetPassword';
import Userprofile from './Userprofile';
// import Sidebar from '../components/Sidebar';
import Sidebar from '../components/Sidebar/FromFigma';
import Header from '../components/Header';
import TabManage from '../components/Sidebar/tabs';

import './index.scss';
import Plant from './Plant';
import Client from './Client';
import PlantPrediction from './Plant/PlantPrediction';
import PredictionTable from './Plant/PlantPrediction/PredictionTable';
import Employee from './Employee';
import { persistor } from '../Stores/Store';
import { logout, Welcome } from '../Stores/Action';
import Manage from './Manage';
import Company from './Company';
import Request from './Request';
import Setting from './Setting';
import { changeContinent } from '../Stores/Action/Continent';

function Pages() {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.userDetails.user);
  const welcome = useSelector((state) => state.welcome.message);
  const permission = useSelector((state) => state.permissions.data);
  // const dashboardPermission = permission
  //   ?.map((i) => i.name)
  //   .includes('DASHBOARD');
  const plantPermission = permission?.map((i) => i.name).includes('PLANT');
  const clientPermission = permission?.map((i) => i.name).includes('CLIENT');
  const companyPermission = permission
    ?.map((i) => i.name)
    .includes('CLIENT_COMPANY');
  const employeePermission = permission
    ?.map((i) => i.name)
    .includes('EMPLOYEE');
  const managePermission = permission?.map((i) => i.name).includes('EMPLOYEE');
  const requestPermission = permission?.map((i) => i.name).includes('REQUEST');
  const isTempPassword = userDetail?.isTempPassword;

  const [open, setOpen] = useState(true);
  const countryData = [
    { value: 'IN', label: 'India' },
    { value: 'EU', label: 'Europe' },
  ];
  const [country, setCountry] = useState({ value: 'IN', label: 'India' });
  useEffect(() => {
    dispatch(changeContinent(country.value));
  }, [country]);
  const tabs = TabManage();
  const [activeIndex, setActiveIndex] = useState(0);
  // const [stepHeight, setStepHeight] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [resetShow, setResetShow] = useState(false);
  const [resetTempShow, setResetTempShow] = useState(isTempPassword);

  const sidebarRef = useRef();
  const indicatorRef = useRef();
  const location = useLocation();
  useEffect(() => {
    dispatch(Welcome());
    // setTimeout(() => {
    //   const sidebarItem = sidebarRef?.current.querySelector('.__menu__item');
    //   indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
    // setStepHeight(sidebarItem.clientHeight);
    // }, 100);
  }, []);

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname.split('/')[2];
    const activeItem = tabs?.findIndex((item) => item.section === curPath);
    setActiveIndex(!curPath || activeItem === -1 ? 0 : activeItem);
  }, [location]);
  window.addEventListener('resize', () => {
    if (document.body.clientWidth < 900) {
      setOpen(false);
    } else setOpen(true);
  });
  const navigate = useNavigate();
  const showProfile = () => {
    setModalShow(true);
  };
  const indicate = {
    sidebarRef,
    indicatorRef,
    activeIndex,
    // stepHeight,
  };
  const handleReset = async () => {
    setModalShow(false);
    setResetShow(true);
  };
  useEffect(() => {
    if (!userDetail?.token) {
      dispatch(logout());
      navigate('/login');
      // navigate('/');
      // toast.info('User Logout Successfully');
    }
  }, [userDetail]);
  // } else {
  useEffect(() => {
    if (!welcome) navigate('/ServerNotFound');
  }, [welcome]);
  return (
    <PersistGate loading={null} persistor={persistor}>
      <div className={`main ${open ? 'open' : ''} dark`}>
        <div className="side_container">
          <Sidebar indicate={indicate} />
        </div>
        <div className="head_container">
          <Header
            countryData={countryData}
            country={country}
            setCountry={setCountry}
            title={tabs?.[activeIndex]?.display}
            showProfile={showProfile}
          />
        </div>
        <Userprofile
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleReset={handleReset}
        />
        <ResetPassword
          show={resetTempShow || resetShow}
          onHide={() => {
            setResetTempShow(false);
            setResetShow(false);
          }}
        />
        <div className="content">
          <Routes>
            <Route
              path="/"
              element={country.value === 'IN' ? <Dashboard /> : <EuDashboard />}
            />
            <Route
              path="/plant"
              element={plantPermission ? <Plant /> : <NoPagePermission />}
            />
            <Route
              path="/plant/prediction"
              element={
                plantPermission ? <PlantPrediction /> : <NoPagePermission />
              }
            />
            <Route
              path="/plant/predictionTable"
              element={
                plantPermission ? <PredictionTable /> : <NoPagePermission />
              }
            />
            <Route
              path="/employee"
              element={employeePermission ? <Employee /> : <NoPagePermission />}
            />
            <Route
              path="/client"
              element={clientPermission ? <Client /> : <NoPagePermission />}
            />
            <Route
              path="/company"
              element={companyPermission ? <Company /> : <NoPagePermission />}
            />
            <Route
              path="/manage"
              element={managePermission ? <Manage /> : <NoPagePermission />}
            />
            <Route
              path="/request"
              element={requestPermission ? <Request /> : <NoPagePermission />}
            />
            <Route
              path="/setting"
              element={requestPermission ? <Setting /> : <NoPagePermission />}
            />
            <Route
              path="/*"
              element={<NotFound message="Route does not exist" />}
            />
          </Routes>
        </div>
      </div>
    </PersistGate>
  );
  // }
}
export default Pages;
