import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../services/configHeader';
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_OTP_REQUEST,
  USER_OTP_SUCCESS,
  USER_OTP_FAIL,
  WELCOME_SUCCESS,
  WELCOME_FAIL,
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  PERMISSION_FAIL,
} from '../Types';

const authAPI = `${process.env.REACT_APP_API_URL}auth`;

const Welcome = (payload) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${authAPI}/welcome`, payload, config);
    await dispatch({ type: WELCOME_SUCCESS, payload: data.message });
  } catch (error) {
    dispatch({
      type: WELCOME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error('Error from server Side');
  }
};

const Signin = (userInfo) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    dispatch({ type: PERMISSION_REQUEST });

    const { data } = await axios.post(`${authAPI}/signIn`, userInfo, config);
    await dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    await dispatch({
      type: PERMISSION_SUCCESS,
      payload: data.data.permissions,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    dispatch({
      type: PERMISSION_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const logout = () => async (dispatch) => {
  dispatch({ type: USER_LOGOUT });
};

const ForgotPassword = (payload) => async (dispatch) => {
  try {
    dispatch({ type: USER_FORGOT_PASSWORD_REQUEST });

    const { data } = await axios.post(
      `${authAPI}/forgetPassword`,
      payload,
      config,
    );

    await dispatch({ type: USER_FORGOT_PASSWORD_SUCCESS, payload: data });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const OTP = (payload) => async (dispatch) => {
  try {
    dispatch({ type: USER_OTP_REQUEST });

    const send = {
      OTP: payload.otp,
      email: payload.email,
    };
    const { data } = await axios.post(
      `${authAPI}/otpverification`,
      send,
      config,
    );

    await dispatch({ type: USER_OTP_SUCCESS, payload: data });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: USER_OTP_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

export { Welcome, Signin, logout, ForgotPassword, OTP };
