import { useGSAP } from "@gsap/react";
import gsap, { ScrollTrigger } from "gsap/all";
import React, { useRef } from "react";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);
const Banner = () => {
  const heroImage = useRef(null);
  const container = useRef(null);
  useGSAP(
    () => {
      if (window.innerWidth > 575) {
        gsap.to(heroImage.current, {
          width: window.innerWidth > 1440 ? "120%" : "100%",
          height: window.innerWidth > 1440 ? "120%" : "100%",
          scrollTrigger: {
            trigger: heroImage.current,
            start: "top 70%",
            end: "top 10%",
            scrub: 1,
            invalidateOnRefresh: true,
            markers: false,
            pinSpacing: true,
          },
          onComplete: () => {
            ScrollTrigger.refresh();
          },
        });
      }
    },
    {
      scope: container,
    },
  );
  return (
    <div className="relative bg-codGray py-20 sm:py-10">
      <div className="pointer-events-none absolute inset-0 z-[0]">
        <img
          src="/assets/images/bg-abstract-pattern.svg"
          alt="hero-bg-pattern"
          className="w-full object-center"
        />
      </div>
      <div className="relative z-[1] flex h-[70vh] w-screen justify-center pt-28 md:h-fit md:pt-0">
        <div className="container">
          <div className="mx-auto flex max-w-[80%] flex-col items-center justify-center text-center xl:mb-10 sm:mb-5 sm:max-w-none">
            <h1 className="mb-8 font-epilogue text-14xl font-semibold text-textColor xxl:text-11xl xl:mb-6 sm:mb-4 sm:text-6xl">
              All-in-One AI Chatbot Solution for Business Growth
            </h1>
            <p className="mx-auto max-w-[70%] text-4xl font-normal text-textColor xxl:text-xl xxl:!leading-[1.5] sm:max-w-none sm:text-base">
              The only easy-to-use chatbot platform you need to streamline lead
              gen, customer support and other use cases – at scale and
              cost-effectively.
            </p>
            <div className="mt-12 flex items-center justify-center gap-8 xxl:gap-4 xl:mt-8 sm:mt-6 sm:w-full sm:flex-col">
              <Link to={"/"} className="btn-primary sm:w-fit">
                Book a demo
              </Link>
              {/* <Link to={'/'} className="btn-secondary sm:w-full">Try for free</Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container relative z-[1]" ref={container}>
        <div className="flex flex-col items-center">
          <div
            className="my-12 overflow-hidden rounded-radius-5 sm:mb-14 sm:mt-5"
            ref={heroImage}
          >
            <img
              src="/assets/images/hero-img.png"
              className="w-full"
              alt="hero-img"
            />
          </div>

          <div className="flex flex-col items-center justify-center gap-8">
            <h6 className="text-5xl font-semibold text-textColor xxl:text-4xl sm:text-base">
              Trusted by brands worldwide
            </h6>
            <div className="flex items-center gap-16 xxl:gap-10 sm:grid sm:grid-cols-2">
              <div className="max-w-36 shrink-0 xxl:max-w-28">
                <img src="/assets/images/brand-1.svg" alt="brand-1" />
              </div>
              <div className="max-w-36 shrink-0 xxl:max-w-28">
                <img src="/assets/images/brand-2.svg" alt="brand-2" />
              </div>
              <div className="max-w-36 shrink-0 xxl:max-w-28">
                <img src="/assets/images/brand-3.svg" alt="brand-3" />
              </div>
              <div className="max-w-36 shrink-0 xxl:max-w-28">
                <img src="/assets/images/brand-4.svg" alt="brand-4" />
              </div>
              <div className="max-w-36 shrink-0 xxl:max-w-28">
                <img src="/assets/images/brand-5.svg" alt="brand-5" />
              </div>
              <div className="max-w-36 shrink-0 xxl:max-w-28">
                <img src="/assets/images/brand-6.svg" alt="brand-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
