import { toast } from 'react-toastify';
import { CHANGE_CONTINENT } from '../Types';

const changeContinent = (payload) => async (dispatch) => {
  try {
    await dispatch({ type: CHANGE_CONTINENT, payload });
  } catch (error) {
    toast.error('Error changing continent', error);
  }
};

export { changeContinent };
