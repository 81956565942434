import axios from 'axios';
import moment from 'moment/moment';
import { config } from '../../services/configHeader';
import reduxStore from '../Store';
import {
  PENALTY_CHART_REQUEST,
  PENALTY_CHART_SUCCESS,
  PENALTY_CHART_FAIL,
  FORECAST_CARDS_REQUEST,
  FORECAST_CARDS_SUCCESS,
  FORECAST_CARDS_FAIL,
  PENALTY_PIE_CHART_REQUEST,
  PENALTY_PIE_CHART_SUCCESS,
  PENALTY_PIE_CHART_FAIL,
  PREDICTION_REQUEST,
  PREDICTION_SUCCESS,
  PREDICTION_FAIL,
} from '../Types';

const predictAPI = `${process.env.REACT_APP_API_URL}forecasting`;

const forecastingCards = (predictionData) => async (dispatch) => {
  try {
    dispatch({ type: FORECAST_CARDS_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const plant = predictionData?.plantId
      ? `&plantId=${predictionData?.plantId}`
      : '';
    const employeefilter = predictionData?.employeeId
      ? `&employeeId=${predictionData?.employeeId}`
      : '';
    const clientfilter = predictionData?.clientId
      ? `&clientId=${predictionData?.clientId}`
      : '';
    const filter = predictionData?.companyId
      ? `&companyId=${predictionData.companyId}`
      : '';
    const type = predictionData?.type ? `?type=${predictionData?.type}` : '';

    const min15Card = await axios.get(
      `${predictAPI}/card15min${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const min30Card = await axios.get(
      `${predictAPI}/card30min${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const hourCard = await axios.get(
      `${predictAPI}/card1hour${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const dayCard = await axios.get(
      `${predictAPI}/card24hour${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );

    // { penalty: "20215.617", block: "00:00 - 00:15" }

    const data = {
      min15: min15Card.data.data,
      min30: min30Card.data.data,
      hour: hourCard.data.data,
      day: dayCard.data.data,
    };
    await dispatch({ type: FORECAST_CARDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FORECAST_CARDS_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
  }
};

const penaltyChart = (penaltyChartData) => async (dispatch) => {
  try {
    dispatch({ type: PENALTY_CHART_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const type = penaltyChartData?.type
      ? `?type=${penaltyChartData?.type}`
      : '';
    const plant = penaltyChartData?.plantId
      ? `&plantId=${penaltyChartData?.plantId}`
      : '';
    const employeefilter = penaltyChartData?.employeeId
      ? `&employeeId=${penaltyChartData?.employeeId}`
      : '';
    const clientfilter = penaltyChartData?.clientId
      ? `&clientId=${penaltyChartData?.clientId}`
      : '';
    const filter = penaltyChartData?.companyId
      ? `&companyId=${penaltyChartData.companyId}`
      : '';
    const { data } = await axios.get(
      `${predictAPI}/${penaltyChartData.chartType}Chart${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    await dispatch({ type: PENALTY_CHART_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: PENALTY_CHART_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
  }
};

const penaltyPieChart = (pieChartData) => async (dispatch) => {
  try {
    dispatch({ type: PENALTY_PIE_CHART_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;

    const type = pieChartData?.type ? `?type=${pieChartData?.type}` : '';
    const plant = pieChartData?.plantId
      ? `&plantId=${pieChartData?.plantId}`
      : '';
    const employeefilter = pieChartData?.employeeId
      ? `&employeeId=${pieChartData?.employeeId}`
      : '';
    const clientfilter = pieChartData?.clientId
      ? `&clientId=${pieChartData?.clientId}`
      : '';
    const filter = pieChartData?.companyId
      ? `&companyId=${pieChartData.companyId}`
      : '';
    // const type = pieChartData?.type ? `?type=${pieChartData.type}` : '';
    const { data } = await axios.get(
      `${predictAPI}/coordination${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    await dispatch({ type: PENALTY_PIE_CHART_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: PENALTY_PIE_CHART_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
  }
};

const prediction = (plant) => async (dispatch) => {
  try {
    dispatch({ type: PREDICTION_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const today = new Date();
    const date = moment(today).format('YYYY-MM-DD');
    const { data } = await axios.get(
      `${predictAPI}/predictionList?date=${date}&plantId=${plant}`,
      config,
    );
    await dispatch({ type: PREDICTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PREDICTION_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
  }
};

export { forecastingCards, penaltyChart, penaltyPieChart, prediction };
