import React from 'react'

const Mail = () => {
    return (
        <>
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.332 35.1532H11.6654C6.66536 35.1532 3.33203 32.6532 3.33203 26.8198V15.1532C3.33203 9.31982 6.66536 6.81982 11.6654 6.81982H28.332C33.332 6.81982 36.6654 9.31982 36.6654 15.1532V26.8198C36.6654 32.6532 33.332 35.1532 28.332 35.1532Z" stroke="#141414" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M28.3346 15.9878L23.118 20.1545C21.4013 21.5211 18.5846 21.5211 16.868 20.1545L11.668 15.9878" stroke="#141414" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </>
    )
}

export default Mail
