import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import AddPlant from './addPlant';
import { plantList } from '../../Stores/Action';
import './index.scss';
import upload from '../../components/assets/upload.svg';
import PlantDetails from './plantDetails';
import AddMultiPlant from './addMultiPlants';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import next from '../../components/assets/next.svg';
import previous from '../../components/assets/previous.svg';
import search from '../../components/assets/search.svg';

const itemsPerPage = 10;

export default function Plant(props) {
  const { types, svalue, continent } = props;
  const dispatch = useDispatch();
  const role = useSelector((state) => state.userDetails?.user?.rolename);
  const pers = useSelector((state) => state.permissions?.data);
  let i = [];
  pers?.map((p) => {
    if (p.name === 'PLANT') {
      i = p.actions;
      return i;
    }
    return null;
  });

  const pData = useSelector((state) => state.plantData?.plant);
  const plantdata = pData ? pData?.data?.plantList : null;
  const totalLength = pData?.data?.count;
  const [plantId, setPlantId] = useState('');
  const [q, setQ] = useState('');
  const [searchParam] = useState([
    'plantName',
    'type',
    'companyName',
    'employeeName',
    'penaltyRate',
    'unitRate',
  ]);
  const [value, setValue] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [modal, setModal] = useState('view');
  const [single, setSingle] = useState(true);
  const [files, setFiles] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [disable, isDisable] = React.useState(true);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const totalCount = Math.ceil(totalLength / itemsPerPage);
    setPageCount(totalCount);
  }, [totalLength]);
  function find(items) {
    // eslint-disable-next-line
    return items.filter((item) => {
      // eslint-disable-next-line
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
  // useEffect(() => {
  //   setCurrentPage(0);
  //   if (types?.length) {
  //     dispatch(
  //       plantList({
  //         type: types,
  //         employeeId: svalue.employee?.value,
  //         clientId: svalue.client?.value,
  //         companyId: svalue.company?.value,
  //         pageSize: itemsPerPage,
  //         pageCount: 0,
  //       }),
  //     );
  //   }
  // }, [svalue]);

  useEffect(() => {
    if (types?.length) {
      dispatch(
        plantList({
          type: types,
          continent,
          employeeId: svalue.employee?.value,
          clientId: svalue.client?.value,
          companyId: svalue.company?.value,
          pageSize: itemsPerPage,
          pageCount: currentPage,
        }),
      );
    }
  }, [currentPage]);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <div className="plant">
      <div className="plant-header">
        <h6
          style={{
            fontWeight: 'bold',
          }}
        >
          Plant Details
        </h6>
        <div>
          <div className="search">
            <label htmlFor="search-form">
              <img src={search} alt="search" className="search-icon" />
              <input
                type="search"
                name="search-form"
                id="search-form"
                className="search-input"
                placeholder="Search Plant..."
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
              {/* <span className="sr-only">Search Plant here</span> */}
            </label>
          </div>
        </div>
        {i &&
          i?.map(
            (action) =>
              // eslint-disable-next-line
              action === 'add' && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <button
                    type="button"
                    className="add-btn"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Add Plant"
                    onClick={() => {
                      setPlantId('');
                      setSingle(true);
                      setModalShow(true);
                    }}
                  >
                    +
                  </button>
                  <label
                    htmlFor="plant-add"
                    className="multi-upload"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Upload Plant Data"
                  >
                    <img src={upload} alt="upload" />
                    <input
                      id="plant-add"
                      type="file"
                      accept=".csv,.xlsx,.xls"
                      value={value}
                      disabled
                      onChange={(e) => {
                        setSingle(false);
                        setFiles(e.target);
                        setValue(e.target.value);
                        setPlantId('');
                        setModalShow(true);
                      }}
                    />
                  </label>
                </div>
              ),
          )}
      </div>
      <Table className="plant-page" hover>
        <thead>
          <tr>
            {/* <th aria-label="type" /> */}
            <th>Plant Name</th>
            {/* <th aria-label="type" /> */}
            <th>Type</th>
            <th>Location</th>
            {role === 'company' && <th>Employee Name</th>}
            <th>Company Name</th>
            <th>Unit Rate</th>
            {continent === 'IN' && <th>Penalty Rate</th>}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {plantdata /* eslint operator-linebreak: ["error", "after"] */ &&
            find(plantdata)?.map((item) => (
              // <tr onClick={() => handlePlantNav(item.uuid)}>
              <tr>
                {/* <td style={{ placeContent: 'center', color: 'blue' }}>
                  <div className={`img_${item.type}`} />
                </td> */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <td>{item.plantName}</td>
                <td style={{ placeContent: 'center', color: 'blue' }}>
                  <div style={{ display: 'flex' }}>
                    <div className={`img_${item.type}`} />
                    {/* </td>
                <td style={{ placeContent: 'center', color: 'blue' }}> */}
                    {item.type}
                  </div>
                </td>
                {/* <td style={{ placeContent: 'center', color: 'blue' }}>
                  <div className={`img_${item.type}`} />
                  &nbsp;
                  {item.type}
                </td> */}
                <td>{item.location}</td>
                {role === 'company' && <td>{item.employeeName}</td>}
                <td>{item.companyName}</td>
                <td>
                  {Math.round((item.unitRate + Number.EPSILON) * 100) / 100}
                </td>
                {continent === 'IN' && (
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      &#8377;&nbsp;
                      {Math.round((item.penaltyRate + Number.EPSILON) * 100) /
                        100}
                    </div>
                  </td>
                )}
                <td>
                  <div className="actions">
                    {i.map((action) => {
                      if (action === 'view') {
                        return (
                          <span
                            aria-hidden
                            className="eyeIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalShow(true);
                              isDisable(true);
                              setModal('view');
                              setPlantId(item.uuid);
                            }}
                          />
                        );
                      }
                      if (action === 'edit') {
                        return (
                          <span
                            aria-hidden
                            className="editIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalShow(true);
                              setModal('view');
                              isDisable(false);
                              setPlantId(item.uuid);
                            }}
                          />
                        );
                      }
                      if (action === 'delete') {
                        return (
                          <span
                            aria-hidden
                            className="deleteIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setPlantId(item.uuid);
                              setIsDelete(false);
                              setModalShow(true);
                              setModal('delete');
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {/* {setLoading && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={plantdata?.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )} */}
      {pageCount > 0 && (
        <ReactPaginate
          initialPage={0}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeLinkClassName="activetab"
          activeClassName="active"
          previousLabel={<img src={previous} alt="upload" />}
          nextLabel={<img src={next} alt="upload" />}
          breakLabel="..."
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
        />
      )}
      <div>
        {modalShow && plantId !== '' && modal === 'view' ? (
          <PlantDetails
            show={modalShow}
            continent={continent}
            plantId={plantId}
            svalue={svalue}
            isDisable={disable}
            onHide={() => setModalShow(false)}
          />
        ) : null}
      </div>
      <div>
        {modalShow && plantId && !isDelete && modal === 'delete' ? (
          <DeleteConfirmation
            show={modalShow}
            plantId={plantId}
            type={types}
            setIsDelete={setIsDelete}
            onHide={() => {
              setModalShow(false);
            }}
          />
        ) : null}
      </div>

      <div>
        {modalShow && plantId === '' && single ? (
          <AddPlant
            show={modalShow}
            continent={continent}
            svalue={svalue}
            types={types}
            onHide={() => {
              setModalShow(false);
              setFiles('');
            }}
          />
        ) : null}
      </div>
      <div>
        {modalShow && plantId === '' && !single ? (
          <AddMultiPlant
            show={modalShow}
            setFiles={files}
            onHide={() => {
              setModalShow(false);
              setFiles('');
              setValue('');
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
