import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import RolePermission from './rolePermissions';
import UserPermission from './userPermissions';
import './index.scss';

export default function Manage() {
  return (
    <div className="table-manage">
      <Tabs defaultActiveKey="role" className="mb-3" justify>
        <Tab eventKey="role" title="Role Permission">
          <Row>
            <Col>
              <div>
                <RolePermission />
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="user" title="User Permission">
          <Row>
            <Col>
              <div>
                <UserPermission />
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
}
