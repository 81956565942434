import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import CompanyRequest from './CompanyRequest';
import PlantRequest from './PlantRequest';
import './index.scss';
import ClientRequest from './ClientRequest';

function Request() {
  return (
    <div>
      <Tabs defaultActiveKey="plant" className="mb-3" justify>
        <Tab eventKey="plant" title="Plant">
          <Row>
            <Col>
              <div>
                <PlantRequest />
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="company" title="Company">
          <Row>
            <Col>
              <div>
                <CompanyRequest />
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="client" title="Client">
          <Row>
            <Col>
              <div>
                <ClientRequest />
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Request;
