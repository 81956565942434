import React from "react";

const Address = () => {
  return (
    <div className="py-20 sm:pb-10 sm:pt-0">
      <h3 className="mb-[72px] text-center font-epilogue text-10xl font-semibold text-textColor xl:text-7xl sm:mb-10 sm:text-6xl">
        Address
      </h3>
      <div className="grid grid-cols-2 gap-10 sm:grid-cols-1 sm:gap-5">
        <div className="overflow-hidden rounded-radius-4 bg-secondary px-8 py-6">
          <div className="overflow-hidden rounded-radius-4">
            {/* <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-1.png" alt="deliver-1" /> */}
          </div>
          <div className="flex flex-col gap-2 text-left">
            <h4 className="text-5xl font-semibold text-codGray">Location</h4>
            <p className="text-base font-normal text-codGray">
              8 street, E Block
            </p>
            <p className="text-base font-normal text-codGray">
              Locate, AB 123456
            </p>
            <p className="text-base font-normal text-codGray">India</p>
          </div>
        </div>
        <div className="overflow-hidden rounded-radius-4 bg-secondary px-8 py-6">
          <div className="overflow-hidden rounded-radius-4">
            {/* <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-1.png" alt="deliver-1" /> */}
          </div>
          <div className="flex flex-col gap-2 text-left">
            <h4 className="text-5xl font-semibold text-codGray">Location</h4>
            <p className="text-base font-normal text-codGray">
              8 street, E Block
            </p>
            <p className="text-base font-normal text-codGray">
              Locate, AB 123456
            </p>
            <p className="text-base font-normal text-codGray">India</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
