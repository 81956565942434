/* eslint-disable indent */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import React from 'react';
// import { Pagination } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
// import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import { prediction } from '../../../Stores/Action';

export default function ForecastingTable() {
  // const { plantPredictionId } = props;
  const predictData = useSelector((state) => state.prediction?.data);
  // const [page, setPage] = useState(1);
  const predictiondata = predictData ? predictData?.data : [];
  // const dispatch = useDispatch();
  // const history = useLocation();
  // useEffect(() => {
  //   dispatch(prediction(plantPredictionId));
  // }, []);
  // const handleNextPage = () => setPage(page + 1);
  // const handlePrevClick = () => {
  //   if (page > 1) setPage(page - 1);
  // };

  // function isCurrentTimeInTimeBlock(timeBlock) {
  //   const [startTimeStr, endTimeStr] = timeBlock.split(' - ');
  //   const [startHour, startMinute] = startTimeStr.split(':').map(Number);
  //   const [endHour, endMinute] = endTimeStr.split(':').map(Number);

  //   const now = new Date();
  //   const currentHour = now.getHours();
  //   const currentMinute = now.getMinutes();

  //   const startTimeInMinutes = startHour * 60 + startMinute;
  //   const endTimeInMinutes = endHour * 60 + endMinute;
  //   const currentTimeInMinutes = currentHour * 60 + currentMinute;

  //   return (
  //     currentTimeInMinutes >= startTimeInMinutes &&
  //     currentTimeInMinutes <= endTimeInMinutes
  //   );
  // }

  // // Example usage:
  // const timeBlock = '23:30 - 23:45';
  // const isCurrentTimeMatching = isCurrentTimeInTimeBlock(timeBlock);

  // console.log(isCurrentTimeMatching);

  function getCurrentTimeBlock() {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();

    // Convert hours and minutes to a string in the format HH:mm
    const currentTimeString = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes
      }`;

    // Define your time block intervals
    const timeBlocks = [
      '00:00 - 00:15',
      '00:15 - 00:30',
      '00:30 - 00:45',
      '00:45 - 01:00',
      '01:00 - 01:15',
      '01:15 - 01:30',
      '01:30 - 01:45',
      '01:45 - 02:00',
      '02:00 - 02:15',
      '02:15 - 02:30',
      '02:30 - 02:45',
      '02:45 - 03:00',
      '03:00 - 03:15',
      '03:15 - 03:30',
      '03:30 - 03:45',
      '03:45 - 04:00',
      '04:00 - 04:15',
      '04:15 - 04:30',
      '04:30 - 04:45',
      '04:45 - 05:00',
      '05:00 - 05:15',
      '05:15 - 05:30',
      '05:30 - 05:45',
      '05:45 - 06:00',
      '06:00 - 06:15',
      '06:15 - 06:30',
      '06:30 - 06:45',
      '06:45 - 07:00',
      '07:00 - 07:15',
      '07:15 - 07:30',
      '07:30 - 07:45',
      '07:45 - 08:00',
      '08:00 - 08:15',
      '08:15 - 08:30',
      '08:30 - 08:45',
      '08:45 - 09:00',
      '09:00 - 09:15',
      '09:15 - 09:30',
      '09:30 - 09:45',
      '09:45 - 10:00',
      '10:00 - 10:15',
      '10:15 - 10:30',
      '10:30 - 10:45',
      '10:45 - 11:00',
      '11:00 - 11:15',
      '11:15 - 11:30',
      '11:30 - 11:45',
      '11:45 - 12:00',
      '12:00 - 12:15',
      '12:15 - 12:30',
      '12:30 - 12:45',
      '12:45 - 13:00',
      '13:00 - 13:15',
      '13:15 - 13:30',
      '13:30 - 13:45',
      '13:45 - 14:00',
      '14:00 - 14:15',
      '14:15 - 14:30',
      '14:30 - 14:45',
      '14:45 - 15:00',
      '15:00 - 15:15',
      '15:15 - 15:30',
      '15:30 - 15:45',
      '15:45 - 16:00',
      '16:00 - 16:15',
      '16:15 - 16:30',
      '16:30 - 16:45',
      '16:45 - 17:00',
      '17:00 - 17:15',
      '17:15 - 17:30',
      '17:30 - 17:45',
      '17:45 - 18:00',
      '18:00 - 18:15',
      '18:15 - 18:30',
      '18:30 - 18:45',
      '18:45 - 19:00',
      '19:00 - 19:15',
      '19:15 - 19:30',
      '19:30 - 19:45',
      '19:45 - 20:00',
      '20:00 - 20:15',
      '20:15 - 20:30',
      '20:30 - 20:45',
      '20:45 - 21:00',
      '21:00 - 21:15',
      '21:15 - 21:30',
      '21:30 - 21:45',
      '21:45 - 22:00',
      '22:00 - 22:15',
      '22:15 - 22:30',
      '22:30 - 22:45',
      '22:45 - 23:00',
      '23:00 - 23:15',
      '23:15 - 23:30',
      '23:30 - 23:45',
      '23:45 - 00:00',
      // Add more time blocks as needed
    ];

    // Find the matching time block for the current time
    const currentTimeBlock = timeBlocks.find((block) => {
      const [startTime, endTime] = block.split(' - ');
      // console.log('startTime', startTime, 'end', endTime);
      return currentTimeString >= startTime && currentTimeString < endTime;
    });
    return currentTimeBlock || ''; // Return an empty string if no match is found
  }

  const currentTimeBlock = getCurrentTimeBlock(); // Implement this function to get the current time block
  return (
    <div className="plant-prediction">
      <div className="plant-header">
        <h6
          style={{
            fontWeight: 'bold',
          }}
        >
          Plant Prediction
        </h6>
      </div>

      <Table className="plant-page" hover>
        <thead>
          <div className="theHead">
            <tr>
              <th>Block no</th>
              <th>Time Block</th>
              <th>AG</th>
              <th>SG</th>
              <th>MAE</th>
            </tr>
          </div>
        </thead>
        {predictiondata?.length ? (
          <tbody>
            <div className="theBody">
              {predictiondata /* eslint operator-linebreak: ["error", "after"] */ &&
                predictiondata.map((item) => (
                  <tr>
                    <td>{item['Block no ']}</td>
                    <td>{item['Time Block']}</td>
                    {/* <td>{parseFloat(item.AG).toFixed(2)}</td> */}
                    <td>
                      {item['Time Block'] < currentTimeBlock
                        ? parseFloat(item.AG).toFixed(2)
                        : '-'}
                    </td>
                    <td>{parseFloat(item.SG).toFixed(2)}</td>
                    <td>{item['Time Block'] < currentTimeBlock
                      ? parseFloat(item.MAE).toFixed(2)
                      : '-'}</td>
                  </tr>
                ))}
            </div>
          </tbody>
        ) : (
          <tbody>
            <tr>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'black',
                }}
              >
                <h3>Data Not Found</h3>
              </div>
            </tr>
          </tbody>
        )}
        {/* <Pagination className="pagination">
          <Pagination.Prev onClick={handlePrevClick} />
          <Pagination.Item>
            <input
              style={{ height: '24px', width: '48px', border: 'none' }}
              type="tel"
              value={page}
              onChange={(e) => setPage(parseInt(e.target.value, 10))}
            />
          </Pagination.Item>
          <Pagination.Next onClick={handleNextPage} />
        </Pagination>  */}
      </Table>
    </div>
  );
}
