import axios from 'axios';
import { toast } from 'react-toastify';

import { config } from '../../services/configHeader';
import reduxStore from '../Store';
import {
  PLANT_TYPE_REQUEST,
  PLANT_TYPE_SUCCESS,
  PLANT_TYPE_FAIL,
  EMPLOYEE_FILTER_REQUEST,
  EMPLOYEE_FILTER_SUCCESS,
  EMPLOYEE_FILTER_FAIL,
  COMPANY_FILTER_REQUEST,
  COMPANY_FILTER_SUCCESS,
  COMPANY_FILTER_FAIL,
  PLANT_FILTER_REQUEST,
  PLANT_FILTER_SUCCESS,
  PLANT_FILTER_FAIL,
  CLIENT_FILTER_SUCCESS,
  CLIENT_FILTER_REQUEST,
  CLIENT_FILTER_FAIL,
} from '../Types';

const clientAPI = `${process.env.REACT_APP_API_URL}client`;
const companyAPI = `${process.env.REACT_APP_API_URL}clientCompany`;
const plantAPI = `${process.env.REACT_APP_API_URL}plant`;
const employeeAPI = `${process.env.REACT_APP_API_URL}employee`;

export const plantType = (typeData) => async (dispatch) => {
  try {
    dispatch({ type: PLANT_TYPE_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;

    const a = typeData?.employeeId ? `&employeeId=${typeData.employeeId}` : '';
    // eslint-disable-next-line
    const b =
      typeData?.employeeId && typeData?.clientId
        ? `&employeeId=${typeData?.employeeId}&clientId=${typeData?.clientId}`
        : a;
    // eslint-disable-next-line
    const filter =
      typeData?.employeeId && typeData?.clientId && typeData?.companyId
        ? `&employeeId=${typeData?.employeeId}&clientId=${typeData?.clientId}&companyId=${typeData?.companyId}`
        : b;
    const plantfilter = typeData?.plantId
      ? `&plantId=${typeData?.plantId}`
      : filter;
    const continent = typeData?.continent
      ? `?continent=${typeData?.continent}`
      : '';
    const { data } = await axios.get(
      `${plantAPI}/plantType${continent}${plantfilter}`,
      config,
    );

    await dispatch({ type: PLANT_TYPE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLANT_TYPE_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    if (error.response && error.response.data.error.message) {
      toast.error(error.response.data.error.message);
    }
    // toast.error(
    //   error.response && error.response.data.error.message
    //     ? error.response.data.error.message
    //     : error.message,
    // );
  }
};

export const employeeFilter = (filterdata) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_FILTER_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const filter = filterdata?.type ? `?type=${filterdata?.type}` : '';
    const plantfilter = filterdata?.plantId
      ? `&plantId=${filterdata?.plantId}`
      : '';
    const companyfilter = filterdata?.companyId
      ? `&companyId=${filterdata?.companyId}`
      : '';
    const clientfilter = filterdata?.clientId
      ? `&clientId=${filterdata?.clientId}`
      : '';
    const continent = `&continent=${filterdata?.continent}`;
    const { data } = await axios.get(
      `${employeeAPI}/employeeFilter${filter}${plantfilter}${companyfilter}${clientfilter}${continent}`,
      config,
    );

    await dispatch({ type: EMPLOYEE_FILTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FILTER_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    // toast.error(
    //   error.response && error.response.data.error.message
    //     ? error.response.data.error.message
    //     : error.message,
    // );
  }
};
export const clientFilter = (filterdata) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_FILTER_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const filter = filterdata?.employeeId
      ? `&employeeId=${filterdata?.employeeId}`
      : '';
    const companyfilter = filterdata?.companyId
      ? `&companyId=${filterdata?.companyId}`
      : '';
    const plantfilter = filterdata?.plantId
      ? `&plantId=${filterdata?.plantId}`
      : '';
    const type = filterdata?.type ? `?type=${filterdata?.type}` : '';
    const continent = `&continent=${filterdata?.continent}`;
    const { data } = await axios.get(
      `${clientAPI}/clientFilter${type}${filter}${companyfilter}${plantfilter}${continent}`,
      config,
    );

    await dispatch({ type: CLIENT_FILTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CLIENT_FILTER_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    // toast.error(
    //   error.response && error.response.data.error.message
    //     ? error.response.data.error.message
    //     : error.message,
    // );
  }
};
export const companyFilter = (filterdata) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_FILTER_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const employeefilter = filterdata?.employeeId
      ? `&employeeId=${filterdata?.employeeId}`
      : '';
    const clientfilter = filterdata?.clientId
      ? `&clientId=${filterdata?.clientId}`
      : '';
    const plantfilter = filterdata?.plantId
      ? `&plantId=${filterdata?.plantId}`
      : '';
    const type = filterdata?.type ? `?type=${filterdata?.type}` : '';

    const continent = `&continent=${filterdata?.continent}`;
    const { data } = await axios.get(
      `${companyAPI}/clientCompanyFilter${type}${employeefilter}${clientfilter}${plantfilter}${continent}`,
      config,
    );

    await dispatch({ type: COMPANY_FILTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPANY_FILTER_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    // toast.error(
    //   error.response && error.response.data.error.message
    //     ? error.response.data.error.message
    //     : error.message,
    // );
  }
};
export const plantFilter = (pData) => async (dispatch) => {
  try {
    dispatch({ type: PLANT_FILTER_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const employeefilter = pData?.employeeId
      ? `&employeeId=${pData?.employeeId}`
      : '';
    const clientfilter = pData?.clientId ? `&clientId=${pData?.clientId}` : '';
    const filter = pData?.companyId ? `&companyId=${pData.companyId}` : '';
    const type = pData?.type ? `?type=${pData.type}` : '';

    const continent = pData?.continent ? `&continent=${pData?.continent}` : '';
    const { data } = await axios.get(
      `${plantAPI}/plantFilter${type}${filter}${clientfilter}${employeefilter}${continent}`,
      config,
    );

    await dispatch({ type: PLANT_FILTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PLANT_FILTER_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    // toast.error(
    //   error.response && error.response.data.error.message
    //     ? error.response.data.error.message
    //     : error.message,
    // );
  }
};
