import { Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import ClientRolePermission from './clientRolePermission';
import EmployeeRolePermission from './employeeRolePermission';

export default function RolePermission() {
  return (
    <div className="plant">
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Employee</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                <div>
                  <EmployeeRolePermission />
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Client</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                <div>
                  <ClientRolePermission />
                </div>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
