import { Welcome, Signin, logout, ForgotPassword, OTP } from './Auth';
import {
  ResetPassword,
  profileUpdate,
  profileView,
  clientList,
  clientView,
  addClient,
  deleteClient,
  clientEdit,
  addMultiClient,
  profileImageUpdate,
} from './Client';
import {
  companyList,
  companyView,
  addCompany,
  deleteCompany,
  companyEdit,
  addMultiCompany,
} from './Company';
import {
  employeeList,
  employeeView,
  addEmployee,
  editEmployee,
  deleteEmployee,
  addMultiEmployee,
} from './Employee';
import {
  penaltyCards,
  penaltyChart,
  penaltyPieChart,
  prediction,
} from './Prediction';
import {
  plantList,
  singlePlant,
  addPlant,
  editPlant,
  deletePlant,
  addMultiPlant,
} from './Plant';
import { permissionList, permissionView } from './Permission';
import {
  plantFilter,
  plantType,
  employeeFilter,
  clientFilter,
  companyFilter,
} from './Filter';
import { rolePermissionView, rolePermissionEdit } from './RolePermission';
import {
  plantRequestList,
  clientCompanyRequestList,
  approveRejectCompanyRequest,
  approveRejectPlantRequest,
  plantRequestDetails,
  companyRequestDetails,
  clientRequestDetail,
  clientRequestList,
  approveRejectClientRequest,
} from './Request';

import {
  plantTypeFilterAction,
  employeeFilterAction,
  clientFilterAction,
  companyFilterAction,
  plantFilterAction,
} from './dashboardFilter';

import { Contact } from './Contact';

import { forecastingCards } from './EuPrediction';

export {
  Welcome,
  Contact,
  Signin,
  logout,
  ForgotPassword,
  OTP,
  ResetPassword,
  profileUpdate,
  profileImageUpdate,
  profileView,
  clientList,
  clientView,
  addClient,
  deleteClient,
  clientEdit,
  addMultiClient,
  companyList,
  companyView,
  addCompany,
  deleteCompany,
  companyEdit,
  employeeList,
  employeeView,
  addEmployee,
  editEmployee,
  deleteEmployee,
  addMultiEmployee,
  addMultiCompany,
  penaltyCards,
  penaltyChart,
  penaltyPieChart,
  prediction,
  plantList,
  singlePlant,
  addPlant,
  editPlant,
  deletePlant,
  addMultiPlant,
  permissionList,
  permissionView,
  plantFilter,
  plantType,
  employeeFilter,
  clientFilter,
  companyFilter,
  rolePermissionView,
  rolePermissionEdit,
  plantRequestList,
  clientCompanyRequestList,
  plantTypeFilterAction,
  employeeFilterAction,
  clientFilterAction,
  companyFilterAction,
  plantFilterAction,
  approveRejectCompanyRequest,
  approveRejectPlantRequest,
  plantRequestDetails,
  companyRequestDetails,
  clientRequestDetail,
  clientRequestList,
  approveRejectClientRequest,
  forecastingCards,
};
