export const WELCOME_SUCCESS = () => ({ type: WELCOME_SUCCESS });
export const WELCOME_FAIL = () => ({ type: WELCOME_FAIL });

export const CHANGE_CONTINENT = () => ({ type: CHANGE_CONTINENT });

export const CONTACT_REQUEST = () => ({ type: CONTACT_REQUEST });
export const CONTACT_SUCCESS = () => ({ type: CONTACT_SUCCESS });
export const CONTACT_FAIL = () => ({ type: CONTACT_FAIL });

export const PLANT_TYPE_CHANGE = () => ({ type: PLANT_TYPE_CHANGE });
export const EMPLOYEE_CHANGE = () => ({ type: EMPLOYEE_CHANGE });
export const COMPANY_CHANGE = () => ({ type: COMPANY_CHANGE });
export const CLIENT_CHANGE = () => ({ type: CLIENT_CHANGE });
export const PLANT_CHANGE = () => ({ type: PLANT_CHANGE });
export const FILTER_FAIL = () => ({ type: FILTER_FAIL });

export const USER_LOGIN_REQUEST = () => ({ type: USER_LOGIN_REQUEST });
export const USER_LOGIN_SUCCESS = () => ({ type: USER_LOGIN_SUCCESS });
export const USER_LOGIN_FAIL = () => ({ type: USER_LOGIN_FAIL });

export const PERMISSION_REQUEST = () => ({ type: PERMISSION_REQUEST });
export const PERMISSION_SUCCESS = () => ({ type: PERMISSION_SUCCESS });
export const PERMISSION_FAIL = () => ({ type: PERMISSION_FAIL });

export const USER_LOGOUT = () => ({ type: USER_LOGOUT });

export const USER_REGISTER_REQUEST = () => ({ type: USER_REGISTER_REQUEST });
export const USER_REGISTER_SUCCESS = () => ({ type: USER_REGISTER_SUCCESS });
export const USER_REGISTER_FAIL = () => ({ type: USER_REGISTER_FAIL });

export const USER_FORGOT_PASSWORD_REQUEST = () => ({
  type: USER_FORGOT_PASSWORD_REQUEST,
});
export const USER_FORGOT_PASSWORD_SUCCESS = () => ({
  type: USER_FORGOT_PASSWORD_SUCCESS,
});
export const USER_FORGOT_PASSWORD_FAIL = () => ({
  type: USER_FORGOT_PASSWORD_FAIL,
});
export const USER_OTP_REQUEST = () => ({
  type: USER_OTP_REQUEST,
});
export const USER_OTP_SUCCESS = () => ({
  type: USER_OTP_SUCCESS,
});
export const USER_OTP_FAIL = () => ({
  type: USER_OTP_FAIL,
});
export const USER_RESET_PASSWORD_REQUEST = () => ({
  type: USER_RESET_PASSWORD_REQUEST,
});
export const USER_RESET_PASSWORD_SUCCESS = () => ({
  type: USER_RESET_PASSWORD_SUCCESS,
});
export const USER_RESET_PASSWORD_FAIL = () => ({
  type: USER_RESET_PASSWORD_FAIL,
});
export const USER_VIEW_REQUEST = () => ({ type: USER_VIEW_REQUEST });
export const USER_VIEW_SUCCESS = () => ({ type: USER_VIEW_SUCCESS });
export const USER_VIEW_FAIL = () => ({ type: USER_VIEW_FAIL });
export const USER_UPDATE_REQUEST = () => ({ type: USER_UPDATE_REQUEST });
export const USER_UPDATE_SUCCESS = () => ({ type: USER_UPDATE_SUCCESS });
export const USER_UPDATE_FAIL = () => ({ type: USER_UPDATE_FAIL });
export const PLANT_TYPE_REQUEST = () => ({
  type: PLANT_TYPE_REQUEST,
});

export const PLANT_TYPE_SUCCESS = () => ({
  type: PLANT_TYPE_SUCCESS,
});
export const PLANT_TYPE_FAIL = () => ({ type: PLANT_TYPE_FAIL });
export const EMPLOYEE_FILTER_REQUEST = () => ({
  type: EMPLOYEE_FILTER_REQUEST,
});

export const EMPLOYEE_FILTER_SUCCESS = () => ({
  type: EMPLOYEE_FILTER_SUCCESS,
});
export const EMPLOYEE_FILTER_FAIL = () => ({ type: EMPLOYEE_FILTER_FAIL });
export const CLIENT_FILTER_REQUEST = () => ({
  type: CLIENT_FILTER_REQUEST,
});

export const CLIENT_FILTER_SUCCESS = () => ({
  type: CLIENT_FILTER_SUCCESS,
});
export const CLIENT_FILTER_FAIL = () => ({ type: CLIENT_FILTER_FAIL });
export const PLANT_FILTER_REQUEST = () => ({
  type: PLANT_FILTER_REQUEST,
});

export const PLANT_FILTER_SUCCESS = () => ({
  type: PLANT_FILTER_SUCCESS,
});
export const PLANT_FILTER_FAIL = () => ({ type: PLANT_FILTER_FAIL });
export const COMPANY_FILTER_REQUEST = () => ({
  type: COMPANY_FILTER_REQUEST,
});

export const COMPANY_FILTER_SUCCESS = () => ({
  type: COMPANY_FILTER_SUCCESS,
});
export const COMPANY_FILTER_FAIL = () => ({ type: COMPANY_FILTER_FAIL });
export const PLANT_LIST_VIEW_REQUEST = () => ({
  type: PLANT_LIST_VIEW_REQUEST,
});

export const PLANT_LIST_VIEW_SUCCESS = () => ({
  type: PLANT_LIST_VIEW_SUCCESS,
});
export const PLANT_LIST_VIEW_FAIL = () => ({ type: PLANT_LIST_VIEW_FAIL });
export const PLANT_VIEW_REQUEST = () => ({
  type: PLANT_VIEW_REQUEST,
});

export const PLANT_VIEW_SUCCESS = () => ({
  type: PLANT_VIEW_SUCCESS,
});
export const PLANT_VIEW_FAIL = () => ({ type: PLANT_VIEW_FAIL });
export const PLANT_ADD_REQUEST = () => ({
  type: PLANT_ADD_REQUEST,
});

export const PLANT_ADD_SUCCESS = () => ({
  type: PLANT_ADD_SUCCESS,
});
export const PLANT_ADD_FAIL = () => ({ type: PLANT_ADD_FAIL });
export const EDIT_PLANT_VIEW_REQUEST = () => ({
  type: EDIT_PLANT_VIEW_REQUEST,
});

export const EDIT_PLANT_VIEW_SUCCESS = () => ({
  type: EDIT_PLANT_VIEW_SUCCESS,
});
export const EDIT_PLANT_VIEW_FAIL = () => ({ type: EDIT_PLANT_VIEW_FAIL });
export const CLIENT_LIST_VIEW_REQUEST = () => ({
  type: CLIENT_LIST_VIEW_REQUEST,
});

export const CLIENT_LIST_VIEW_SUCCESS = () => ({
  type: CLIENT_LIST_VIEW_SUCCESS,
});
export const CLIENT_LIST_VIEW_FAIL = () => ({ type: CLIENT_LIST_VIEW_FAIL });
export const CLIENT_ADD_REQUEST = () => ({
  type: CLIENT_ADD_REQUEST,
});

export const CLIENT_ADD_SUCCESS = () => ({
  type: CLIENT_ADD_SUCCESS,
});
export const CLIENT_ADD_FAIL = () => ({ type: CLIENT_ADD_FAIL });

export const PENALTY_CHART_REQUEST = () => ({
  type: PENALTY_CHART_REQUEST,
});
export const PENALTY_CHART_SUCCESS = () => ({
  type: PENALTY_CHART_SUCCESS,
});
export const PENALTY_CHART_FAIL = () => ({
  type: PENALTY_CHART_FAIL,
});

export const PENALTY_CARDS_REQUEST = () => ({
  type: PENALTY_CARDS_REQUEST,
});
export const PENALTY_CARDS_SUCCESS = () => ({
  type: PENALTY_CARDS_SUCCESS,
});
export const PENALTY_CARDS_FAIL = () => ({
  type: PENALTY_CARDS_FAIL,
});

export const PENALTY_PIE_CHART_REQUEST = () => ({
  type: PENALTY_PIE_CHART_REQUEST,
});
export const PENALTY_PIE_CHART_SUCCESS = () => ({
  type: PENALTY_PIE_CHART_SUCCESS,
});
export const PENALTY_PIE_CHART_FAIL = () => ({
  type: PENALTY_PIE_CHART_FAIL,
});

export const FORECAST_CARDS_REQUEST = () => ({ type: FORECAST_CARDS_REQUEST });
export const FORECAST_CARDS_SUCCESS = () => ({ type: FORECAST_CARDS_SUCCESS });
export const FORECAST_CARDS_FAIL = () => ({ type: FORECAST_CARDS_FAIL });

export const PREDICTION_REQUEST = () => ({
  type: PREDICTION_REQUEST,
});

export const PREDICTION_SUCCESS = () => ({
  type: PREDICTION_SUCCESS,
});
export const PREDICTION_FAIL = () => ({
  type: PREDICTION_FAIL,
});

export const EDIT_CLIENT_REQUEST = () => ({
  type: EDIT_CLIENT_REQUEST,
});

export const EDIT_CLIENT_SUCCESS = () => ({
  type: EDIT_CLIENT_SUCCESS,
});
export const EDIT_CLIENT_FAIL = () => ({ type: EDIT_CLIENT_FAIL });

export const CLIENT_VIEW_REQUEST = () => ({
  type: CLIENT_VIEW_REQUEST,
});

export const CLIENT_VIEW_SUCCESS = () => ({
  type: CLIENT_VIEW_SUCCESS,
});
export const CLIENT_VIEW_FAIL = () => ({ type: CLIENT_VIEW_FAIL });
export const DELETE_PLANT_REQUEST = () => ({
  type: DELETE_PLANT_REQUEST,
});

export const DELETE_PLANT_SUCCESS = () => ({
  type: DELETE_PLANT_SUCCESS,
});
export const DELETE_PLANT_FAIL = () => ({ type: DELETE_PLANT_FAIL });
export const DELETE_CLIENT_REQUEST = () => ({
  type: DELETE_CLIENT_REQUEST,
});

export const DELETE_CLIENT_SUCCESS = () => ({
  type: DELETE_CLIENT_SUCCESS,
});
export const DELETE_CLIENT_FAIL = () => ({ type: DELETE_CLIENT_FAIL });
export const DELETE_EMPLOYEE_REQUEST = () => ({
  type: DELETE_EMPLOYEE_REQUEST,
});

export const DELETE_EMPLOYEE_SUCCESS = () => ({
  type: DELETE_EMPLOYEE_SUCCESS,
});
export const DELETE_EMPLOYEE_FAIL = () => ({ type: DELETE_EMPLOYEE_FAIL });
export const EMPLOYEE_ADD_REQUEST = () => ({
  type: EMPLOYEE_ADD_REQUEST,
});

export const EMPLOYEE_ADD_SUCCESS = () => ({
  type: EMPLOYEE_ADD_SUCCESS,
});
export const EMPLOYEE_ADD_FAIL = () => ({ type: EMPLOYEE_ADD_FAIL });
export const EDIT_EMPLOYEE_REQUEST = () => ({
  type: EDIT_EMPLOYEE_REQUEST,
});

export const EDIT_EMPLOYEE_SUCCESS = () => ({
  type: EDIT_EMPLOYEE_SUCCESS,
});
export const EDIT_EMPLOYEE_FAIL = () => ({ type: EDIT_EMPLOYEE_FAIL });
export const EMPLOYEE_LIST_VIEW_REQUEST = () => ({
  type: EMPLOYEE_LIST_VIEW_REQUEST,
});

export const EMPLOYEE_LIST_VIEW_SUCCESS = () => ({
  type: EMPLOYEE_LIST_VIEW_SUCCESS,
});
export const EMPLOYEE_LIST_VIEW_FAIL = () => ({
  type: EMPLOYEE_LIST_VIEW_FAIL,
});
export const EMPLOYEE_VIEW_REQUEST = () => ({
  type: EMPLOYEE_VIEW_REQUEST,
});

export const EMPLOYEE_VIEW_SUCCESS = () => ({
  type: EMPLOYEE_VIEW_SUCCESS,
});
export const EMPLOYEE_VIEW_FAIL = () => ({ type: EMPLOYEE_VIEW_FAIL });
export const MULTI_EMPLOYEE_ADD_REQUEST = () => ({
  type: MULTI_EMPLOYEE_ADD_REQUEST,
});

export const MULTI_EMPLOYEE_ADD_SUCCESS = () => ({
  type: MULTI_EMPLOYEE_ADD_SUCCESS,
});
export const MULTI_EMPLOYEE_ADD_FAIL = () => ({
  type: MULTI_EMPLOYEE_ADD_FAIL,
});
export const MULTI_CLIENT_ADD_REQUEST = () => ({
  type: MULTI_CLIENT_ADD_REQUEST,
});

export const MULTI_CLIENT_ADD_SUCCESS = () => ({
  type: MULTI_CLIENT_ADD_SUCCESS,
});
export const MULTI_CLIENT_ADD_FAIL = () => ({ type: MULTI_CLIENT_ADD_FAIL });
export const MULTI_PLANT_ADD_REQUEST = () => ({
  type: MULTI_PLANT_ADD_REQUEST,
});

export const MULTI_PLANT_ADD_SUCCESS = () => ({
  type: MULTI_PLANT_ADD_SUCCESS,
});
export const MULTI_PLANT_ADD_FAIL = () => ({ type: MULTI_PLANT_ADD_FAIL });
export const MULTI_COMPANY_ADD_REQUEST = () => ({
  type: MULTI_COMPANY_ADD_REQUEST,
});

export const MULTI_COMPANY_ADD_SUCCESS = () => ({
  type: MULTI_COMPANY_ADD_SUCCESS,
});
export const MULTI_COMPANY_ADD_FAIL = () => ({ type: MULTI_COMPANY_ADD_FAIL });
export const PERMISSION_LIST_REQUEST = () => ({
  type: PERMISSION_LIST_REQUEST,
});

export const PERMISSION_LIST_SUCCESS = () => ({
  type: PERMISSION_LIST_SUCCESS,
});
export const PERMISSION_LIST_FAIL = () => ({ type: PERMISSION_LIST_FAIL });
export const PERMISSION_VIEW_REQUEST = () => ({
  type: PERMISSION_VIEW_REQUEST,
});

export const PERMISSION_VIEW_SUCCESS = () => ({
  type: PERMISSION_VIEW_SUCCESS,
});
export const PERMISSION_VIEW_FAIL = () => ({ type: PERMISSION_VIEW_FAIL });
export const COMPANY_LIST_VIEW_REQUEST = () => ({
  type: COMPANY_LIST_VIEW_REQUEST,
});

export const COMPANY_LIST_VIEW_SUCCESS = () => ({
  type: COMPANY_LIST_VIEW_SUCCESS,
});
export const COMPANY_LIST_VIEW_FAIL = () => ({ type: COMPANY_LIST_VIEW_FAIL });
export const COMPANY_ADD_REQUEST = () => ({
  type: COMPANY_ADD_REQUEST,
});

export const COMPANY_ADD_SUCCESS = () => ({
  type: COMPANY_ADD_SUCCESS,
});
export const COMPANY_ADD_FAIL = () => ({ type: COMPANY_ADD_FAIL });
export const EDIT_COMPANY_REQUEST = () => ({
  type: EDIT_COMPANY_REQUEST,
});

export const EDIT_COMPANY_SUCCESS = () => ({
  type: EDIT_COMPANY_SUCCESS,
});
export const EDIT_COMPANY_FAIL = () => ({ type: EDIT_COMPANY_FAIL });
export const DELETE_COMPANY_REQUEST = () => ({
  type: DELETE_COMPANY_REQUEST,
});

export const DELETE_COMPANY_SUCCESS = () => ({
  type: DELETE_COMPANY_SUCCESS,
});
export const DELETE_COMPANY_FAIL = () => ({ type: DELETE_COMPANY_FAIL });
export const COMPANY_VIEW_REQUEST = () => ({
  type: COMPANY_VIEW_REQUEST,
});

export const COMPANY_VIEW_SUCCESS = () => ({
  type: COMPANY_VIEW_SUCCESS,
});
export const COMPANY_VIEW_FAIL = () => ({ type: COMPANY_VIEW_FAIL });

export const ROLE_PERMISSION_REQUEST = () => ({
  type: ROLE_PERMISSION_REQUEST,
});
export const ROLE_PERMISSION_SUCCESS = () => ({
  type: ROLE_PERMISSION_SUCCESS,
});
export const ROLE_PERMISSION_FAIL = () => ({ type: ROLE_PERMISSION_FAIL });

export const ROLE_PERMISSION_EDIT_REQUEST = () => ({
  type: ROLE_PERMISSION_EDIT_REQUEST,
});
export const ROLE_PERMISSION_EDIT_SUCCESS = () => ({
  type: ROLE_PERMISSION_EDIT_SUCCESS,
});
export const ROLE_PERMISSION_EDIT_FAIL = () => ({
  type: ROLE_PERMISSION_EDIT_FAIL,
});

export const PLANT_PENDING_REQUEST = () => ({
  type: PLANT_PENDING_REQUEST,
});
export const PLANT_PENDING_SUCCESS = () => ({
  type: PLANT_PENDING_SUCCESS,
});
export const PLANT_PENDING_FAIL = () => ({ type: PLANT_PENDING_FAIL });

export const CLIENT_COMPANY_PENDING_REQUEST = () => ({
  type: CLIENT_COMPANY_PENDING_REQUEST,
});
export const CLIENT_COMPANY_PENDING_SUCCESS = () => ({
  type: CLIENT_COMPANY_PENDING_SUCCESS,
});
export const CLIENT_COMPANY_PENDING_FAIL = () => ({
  type: CLIENT_COMPANY_PENDING_FAIL,
});
export const CLIENT_PENDING_REQUEST = () => ({
  type: CLIENT_PENDING_REQUEST,
});
export const CLIENT_PENDING_SUCCESS = () => ({
  type: CLIENT_PENDING_SUCCESS,
});
export const CLIENT_PENDING_FAIL = () => ({
  type: CLIENT_PENDING_FAIL,
});
export const PLANT_APPROVE_REJECT_REQUEST = () => ({
  type: PLANT_APPROVE_REJECT_REQUEST,
});
export const PLANT_APPROVE_REJECT_SUCCESS = () => ({
  type: PLANT_APPROVE_REJECT_SUCCESS,
});
export const PLANT_APPROVE_REJECT_FAIL = () => ({
  type: PLANT_APPROVE_REJECT_FAIL,
});

export const PLANT_REQUEST_DETAIL_REQUEST = () => ({
  type: PLANT_REQUEST_DETAIL_REQUEST,
});
export const PLANT_REQUEST_DETAIL_SUCCESS = () => ({
  type: PLANT_REQUEST_DETAIL_SUCCESS,
});
export const PLANT_REQUEST_DETAIL_FAIL = () => ({
  type: PLANT_REQUEST_DETAIL_FAIL,
});
export const COMPANY_APPROVE_REJECT_REQUEST = () => ({
  type: COMPANY_APPROVE_REJECT_REQUEST,
});
export const COMPANY_APPROVE_REJECT_SUCCESS = () => ({
  type: COMPANY_APPROVE_REJECT_SUCCESS,
});
export const COMPANY_APPROVE_REJECT_FAIL = () => ({
  type: COMPANY_APPROVE_REJECT_FAIL,
});
export const COMPANY_REQUEST_DETAIL_REQUEST = () => ({
  type: COMPANY_REQUEST_DETAIL_REQUEST,
});
export const COMPANY_REQUEST_DETAIL_SUCCESS = () => ({
  type: COMPANY_REQUEST_DETAIL_SUCCESS,
});
export const COMPANY_REQUEST_DETAIL_FAIL = () => ({
  type: COMPANY_REQUEST_DETAIL_FAIL,
});
export const CLIENT_APPROVE_REJECT_REQUEST = () => ({
  type: CLIENT_APPROVE_REJECT_REQUEST,
});
export const CLIENT_APPROVE_REJECT_SUCCESS = () => ({
  type: CLIENT_APPROVE_REJECT_SUCCESS,
});
export const CLIENT_APPROVE_REJECT_FAIL = () => ({
  type: CLIENT_APPROVE_REJECT_FAIL,
});
export const CLIENT_REQUEST_DETAIL_REQUEST = () => ({
  type: CLIENT_REQUEST_DETAIL_REQUEST,
});
export const CLIENT_REQUEST_DETAIL_SUCCESS = () => ({
  type: CLIENT_REQUEST_DETAIL_SUCCESS,
});
export const CLIENT_REQUEST_DETAIL_FAIL = () => ({
  type: CLIENT_REQUEST_DETAIL_FAIL,
});
export const IMAGE_UPDATE_REQUEST = () => ({
  type: IMAGE_UPDATE_REQUEST,
});
export const IMAGE_UPDATE_SUCCESS = () => ({
  type: IMAGE_UPDATE_SUCCESS,
});
export const IMAGE_UPDATE_FAIL = () => ({
  type: IMAGE_UPDATE_FAIL,
});
