import React from "react";
import { Address, ContactForm } from "../components/contactPage";
import Phone from "../components/icons/Phone";
import { Link } from "react-router-dom";
import Mail from "../components/icons/Mail";

const ContactPage = () => {
  return (
    <div id="contactPage" className="relative bg-codGray py-20 sm:py-10">
      <div className="pointer-events-none absolute inset-0 z-[0]">
        <img
          src="/assets/images/bg-abstract-pattern.svg"
          alt="hero-bg-pattern"
          className="w-full object-center"
        />
      </div>
      <div className="container relative z-[1]">
        <ContactForm />
        <div className="my-24 sm:my-10">
          <div className="flex items-center gap-10 sm:flex-col sm:gap-6">
            <div className="w-full rounded-2xl bg-secondary p-8 sm:p-4">
              <div className="flex items-center gap-6">
                <Phone />
                <Link
                  className="text-2xl font-normal text-codGray sm:text-base"
                  to={"tel:+17372587485"}
                >
                  +1 737 258 7485
                </Link>
                <div className="text-silver">|</div>
                <Link
                  className="text-2xl font-normal text-codGray sm:text-base"
                  to={"tel:+919880991140"}
                >
                  +91 98809 91140
                </Link>
              </div>
            </div>
            <div className="w-full rounded-2xl bg-secondary p-8 sm:p-4">
              <div className="flex items-center gap-6">
                <Mail />
                <Link
                  className="text-2xl font-normal text-codGray sm:text-base"
                  to={"mailto:hello@indite.io"}
                >
                  hello@indite.io
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Address />
      </div>
    </div>
  );
};

export default ContactPage;
