import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import profile from '../assets/profile.png';
import './index.scss';

export default function Header({
  title,
  showProfile,
  countryData,
  country,
  setCountry,
}) {
  const data = useSelector((state) => state.userDetails);
  const username = data?.user?.userName || 'Your username';
  const role = data?.user?.rolename || 'Your Role';
  // const profileData = useSelector((state) => state.userUpdate?.user);
  // const profileUrl = profileData?.data?.profileUrl || profile;
  const profileUrl = profile;

  return (
    <div className="header-title">
      {title}
      <div className="freespace" />
      <Select
        defaultValue="India"
        name="inputData"
        id="inputData"
        className="select"
        options={countryData}
        value={country}
        onChange={(e) => {
          setCountry(e);
        }}
      />
      {/* <div className="search">
        <img src={search} alt="search" className="search-icon" />
        <input className="search-input" placeholder="Search Content ..." />
      </div> */}
      <div className="__section">
        {/* eslint-disable-next-line */}
        <div className="profile" onClick={() => showProfile()}>
          <div className="__image">
            <img src={profileUrl} alt="profile" />
          </div>
          <div className="__name">
            <p className="name">{username}</p>
            <p className="role">{role}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
