/* eslint-disable indent */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { rolePermissionEdit } from '../../Stores/Action';
// import AddPermission from './addPermission';
// import AddMultiPermission from './addMultiPermission';
// import store from '../../Stores/Store';
// import upload from '../../components/assets/upload.svg';
import './index.scss';
import Check from './CheckBox';

export default function ClientRolePermission() {
  const dispatch = useDispatch();

  const permissiondata = useSelector(
    (state) => state.rolePermissions?.data?.data,
  );
  // const permissiondata = pData.permission ? pData?.permission : null;
  // const handleRemove = (id) => {
  //   dispatch(deletePermission({ permissionId: id }));
  // };
  // const [permissionId, setPermissionId] = useState('');
  const [Show, setShow] = useState(false);
  // const [addPermissionShow, setAddPermissionShow] = useState(false);
  const [disable, isDisable] = useState(true);

  const [permissions, setPermissions] = useState(
    permissiondata?.filter((p) => p.roleName === 'client' && p.permission),
  );

  useEffect(() => {
    setPermissions(
      permissiondata?.filter((p) => p.roleName === 'client' && p.permission),
    );
  }, [permissiondata]);
  const handleCheck = (i, j) => {
    const condition = permissions[0].permission.filter(
      (p) => p.permissionName === i,
    )?.[0];
    if (condition.actions.includes(j)) {
      setPermissions([
        {
          permission: permissions[0].permission.map((k) => {
            let m = k.actions;
            if (k.permissionName === i) {
              if (j === 'view') {
                m = [];
              } else {
                m = k.actions.filter((a) => a !== j);
              }
            }
            return {
              permissionName: k.permissionName,
              actions: m,
            };
          }),
        },
      ]);
    } else {
      setPermissions([
        {
          permission: permissions[0].permission.map((k) => {
            let m = k.actions;
            if (k.permissionName === i) {
              if (k.actions.includes('view') || j === 'view') {
                m = [...k.actions, j];
              } else {
                m = [...k.actions, j, 'view'];
              }
            }
            return {
              permissionName: k.permissionName,
              actions: m,
            };
          }),
        },
      ]);
    }
    // setAction(action.filter((k) => k !== i));
    // else setAction([...action, i]);
  };
  return (
    <div className="rolepermission-list">
      <div className="rolepermission-header">
        <h5
          style={{
            fontWeight: 'bold',
          }}
        >
          Client Permissions
        </h5>
        <div className="dropdown" style={{ display: 'none' }}>
          {/* <div className="dropdown-content"> */}
          {/* <button
                      type="button"
                      className="dropbtn"
                      onClick={() => {
                        setpermissionShow(true);
                        isDisable(true);
                        setPermissionId(item.uuid);
                      }}
                    >
                      <div className="view-svg" />
                    </button> */}
          {Show ? (
            <Button
              className="edit-button"
              type="button"
              onClick={() => {
                isDisable(true);
                setShow(false);
                dispatch(rolePermissionEdit(permissions));
              }}
            >
              save
            </Button>
          ) : (
            <Button
              className="dropbtn"
              type="button"
              onClick={() => {
                isDisable(false);
                setShow(true);
              }}
            >
              <div className="edit-svg" />
            </Button>
          )}
        </div>
      </div>
      <Table className="rolepermission-page" hover>
        <thead>
          <tr>
            <th aria-label="index" />
            <th>Permission Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {permissions /* eslint operator-linebreak: ["error", "after"] */ &&
            permissions?.[0]?.permission?.map((items) => (
              <tr>
                <td>{ }</td>
                <td>{items.permissionName}</td>
                <td>
                  <Check
                    disable={disable}
                    permission={items.permissionName}
                    handleCheck={handleCheck}
                    actions={items.actions}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}
