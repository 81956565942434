import axios from 'axios';
import moment from 'moment/moment';
import { config } from '../../services/configHeader';
import reduxStore from '../Store';
import {
  PENALTY_CHART_REQUEST,
  PENALTY_CHART_SUCCESS,
  PENALTY_CHART_FAIL,
  PENALTY_CARDS_REQUEST,
  PENALTY_CARDS_SUCCESS,
  PENALTY_CARDS_FAIL,
  PENALTY_PIE_CHART_REQUEST,
  PENALTY_PIE_CHART_SUCCESS,
  PENALTY_PIE_CHART_FAIL,
  PREDICTION_REQUEST,
  PREDICTION_SUCCESS,
  PREDICTION_FAIL,
} from '../Types';

const predictAPI = `${process.env.REACT_APP_API_URL}prediction`;

const penaltyCards = (predictionData) => async (dispatch) => {
  try {
    dispatch({ type: PENALTY_CARDS_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const plant = predictionData?.plantId
      ? `&plantId=${predictionData?.plantId}`
      : '';
    const employeefilter = predictionData?.employeeId
      ? `&employeeId=${predictionData?.employeeId}`
      : '';
    const clientfilter = predictionData?.clientId
      ? `&clientId=${predictionData?.clientId}`
      : '';
    const filter = predictionData?.companyId
      ? `&companyId=${predictionData.companyId}`
      : '';
    const type = predictionData?.type ? `?type=${predictionData?.type}` : '';

    const tdpCard = await axios.get(
      `${predictAPI}/dtdCard${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const twpCard = await axios.get(
      `${predictAPI}/wtdCard${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const tmpCard = await axios.get(
      `${predictAPI}/mtdCard${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const typCard = await axios.get(
      `${predictAPI}/ytdCard${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const tdpChart = await axios.get(
      `${predictAPI}/tdpChart${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const twpChart = await axios.get(
      `${predictAPI}/twpChart${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const tmpChart = await axios.get(
      `${predictAPI}/tmpChart${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    const typChart = await axios.get(
      `${predictAPI}/typChart${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );

    // { penalty: "20215.617", block: "00:00 - 00:15" }

    const tdpChartReduce = tdpChart.data.data.reduce(
      (prev, now) => {
        if (prev.b === 4) {
          prev.c.push({
            penalty: prev.a,
            block: prev.c.length + 1,
          });
          return {
            a: parseFloat(now.penalty),
            b: 1,
            c: prev.c,
          };
        }
        return {
          a: prev.a + parseFloat(now.penalty),
          b: prev.b + 1,
          c: prev.c,
        };
      },
      { a: 0, b: 1, c: [] },
    ).c;
    const data = {
      tdp: tdpCard.data.data.penalty,
      twp: twpCard.data.data.penalty,
      tmp: tmpCard.data.data.penalty,
      typ: typCard.data.data.penalty,
      // tdpChart: tdpChart.data.data,
      tdpChart: tdpChartReduce,
      twpChart: twpChart.data.data,
      tmpChart: tmpChart.data.data,
      typChart: typChart.data.data,
    };
    await dispatch({ type: PENALTY_CARDS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PENALTY_CARDS_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
  }
};

const penaltyChart = (penaltyChartData) => async (dispatch) => {
  try {
    dispatch({ type: PENALTY_CHART_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const type = penaltyChartData?.type
      ? `?type=${penaltyChartData?.type}`
      : '';
    const plant = penaltyChartData?.plantId
      ? `&plantId=${penaltyChartData?.plantId}`
      : '';
    const employeefilter = penaltyChartData?.employeeId
      ? `&employeeId=${penaltyChartData?.employeeId}`
      : '';
    const clientfilter = penaltyChartData?.clientId
      ? `&clientId=${penaltyChartData?.clientId}`
      : '';
    const filter = penaltyChartData?.companyId
      ? `&companyId=${penaltyChartData.companyId}`
      : '';
    const { data } = await axios.get(
      `${predictAPI}/${penaltyChartData.chartType}Chart${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    await dispatch({ type: PENALTY_CHART_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: PENALTY_CHART_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
  }
};

const penaltyPieChart = (pieChartData) => async (dispatch) => {
  try {
    dispatch({ type: PENALTY_PIE_CHART_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;

    const type = pieChartData?.type ? `?type=${pieChartData?.type}` : '';
    const plant = pieChartData?.plantId
      ? `&plantId=${pieChartData?.plantId}`
      : '';
    const employeefilter = pieChartData?.employeeId
      ? `&employeeId=${pieChartData?.employeeId}`
      : '';
    const clientfilter = pieChartData?.clientId
      ? `&clientId=${pieChartData?.clientId}`
      : '';
    const filter = pieChartData?.companyId
      ? `&companyId=${pieChartData.companyId}`
      : '';
    // const type = pieChartData?.type ? `?type=${pieChartData.type}` : '';
    const { data } = await axios.get(
      `${predictAPI}/coordination${type}${plant}${employeefilter}${clientfilter}${filter}`,
      config,
    );
    await dispatch({ type: PENALTY_PIE_CHART_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: PENALTY_PIE_CHART_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
  }
};

const prediction = (plant) => async (dispatch) => {
  try {
    dispatch({ type: PREDICTION_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    // const { continent } = reduxStore.getState();
    const today = new Date();
    const date = moment(today).format('YYYY-MM-DD');
    const { data } = await axios.get(
      `${predictAPI}/predictionList?date=${date}&plantId=${plant}`,
      config,
    );
    await dispatch({ type: PREDICTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PREDICTION_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
  }
};

export { penaltyCards, penaltyChart, penaltyPieChart, prediction };
