import {
  PLANT_TYPE_CHANGE,
  EMPLOYEE_CHANGE,
  COMPANY_CHANGE,
  CLIENT_CHANGE,
  PLANT_CHANGE,
  FILTER_FAIL,
} from '../Types';

const plantTypeFilterAction = (payload) => async (dispatch) => {
  try {
    await dispatch({ type: PLANT_TYPE_CHANGE, payload });
  } catch (error) {
    dispatch({
      type: FILTER_FAIL,
      payload: error,
    });
  }
};

const employeeFilterAction = (payload) => async (dispatch) => {
  try {
    await dispatch({ type: EMPLOYEE_CHANGE, payload });
  } catch (error) {
    dispatch({
      type: FILTER_FAIL,
      payload: error,
    });
  }
};

const clientFilterAction = (payload) => async (dispatch) => {
  try {
    await dispatch({ type: CLIENT_CHANGE, payload });
  } catch (error) {
    dispatch({
      type: FILTER_FAIL,
      payload: error,
    });
  }
};

const companyFilterAction = (payload) => async (dispatch) => {
  try {
    await dispatch({ type: COMPANY_CHANGE, payload });
  } catch (error) {
    dispatch({
      type: FILTER_FAIL,
      payload: error,
    });
  }
};

const plantFilterAction = (payload) => async (dispatch) => {
  try {
    await dispatch({ type: PLANT_CHANGE, payload });
  } catch (error) {
    dispatch({
      type: FILTER_FAIL,
      payload: error,
    });
  }
};

export {
  plantTypeFilterAction,
  employeeFilterAction,
  clientFilterAction,
  companyFilterAction,
  plantFilterAction,
};
