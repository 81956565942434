import axios from 'axios';
import { toast } from 'react-toastify';

import { config } from '../../services/configHeader';
import reduxStore from '../Store';
import {
  PERMISSION_LIST_REQUEST,
  PERMISSION_LIST_SUCCESS,
  PERMISSION_LIST_FAIL,
  PERMISSION_VIEW_REQUEST,
  PERMISSION_VIEW_SUCCESS,
  PERMISSION_VIEW_FAIL,
} from '../Types';

const permissionAPI = `${process.env.REACT_APP_API_URL}employee`;

const permissionList = () => async (dispatch) => {
  try {
    dispatch({ type: PERMISSION_LIST_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { pdata } = await axios.get(
      `${permissionAPI}/permissionList`,
      config,
    );

    await dispatch({ type: PERMISSION_LIST_SUCCESS, payload: pdata });
  } catch (error) {
    dispatch({
      type: PERMISSION_LIST_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const permissionView = () => async (dispatch) => {
  try {
    dispatch({ type: PERMISSION_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(`${permissionAPI}/permissionView`, config);

    await dispatch({ type: PERMISSION_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PERMISSION_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

export { permissionList, permissionView };
