import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Modal, Button, Form, CloseButton } from 'react-bootstrap';
import { companyEdit, companyView } from '../../Stores/Action';
import input from '../../components/Form/input';

function CompanyDetail(props) {
  const dispatch = useDispatch();
  const { companyId, isDisable, onHide } = props;

  useEffect(() => {
    dispatch(companyView(companyId));
  }, []);
  const companyState = useSelector((state) => state.companyDetails);
  const data = companyState?.Company?.data;
  const successMessage = companyState?.edit;

  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    setFormValues({
      companyName: data?.companyName,
      ownername: data?.ownername,
      email: data?.email,
      joiningDate: data?.joiningDate,
      gstNumber: data?.gstNumber,
      panNumber: data?.panNumber,
      address: data?.address,
    });
  }, [data]);
  const uploadSingleFile = async () => {
    const formData = new FormData();
    // formData.append('companyName', formValues.companyName);
    // formData.append('ownername', formValues.ownername);
    // formData.append('email', formValues.email);
    // formData.append('gst', formValues.gstNumber);
    // formData.append('pan', formValues.panNumber);
    formData.append('address', formValues.address);
    formData.append('companyId', companyId);
    await dispatch(companyEdit(formData));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  useEffect(() => {
    // eslint-disable-next-line
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      uploadSingleFile();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    // const Name = /^[A-Za-z ]*$/;
    // const gstNumber = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    // const panNumber = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    // const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})/;

    // if (!values.companyName) {
    //   errors.companyName = 'Company Name is required!';
    // }
    // if (!values.ownername) {
    //   errors.ownername = 'Owner Name is required!';
    // } else if (!Name.test(values.ownername)) {
    //   errors.ownername = 'This is not a valid Name format!';
    // }

    // if (!values.email) {
    //   errors.email = 'owners is required';
    // } else if (!regex.test(values.email)) {
    //   errors.email = 'This owners email is not valid!';
    // }
    // if (!values.gstNumber) {
    //   errors.gstNumber = 'GST Number is required!';
    // } else if (!gstNumber.test(values.gstNumber)) {
    //   errors.gstNumber = 'This is not a valid GST format!';
    // } else if (values.gstNumber.length < 15) {
    //   errors.gstNumber = 'GST number is not Valid';
    // } else if (values.gstNumber.length > 15) {
    //   errors.gstNumber = 'GST number is not Valid';
    // }

    // if (!values.panNumber) {
    //   errors.panNumber = 'PAN Number is required!';
    // } else if (!panNumber.test(values.panNumber)) {
    //   errors.panNumber = 'This is not a valid PAN format!';
    // } else if (values.panNumber.length < 10) {
    //   errors.panNumber = 'PAN number is not Valid';
    // } else if (values.panNumber.length > 10) {
    //   errors.panNumber = 'PAN number is not Valid';
    // }

    if (!values.address) {
      errors.address = 'Address is required!';
    }
    return errors;
  };
  const handleHide = async () => {
    setFormErrors(false);
    onHide();
    setIsSubmit(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (isSubmit && successMessage) onHide();
  });

  return (
    <Modal {...props} dialogClassName="company-modal">
      <Modal.Body
        dialogClassName="company-form"
        style={{ display: 'block', padding: '0 1rem', height: '100%' }}
      >
        <div className="companyHeader">
          <h2
            style={{
              fontWeight: 'bolder',
              color: 'GrayText',
            }}
          >
            Company Details
          </h2>
          <CloseButton style={{ float: 'right' }} onClick={handleHide} />
        </div>
        <Form
          method="POST"
          id="CompanyDetail"
          onSubmit={handleSubmit}
          style={{ paddingBottom: '10px' }}
        >
          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.companyName,
            name: 'companyName',
            label: 'Company Name',
            disabled: true,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.ownername,
            errors: formErrors.ownername,
            name: 'ownername',
            label: 'Owners Name',
            disabled: true,
            maxLength: 50,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.email,
            errors: formErrors.email,
            name: 'email',
            label: 'Owners Email',
            disabled: true,
            maxLength: 50,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: moment(formValues.joiningDate).format('DD-MM-YYYY'),
            name: 'joiningDate',
            label: 'Joining Date',
            disabled: true,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.gstNumber,
            errors: formErrors.gstNumber,
            name: 'gstNumber',
            label: 'GST Number',
            disabled: true,
            maxLength: 15,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.panNumber,
            errors: formErrors.panNumber,
            name: 'panNumber',
            label: 'PAN Number',
            disabled: true,
            maxLength: 10,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.address,
            errors: formErrors.address,
            name: 'address',
            label: 'Address',
            disabled: isDisable,
          })}
          {isDisable ? (
            ''
          ) : (
            <div className="profileFooter">
              <Button
                type="submit"
                id="submit-button"
                style={{ marginBottom: '10px' }}
              >
                SAVE
              </Button>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CompanyDetail;
