import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import LogoImage from '../../components/assets/dp_logo.svg';
import Price from './price';
import Cases from './case';
import Contact from './contact';
import Services from './service';
import HomeSection from './home';
import Footer from './footer';

import './index.scss';

function Home() {
  const history = useLocation();
  const [active, setActive] = useState('home');
  const navigate = useNavigate();
  const handleActive = (to) => {
    ReactGA.event({
      action: 'take action',
      label: 'history state',
      value: history.state,
      category: history.state,
    });
    setActive(to);
    navigate('/', { state: to });
  };
  useEffect(() => {
    ReactGA.pageview('/', ['Home Tracker'], 'home');
    if (history.state) {
      setActive(history.state);
    }
  }, []);
  const activePage = () => {
    switch (active) {
      case 'us':
        return <Price />;
      case 'case':
        return <Cases />;
      case 'serv':
        return <Services />;
      case 'contact':
        return <Contact />;
      default:
        return <HomeSection setActive={setActive} />;
    }
  };

  return (
    <div className="Home-page">
      <header>
        {/* <!-- top-section --> */}
        <div className="top-section">
          <div className="wrapper">
            <div className="logo">
              <a href="/">
                <img src={LogoImage} width="170" alt="SBM Bank" />
              </a>
            </div>
            {/* <!-- secondary-nav --> */}
            <ul className="secondary-nav">
              {/* eslint-disable-next-line */}
              <li
                className={`${active === 'home' ? 'active' : ''}`}
                onClick={() => handleActive('home')}
              >
                <a href="#main">Home</a>
              </li>
              {/* eslint-disable-next-line */}
              <li
                className={`${active === 'serv' ? 'active' : ''}`}
                onClick={() => handleActive('serv')}
              >
                <a href="#main">Services</a>
              </li>
              {/* eslint-disable-next-line */}
              <li
                className={`${active === 'case' ? 'active' : ''}`}
                onClick={() => handleActive('case')}
              >
                <a href="#main">Cases</a>
              </li>
              {/* eslint-disable-next-line */}
              <li
                className={`${active === 'us' ? 'active' : ''}`}
                onClick={() => handleActive('us')}
              >
                <a href="#main">Pricing</a>
              </li>
              {/* eslint-disable-next-line */}
              {/* <li
                className={`${active === 'contact' ? 'active' : ''}`}
                onClick={() => handleActive('contact')}
              >
              <a href="#main">Contact Us</a>
              </li> */}
            </ul>
            <button
              type="button"
              onClick={() => navigate('login')}
              className="btn"
            >
              Login
            </button>
            {/* <!-- secondary-nav end --> */}
            {/* <a href="/" className="btn-menu icon-menu" /> */}
          </div>
        </div>
      </header>
      <section className="content landing">
        <div id="main" />
        <Routes>
          <Route path="/*" element={activePage()} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </section>
    </div>
  );
}

export default Home;
