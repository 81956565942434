import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row } from 'react-bootstrap';

import { OTP, ForgotPassword } from '../../../Stores/Action';

function Otp() {
  const { state } = useLocation();
  const [errorShow, setErrorShow] = useState(false);
  const [userInfo, setuser] = useState({
    otp: '',
    email: state.userInfo.email,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [timer, setTimer] = useState(30);

  const store = useSelector((reduxState) => reduxState.userDetails);
  const successMessage = store.success;
  const regex = /^([0-9]{6})+$/;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorShow(false);
    setuser({ ...userInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      // log.info('dispatch OTP');
      dispatch(OTP(userInfo));
      setIsSubmit(true);
    } else {
      // log.error('form error');
      setIsSubmit(false);
    }
  };
  const validateOtp = (values) => {
    const errors = {};
    if (!values.otp) {
      errors.otp = 'OTP required!';
    } else if (values.otp.length < 6) {
      errors.otp = 'OTP length must be 6!';
    } else if (!regex.test(values.otp)) {
      errors.otp = 'Only numbers are allowed';
    }
    return errors;
  };
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    [],
  );
  useEffect(() => {
    // eslint-disable-next-line
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = () => {
    if (!timer || timer === 0) {
      // log.info('Reset OTP');
      dispatch(ForgotPassword(userInfo));
      setTimer(30);
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    setFormErrors(validateOtp(userInfo));
  }, [userInfo]);

  useEffect(() => {
    if (successMessage && isSubmit) {
      // log.info('OTP verified');
      navigate('/login');
    }
  }, [successMessage]);

  return (
    <div className="forgot-password-page">
      <Row className="image-half" />
      <Row className="form-half">
        <img className="logo-image" src="/dp_logo.svg" alt="" />
        <p className="flex small-device-error">This Device is too small...</p>
        <Form className="form" onSubmit={handleSubmit}>
          <h2 className="form-header">OTP Verification</h2>
          <div>
            <input
              className="otpInput"
              id="otp"
              type="text"
              name="otp"
              placeholder="Enter OTP"
              value={userInfo.otp}
              onChange={handleChange}
              maxLength={6}
            />
            <p className="text-danger">{errorShow ? formErrors.otp : null}</p>
          </div>
          <div className="otp-timer">
            {timer !== 0 && `Resend OTP in ${timer}s`}
          </div>
          <div className="resend-otp">
            Didn&apos;t Receive the OTP?
            <Button
              className="under-line"
              disabled={timer !== 0}
              onClick={resetTimer}
            >
              Resend OTP
            </Button>
          </div>
          <div>
            <button type="submit" className="submit -button">
              Submit
            </button>
            {/* <button
              style={{
                width: 'max-content',
                //   color: '#2563EB',
                //   backgroundColor: '#FAFBFC',
              }}
              type="button"
              className="edit -button"
              onClick={() => navigate('/forgot')}
            >
              Forgot password?
            </button> */}
          </div>
        </Form>
      </Row>
    </div>
  );
}

export default Otp;
