import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from '../../Stores/Action';
import './FromFigma.scss';
import TabManage from './tabs';

export default function Sidebar({ indicate }) {
  const { activeIndex } = indicate;
  // const data = useSelector((state) => state.userDetails);
  // const username = data?.user?.userName || 'Your username';
  // const role = data?.user?.rolename || 'Your Role';
  const tabs = TabManage();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  return (
    <div className="sideCanvas">
      <div className="__logo">
        <img className="logo_image" alt="logo" />
      </div>
      <div className="__menu">
        {tabs?.map((item, index) => (
          <Link
            to={item.to}
            key={item.section}
            className={`__menuItem ${activeIndex === index ? 'active' : ''}`}
          >
            {item.icon}
            {item.display}
          </Link>
        ))}
      </div>
      <div style={{ flexGrow: 1 }} />
      <div className="__bottom">
        {/* eslint-disable-next-line */}
        {/* <div className="profile __section" onClick={() => showProfile()}>
          <div className="__image" />
          <div className="__name">
            <p className="name">{username}</p>
            <p className="role">{role}</p>
          </div>
        </div> */}
        <div className="help __section">
          <div className="__image" />
          <div className="__name">Get Help</div>
        </div>
        <div className="feedback __section">
          <div className="__image" />
          <div className="__name">Send Feedback</div>
        </div>
        <button
          type="button"
          className="logout __section"
          onClick={() => {
            dispatch(logout());
            navigate('/login');
            toast.info('User Logout Successfully');
          }}
        >
          <div className="__image" />
          <div className="__name">Logout</div>
        </button>
      </div>
    </div>
  );
}
