import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, CloseButton } from 'react-bootstrap';
import input from '../../components/Form/input';
import { addCompany } from '../../Stores/Action';

function AddCompany(props) {
  const [Employee, setEmployee] = useState([{ uuid: '' }]);
  const role = useSelector((state) => state.userDetails?.user?.rolename);
  const employeeData = useSelector((state) => state?.employeeList);
  const data = employeeData?.employee?.data?.employeeList;
  const clinetData = useSelector((state) => state?.company);
  const successmessage = clinetData.add;
  useEffect(() => {
    if (data?.length > 0) {
      setEmployee(data);
    }
  });
  const dispatch = useDispatch();
  const { onHide } = props;

  const initialValues = {
    companyName: '',
    email: '',
    gstNumber: '',
    panNumber: '',
    address: '',
    employeeId: '',
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errorShow, setErrorShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  // const [employeeId, setemployeeId] = useState('');

  const addCompanyData = async () => {
    const formData = new FormData();
    formData.append('companyName', formValues.companyName);
    // formData.append('ownerName', formValues.ownersName);
    formData.append('email', formValues.email);
    formData.append('gstNumber', formValues.gstNumber);
    formData.append('panNumber', formValues.panNumber);
    formData.append('address', formValues.address);
    if (role === 'company') {
      formData.append('employeeId', formValues.employeeId);
    }
    dispatch(addCompany(formData));
  };
  const validate = (values) => {
    const errors = {};
    // const Name = /^[A-Za-z ]*$/;
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})/;
    const gstNumber = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    const panNumber = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (!values.companyName) {
      errors.companyName = 'Company Name is required!';
    }
    // if (!values.ownersName) {
    //   errors.ownersName = 'Owner Name is required!';
    // } else if (!Name.test(values.ownersName)) {
    //   errors.ownersName = 'This is not a valid Name format!';
    // }

    if (!values.employeeId && role === 'company') {
      errors.employeeId = 'Employee Name is required!';
    }
    if (!values.email) {
      errors.email = 'owners is required';
    } else if (!regex.test(values.email)) {
      errors.email = 'This owners email is not valid!';
    }

    if (!values.gstNumber) {
      errors.gstNumber = 'GST Number is required!';
    } else if (!gstNumber.test(values.gstNumber)) {
      errors.gstNumber = 'This is not a valid GST format!';
    } else if (values.gstNumber.length < 15) {
      errors.gstNumber = 'GST number is not Valid';
    } else if (values.gstNumber.length > 15) {
      errors.gstNumber = 'GST number is not Valid';
    }

    if (!values.panNumber) {
      errors.panNumber = 'PAN Number is required!';
    } else if (!panNumber.test(values.panNumber)) {
      errors.panNumber = 'This is not a valid PAN format!';
    } else if (values.panNumber.length < 10) {
      errors.panNumber = 'PAN number is not Valid';
    } else if (values.panNumber.length > 10) {
      errors.panNumber = 'PAN number is not Valid';
    }

    if (!values.address) {
      errors.address = 'Address is required!';
    }
    // if (!values.aadharNumber) {
    //   errors.aadharNumber = 'Aadhar Number is required';
    // } else if (values.aadharNumber.length < 12) {
    //   errors.aadharNumber = 'Aadhar Number cannot exceed less than 10 characters';
    // } else if (values.aadharNumber.length > 12) {
    //   errors.aadharNumber = 'Aadhar number is not correct';
    // }
    return errors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorShow(false);
    setIsSubmit(false);
    if (name === 'panNumber' || name === 'gstNumber') {
      setFormValues({ ...formValues, [name]: value.toUpperCase() });
    } else setFormValues({ ...formValues, [name]: value });
  };
  // useEffect(() => {
  //   // eslint-disable-next-line
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     addCompanyData();
  //   }
  // }, [formErrors]);

  useEffect(() => {
    setFormErrors(validate(formValues));
  }, [formValues]);
  const handleHide = async () => {
    setFormErrors(false);
    onHide();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      setIsSubmit(true);
      addCompanyData();
      setErrorShow(false);
      setFormErrors('');
    }
  };

  useEffect(() => {
    if (isSubmit && successmessage) onHide();
  });

  return (
    <Modal {...props} dialogClassName="company-modal">
      <Modal.Body
        dialogClassName="company-form"
        style={{
          display: 'block',
          padding: '1 1rem',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <div className="companyHeader">
          <h2
            style={{
              fontWeight: 'bolder',
              color: 'GrayText',
            }}
          >
            Add Company
          </h2>
          <CloseButton style={{ float: 'right' }} onClick={handleHide} />
        </div>
        <Form id="companydetail" onSubmit={handleSubmit}>
          {input({
            handleChange,
            errorShow,
            value: formValues.companyName,
            errors: formErrors.companyName,
            name: 'companyName',
            label: 'Company Name',
            maxLength: 25,
          })}

          {input({
            handleChange,
            errorShow,
            value: formValues.email,
            errors: formErrors.email,
            name: 'email',
            label: 'Email',
          })}

          {input({
            handleChange,
            errorShow,
            value: formValues.gstNumber,
            errors: formErrors.gstNumber,
            name: 'gstNumber',
            maxLength: 15,
            label: 'GST Number',
          })}

          {input({
            handleChange,
            errorShow,
            value: formValues.panNumber,
            errors: formErrors.panNumber,
            name: 'panNumber',
            label: 'PAN Number',
            maxLength: 10,
          })}
          {/* {input({
            handleChange,
            errorShow,
            value: formValues.aadharNumber,
            errors: formErrors.aadharNumber,
            name: 'aadharNumber',
            label: 'Aadhar Number',
            maxLength: 12,
          })} */}
          {role === 'company' && (
            <div className="flex form-input-controller">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Employee Name</label>
              <div className="div-select">
                <select
                  name="employeeId"
                  className="select-employee"
                  onChange={(e) => {
                    // setemployeeId(e.target.value);
                    handleChange(e);
                  }}
                >
                  <option
                    style={{ color: 'gray' }}
                    aria-label="Not selected"
                    placeholder="not selected"
                    value=""
                    selected
                  >
                    select employee
                  </option>
                  {Employee.map((employee) => (
                    <option key={employee.userName} value={employee.uuid}>
                      {employee.userName}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errorShow ? formErrors.employeeId : null}
                </p>
              </div>
            </div>
          )}

          {input({
            handleChange,
            errorShow,
            value: formValues.address,
            errors: formErrors.address,
            name: 'address',
            label: 'Address',
          })}

          <div className="companyFooter">
            <Button type="submit" id="submit-button">
              Add
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddCompany;
