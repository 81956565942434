import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, CloseButton } from 'react-bootstrap';
// import Image from 'react-bootstrap/Image';
import moment from 'moment';
import {
  profileImageUpdate,
  profileUpdate,
  profileView,
} from '../../Stores/Action';
import input from '../../components/Form/input';
import './index.scss';

function Userprofile(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(profileView());
  }, []);
  const userData = useSelector((state) => state.userUpdate);
  const data = userData?.user?.data;
  const successMessage = userData?.edit;
  const imageData = useSelector((state) => state.imageUpdate);
  const successImageMessage = imageData?.edit;

  const [initialValues, setInitialValues] = useState({
    userName: data?.userName,
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    joiningDate: data?.joiningDate,
    phone: data?.phone,
    gender: data?.gender,
    // gstNumber: data?.gstNumber,
    // panNumber: data?.panNumber,
    // aadharNumber: data?.aadharNumber,
    address: data?.address,
    pinCode: data?.pinCode,
    permissions: data?.permissions,
    profileUrl: data?.profileUrl,
  });
  const { handleReset, onHide } = props;

  const [singleFile, setSingleFile] = useState('');
  // const [preview, setpreview] = useState();
  const [errorShow, setErrorShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [profileimage, setprofileimage] = useState('');

  useEffect(() => {
    if (data) {
      setInitialValues({ ...initialValues, ...data });
    }
    setprofileimage(data?.profileUrl);
  }, [data]);

  // const baseUrl = 'http://localhost:5000/';
  useEffect(() => {
    setprofileimage(data?.profileUrl);
  }, [profileimage]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    initialValues?.profileUrl;
    // ? setpreview(initialValues?.profileUrl)
    // : setpreview(undefined);
  }, [profileimage]);

  // const [formValue, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [disable, setDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(true);
  const SingleFileChange = (e) => {
    setSingleFile(e.target.files[0]);
  };

  const validate = (values) => {
    const errors = {};
    const Name = /^[A-Za-z ]*$/;
    const Phone = /^(([1-9]{2,4})[ -]*)*?[0-9]{3,6}?[ -]*[0-9]{3,6}?$/;
    const pinCode = /^[0-9]{1,5}-?[0-9]{1,5}/;
    // const gstNumber = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    // const panNumber = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (!values.firstName) {
      errors.firstName = 'First Name is required!';
    } else if (!Name.test(values.firstName)) {
      errors.firstName = 'This is not a valid Name format!';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required!';
    } else if (!Name.test(values.lastName)) {
      errors.lastName = 'This is not a valid Name format!';
    }

    // if (!values.gstNumber) {
    //   errors.gstNumber = 'GST Number is required!';
    // } else if (!gstNumber.test(values.gstNumber)) {
    //   errors.gstNumber = 'This is not a valid GST format!';
    // } else if (values.gstNumber.length < 15) {
    //   errors.gstNumber = 'GST number is not Valid';
    // } else if (values.gstNumber.length > 15) {
    //   errors.gstNumber = 'GST number is not Valid';
    // }

    // if (!values.panNumber) {
    //   errors.panNumber = 'PAN Number is required!';
    // } else if (!panNumber.test(values.panNumber)) {
    //   errors.panNumber = 'This is not a valid PAN format!';
    // } else if (values.panNumber.length < 10) {
    //   errors.panNumber = 'PAN number is not Valid';
    // } else if (values.panNumber.length > 10) {
    //   errors.panNumber = 'PAN number is not Valid';
    // }

    if (!values.phone) {
      errors.phone = 'Phone is required';
    } else if (values.phone.length < 10) {
      errors.phone = 'Phone cannot exceed less than 10 characters';
    } else if (values.phone.length > 10) {
      errors.phone = ' Phone number is not correct';
    } else if (!Phone.test(values.phone)) {
      errors.phone = 'This is not a valid GST format!';
    }

    if (!values.pinCode) {
      errors.pinCode = 'Pin Code is required';
    } else if (values.pinCode.length < 6) {
      errors.pinCode = 'Pin Code cannot exceed less than 6 characters';
    } else if (values.pinCode.length > 6) {
      errors.pinCode = ' Pin Code number is not correct';
    } else if (!pinCode.test(values.pinCode)) {
      errors.pinCode = 'This is not a valid GST format!';
    }

    if (!values.address) {
      errors.address = 'Address is required!';
    }
    // if (!values.aadharNumber) {
    //   errors.aadharNumber = 'Aadhar Number is required';
    // } else if (values.aadharNumber.length < 12) {
    //   errors.aadharNumber = 'Aadhar Number cannot exceed less than 10 characters';
    // } else if (values.aadharNumber.length > 12) {
    //   errors.aadharNumber = 'Aadhar number is not correct';
    // }

    return errors;
  };
  // useEffect(() => {
  //   if (!singleFile) {
  //     // setpreview(undefined);
  //     return;
  //   }

  //   // const objectUrl = URL.createObjectURL(singleFile);
  //   // setpreview(objectUrl);
  // }, [singleFile]);

  const handleChange = (e) => {
    setIsSubmit(false);
    setIsChange(true);
    const { name, value } = e.target;
    if (name === 'panNumber' || name === 'gstNumber') {
      setInitialValues({ ...initialValues, [name]: value.toUpperCase() });
    } else setInitialValues({ ...initialValues, [name]: value });
  };
  useEffect(() => {
    setFormErrors(validate(initialValues));
  }, [initialValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      if (isChange) {
        dispatch(
          profileUpdate({
            firstName: initialValues.firstName,
            lastName: initialValues.lastName,
            phone: initialValues.phone,
            gender: initialValues.gender,
            // gst: initialValues.gstNumber,
            // pan: initialValues.panNumber,
            address: initialValues.address,
            pinCode: initialValues.pinCode,
          }),
        );
      }
      if (singleFile) {
        const formData = new FormData();
        formData.append('file', singleFile);
        dispatch(profileImageUpdate(formData));
      }
      setIsSubmit(true);
      setErrorShow(false);
      setIsChange(false);
      setFormErrors('');
      setDisabled(true);
      setShow(!show);
      setVisible(!visible);
    } else setIsSubmit(false);
  };
  useEffect(() => {
    if (successMessage && isSubmit) {
      setIsChange(false);
      // onHide();
      setSingleFile('');
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  });
  useEffect(() => {
    if (successImageMessage && isSubmit) {
      setIsChange(false);
      setSingleFile('');
      // onHide();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  });
  const handleHide = async () => {
    setFormErrors(false);
    onHide();
    setDisabled(true);
    setVisible(true);
  };

  // const ImageAvailable = (
  //   <Image className="in-image" src={preview} alt="Update preview" />
  // );
  const notProfile = <div className="___image" />;

  // const profilePhoto = preview ? ImageAvailable : notProfile;
  const profilePhoto = notProfile;

  return (
    <Modal {...props} dialogClassName="modal-profile" onHide={handleHide}>
      <Modal.Body
        dialogClassName="profile-form"
        style={{
          display: 'block',
          padding: '0 1rem',
          height: '100%',
        }}
      >
        <div className="profileHeader">
          <h2
            style={{
              fontWeight: 'bolder',
              color: 'GrayText',
            }}
          >
            Profile Details
          </h2>
          <CloseButton style={{ float: 'right' }} onClick={handleHide} />
        </div>
        <Form method="POST" id="userprofile" onSubmit={handleSubmit}>
          <div className="profile-image" style={{ display: 'flex' }}>
            <label htmlFor="photo-upload" className="__image">
              <div>
                {!disable ? (
                  <input
                    id="photo-upload"
                    className="in-image"
                    type="file"
                    accept=".svg,.png,.jpg,.jpeg,.gif"
                    style={{ display: 'none' }}
                    onChange={(e) => SingleFileChange(e)}
                  />
                ) : (
                  <div />
                )}
                {profilePhoto}
                {!disable && <div className="camera" />}
              </div>
            </label>
            {visible && (
              <button
                type="button"
                className="edit-button"
                onClick={() => {
                  setShow(!show);
                  setDisabled(!disable);
                  setVisible(!visible);
                }}
              >
                Edit
              </button>
            )}
          </div>
          {input({
            handleChange,
            errorShow,
            value: initialValues.userName,
            name: 'userName',
            label: 'User Name',
            disabled: true,
          })}

          {input({
            handleChange,
            errorShow,
            value: initialValues.firstName,
            errors: formErrors.firstName,
            name: 'firstName',
            label: 'First Name',
            disabled: disable,
            maxLength: 50,
          })}

          {input({
            handleChange,
            errorShow,
            value: initialValues.lastName,
            errors: formErrors.lastName,
            name: 'lastName',
            label: 'Last Name',
            disabled: disable,
            maxLength: 50,
          })}

          {input({
            handleChange,
            errorShow,
            value: initialValues.email,
            name: 'email',
            label: 'Email',
            disabled: true,
          })}
          {input({
            handleChange,
            errorShow,
            value: moment(initialValues.joiningDate).format('DD-MM-YYYY'),
            name: 'joiningDate',
            label: 'Joining Date',
            disabled: true,
          })}

          {/* {input({
            handleChange,
            errorShow,
            value: initialValues.gstNumber,
            errors: formErrors.gstNumber,
            name: 'gstNumber',
            label: 'GST Number',
            disabled: disable,
            maxLength: 15,
          })}

          {input({
            handleChange,
            errorShow,
            value: initialValues.panNumber,
            errors: formErrors.panNumber,
            name: 'panNumber',
            label: 'PAN Number',
            disabled: disable,
            maxLength: 10,
          })} */}
          {/* {input({
            handleChange,
            errorShow,
            value: initialValues.aadharNumber,
            errors: formErrors.aadharNumber,
            name: 'aadharNumber',
            label: 'Aadhar Number',
            disabled: disable,
            maxLength: 12,
          })} */}

          {input({
            handleChange,
            errorShow,
            value: initialValues.phone,
            errors: formErrors.phone,
            name: 'phone',
            label: 'Phone Number',
            disabled: disable,
            maxLength: 10,
          })}
          <div className="flex form-input-controller">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Gender</label>
            <div style={{ width: '294px', color: 'gray' }} disabled={disable}>
              <select
                name="gender"
                className="select-gender"
                value={initialValues.gender}
                disabled={disable}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                {initialValues.gender?.length || (
                  <option value="" selected>
                    Select Gender
                  </option>
                )}
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          {input({
            handleChange,
            errorShow,
            value: initialValues.pinCode,
            errors: formErrors.pinCode,
            name: 'pinCode',
            label: 'Pincode',
            disabled: disable,
            maxLength: 6,
          })}
          {input({
            handleChange,
            errorShow,
            value: initialValues.address,
            errors: formErrors.address,
            name: 'address',
            label: 'Address',
            disabled: disable,
          })}
          <div className="profileFooter">
            <Button
              style={{ width: 'max-content', backgroundColor: '#FAFBFC' }}
              variant="primary"
              className="edit-button"
              onClick={handleReset}
            >
              Reset Password
            </Button>
            {visible ? (
              ''
            ) : (
              <Button type="submit" id="submit-button">
                SAVE
              </Button>
            )}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default Userprofile;
