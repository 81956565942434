import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { PieTooltip } from './tooltip';

function MyResponsivePie({ data, legend, margin, colors }) {
  return (
    <ResponsivePie
      data={data}
      tooltip={PieTooltip}
      colors={colors}
      margin={{
        top: margin ? margin.top || 20 : 20,
        right: margin ? margin.right || 20 : 20,
        bottom: margin ? margin.bottom || 20 : 20,
        left: margin ? margin.left || 20 : 20,
      }}
      sortByValue={false}
      innerRadius={0.75}
      activeOuterRadiusOffset={2}
      activeInnerRadiusOffset={2}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      legends={legend}
    />
  );
}

export default MyResponsivePie;
