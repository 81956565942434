import React from "react";
import LinkedinIcon from "../icons/LinkedinIcon";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <div className="relative py-16">
      <div className="container">
        <div className="mb-12">
          <h3 className="text-center font-epilogue text-10xl font-semibold text-textColor xl:text-7xl sm:text-6xl">
            Meet Our Team
          </h3>
        </div>
        <div className="grid grid-cols-2 gap-10 xl:gap-6 md:grid-cols-1">
          {[...Array(5)].map((_, index) => (
            <div className="overflow-hidden rounded-radius-4 bg-secondary px-8 py-6 xl:p-4">
              <div className="overflow-hidden rounded-radius-4">
                {/* <img
                className="aspect-video h-full w-full object-cover"
                src="/assets/images/deliver-1.png"
                alt="deliver-1"
              /> */}
              </div>
              <div className="mt-4 flex items-center gap-6">
                <div className="flex flex-1 flex-col gap-2 text-left md:gap-1">
                  <h4 className="text-5xl font-semibold text-codGray md:text-4xl">
                    Name
                  </h4>
                  <p className="text-base font-normal text-codGray">
                    Designation
                  </p>
                </div>
                <Link to={"/"} className="shrink-0">
                  <LinkedinIcon />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
