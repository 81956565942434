import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import { useGSAP } from '@gsap/react'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import RightArrow from '../icons/RightArrow'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

const cardsData = [
    {
        title: "Convert website traffic into qualified leads",
        subtitle: "Your chatbot interacts with every visitor coming to your site and asks them pre-qualifying questions. More interactions mean more leads."
    },
    {
        title: "Smartly answer FAQs with a breeze with ChatGPT",
        subtitle: "Train your chatbot with your website or files and have your chatbot ready to go. With the power of ChatGPT, answer any question like a pro and wow your users."
    },
    {
        title: "Get your calendars booked with an assistant",
        subtitle: "Save time going back and forth with prospects to book a slot. Our chatbots smartly qualify and book meetings directly on your sales teams’ calendars."
    },
    {
        title: "Take over conversations from chatbots when needed",
        subtitle: "Chatbots don't know everything. Using live chat, your agents monitor chats in real-time and jump in to offer a human experience, if needed."
    },
    {
        title: "Proactively engage users on SMS and WhatsApp",
        subtitle: "With outbound bots, your contacts are reached out to in a cadence on SMS and WhatsApp to get better response rates."
    }
]

const PlatformInfoSection = () => {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(0)

    const animeScope = useRef(null);
    const titleRef = useRef(null);


    useGSAP(() => {
        if (window.innerWidth > 575) {
            const cards = gsap.utils.toArray(".card");
            gsap.to(titleRef.current, {
                scrollTrigger: {
                    trigger: titleRef.current,
                    start: window.innerWidth < 1024 ? "center 15%" : "center 20%",
                    endTrigger: cards[cards.length - 1],
                    end: window.innerWidth < 1024 ? "top 35%" : "center 60%",
                    pin: true,
                    pinSpacing: false,
                    markers: false,
                    scrub: 1,
                    invalidateOnRefresh: true,
                },
            });
            const cardTl = gsap.timeline();
            cards.forEach((card, i) => {
                cardTl.set(card, { scale: 1 });
                cardTl.to(card, {
                    scale: () => 1 - (cards.length - i) * 0.015,
                    scrollTrigger: {
                        trigger: card,
                        start:
                            window.innerWidth < 1024
                                ? "top+=" + i * -10 + " 35%"
                                : "center+=" + i * -10 + " 60%",
                        endTrigger: cards[cards.length - 1],
                        end:
                            window.innerWidth < 1024
                                ? "top+=" + i * -10 + " 35%"
                                : "center 60%",
                        pin: true,
                        pinSpacing: false,
                        markers: false,
                        scrub: 1,
                        invalidateOnRefresh: true,
                    },
                });
            });
        }

    }, {
        dependencies: [cardsData],
        scope: animeScope,
    })




    return (
        <div className='py-28 xl:py-20 sm:py-16' ref={animeScope}>
            <div className="container">
                <div ref={titleRef} className="mx-auto mb-20 flex flex-col items-center justify-center gap-6 xl:mb-12 xl:gap-4">
                    <h2 className='text-center font-epilogue text-12xl font-semibold text-textColor xxl:text-6xl xl:max-w-[80%] sm:max-w-none sm:text-6xl'>
                        Your daily business problems solved with our easy chatbot platform
                    </h2>
                    <p className="max-w-[60%] text-center text-4xl font-normal text-textColor xxl:text-xl sm:max-w-none sm:text-base">
                        Our no-code chatbot platform has everything you need to automate your conversations at scale across channels.
                    </p>
                </div>

                {/* cards */}
                <div className=''>
                    {
                        cardsData.map((data, index) => {
                            return (
                                <div className="card relative mb-10 w-full rounded-radius-4 bg-secondary p-10 shadow-2xl shadow-black xxl:p-6 sm:mb-5 sm:p-5">
                                    <div className="flex gap-14 sm:flex-col-reverse sm:gap-5">
                                        <div className='w-1/2 sm:w-full' >
                                            <div className="mb-9 flex flex-col gap-2.5">
                                                <h4 className='text-5xl font-semibold text-black sm:text-xl'>{data.title}</h4>
                                                <p className='text-2xl text-textGray sm:text-base'>{data.subtitle}</p>
                                            </div>
                                            <Link to={'/'} className='btn-primary flex w-fit items-center gap-2'>
                                                <span className='block'>Learn More</span>
                                                <span className='flex items-center justify-center'>
                                                    <RightArrow sizeClass={'sm:size-4'} />

                                                </span>
                                            </Link>
                                        </div>
                                        <div className='aspect-video flex-1 overflow-hidden rounded-radius-4 xxl:max-h-60 sm:aspect-[unset] sm:max-h-none'>
                                            <img className='w-full' src="/assets/images/platform-demo.jpg" alt="platform" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {/* <div className='relative sm:hidden'>
                        <div className={`invisible overflow-hidden rounded-radius-6`}>
                            <img className='w-full' src="/assets/images/platform-img-1.png" alt="platform" />
                        </div>
                        {
                            [1, 2, 3, 4, 5].map((item, index) => {
                                return (

                                    <div key={index} className={`absolute inset-0 ${openAccordionIndex === index ? 'opacity-100' : 'opacity-0'} overflow-hidden rounded-radius-6 duration-500`}>

                                        <img className='w-full' src={`/assets/images/platform-img-${index + 1}.png`} alt={index} />
                                    </div>
                                )
                            })
                        }

                    </div> */}

            </div >

        </div >
    )
}

export default PlatformInfoSection
