import React from 'react'
import { Link } from 'react-router-dom'
import { PRODUCT_NAME } from '../../constants'

const CtaSection = () => {
    return (
        <div className='py-28 xl:py-20 sm:py-0'>
            <div className="container sm:p-0">
                <div className="relative overflow-hidden rounded-radius-6 bg-secondary sm:rounded-none">
                    <div className="pointer-events-none absolute inset-0 z-0 sm:inset-[unset] sm:-left-[20%] sm:bottom-0 sm:h-full sm:w-full">
                        <img className='h-full w-full object-cover' src="/assets/images/cta-bg-pattern.svg" alt="cta-bg-pattern" />
                    </div>
                    <div className="relative z-[1] px-28 pb-36 pt-24 xl:p-20 sm:px-4 sm:py-16">
                        <div className="mb-10 flex flex-col items-center justify-center gap-4 sm:mb-7">
                            <h3 className='text-center font-epilogue text-10xl font-semibold text-codGray sm:text-6xl'>
                                Start building your chatbots today!
                            </h3>
                            <p className='text-center text-4xl font-normal text-codGray sm:text-base'>Curious to know how {PRODUCT_NAME} can help you? Let’s talk.</p>
                        </div>
                        <div className="flex items-center justify-center gap-7 sm:w-full sm:flex-col sm:gap-4">
                            <Link to="/" className="btn-primary black sm:w-full">Book a demo</Link>
                            <Link to="/" className="btn-secondary black sm:w-full">Try for free</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CtaSection
