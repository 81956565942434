import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, CloseButton, Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { addMultiCompany } from '../../Stores/Action';
import './index.scss';

function AddMultiCompany(props) {
  const [bulkCompanyData, setBulkCompanyData] = useState([]);
  const dispatch = useDispatch();
  const { onHide, setFile } = props;

  const handleHide = async () => {
    setBulkCompanyData('');
    onHide();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch(addMultiCompany(bulkCompanyData));
    onHide();
  };
  const readExcel = (file) => {
    if (file.type === 'application/vnd.ms-excel' || file.type === '.csv') {
      const promise = new Promise((resolve, reject) => {
        const filrReader = new FileReader();
        filrReader.readAsArrayBuffer(file);
        filrReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: 'buffer' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);

          resolve(data);

          FileReader.onerror = (error) => {
            reject(error);
          };
        };
      });

      promise.then((dt) => {
        setBulkCompanyData(dt);
      });
    }
  };
  useEffect(() => {
    const files = setFile.files[0];
    readExcel(files);
  }, []);
  return (
    <div style={{ width: '100%' }}>
      <Modal
        {...props}
        backdropClassName="back-drop-plant"
        className="multi-modal"
        style={{ height: '100vh' }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="multi">
          <div className="multi-header">
            <h2
              style={{
                fontWeight: 'bolder',
                color: 'GrayText',
              }}
            >
              Add Company
            </h2>
            <CloseButton style={{ float: 'right' }} onClick={handleHide} />
          </div>

          {bulkCompanyData.length >
            0 /* eslint operator-linebreak: ["error", "after"] */ && (
            <Table className="multi-company-page" hover>
              <thead>
                <div className="tabHead">
                  <tr>
                    <th>Company Name</th>
                    <th>Owners Name</th>
                    <th>Owners Email</th>
                    <th>GST Number</th>
                    <th>Pan Number</th>
                    <th>Address</th>
                    <th>Employee Name</th>
                  </tr>
                </div>
              </thead>
              <tbody>
                <div className="tabBody">
                  {bulkCompanyData.map((item) => (
                    <tr>
                      <td>{item.companyName}</td>
                      <td>{item.ownersName}</td>
                      <td>{item.ownersEmail}</td>
                      <td>{item.gst}</td>
                      <td>{item.pan}</td>
                      <td>{item.address}</td>
                      <td>{item.employeeName}</td>
                    </tr>
                  ))}
                </div>
              </tbody>
            </Table>
          )}
          <div className="btn-reset">
            <Button
              type="button"
              style={{ margin: '0 24px' }}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddMultiCompany;
