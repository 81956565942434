import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import ReactGA from 'react-ga';
// import moment from 'moment';
import Plant from '../Plant/index';
import PenaltyChart from '../../components/dashboard/graphs/Line';

import {
  clientList,
  employeeList,
  plantType,
  companyList,
  employeeFilter,
  companyFilter,
  forecastingCards,
  plantList,
} from '../../Stores/Action';
import Filter from './Filter';
import ForecastingTable from '../Plant/PlantPrediction/ForecastingTable';
import { DummyLine } from '../../components/dashboard/Dummies';
import { chart1, month, week } from '../../services/DummyDatas';

function Bartool({ data, color }) {
  return (
    <p
      style={{
        color: 'white',
        padding: 4,
        borderRadius: 8,
        border: '1px solid rgb(128,128,128,0.5)',
        backgroundColor: color,
      }}
    >
      {`${data.y.toFixed(3)} W`}
      <p
        style={{
          fontSize: 'small',
          paddingTop: 1,
          paddingBottom: 1,
          margin: 0,
        }}
      >
        {data.x}
      </p>
    </p>
  );
}

function PieTooltip({ datum }) {
  return (
    <p
      style={{
        backgroundColor: datum.color,
        padding: 4,
        borderRadius: 8,
        color: 'white',
      }}
    >
      {`${datum.value} W`}
    </p>
  );
}
export default function Dashboard() {
  const dispatch = useDispatch();
  const permission = useSelector((state) => state.permissions.data);
  const role = useSelector((state) => state.userDetails.user?.rolename);
  const region = useSelector((state) => state?.continent);

  const [svalue, setSValue] = useState({
    plant: '',
    employee: '',
    client: '',
    company: '',
    type: '',
  });
  const plantTypes = useSelector((state) => state?.plantType?.plantType?.data);
  const typesData = plantTypes?.map((t) => {
    const i = { value: t.type, label: t.label };
    return i;
  });
  const [types, setType] = useState('');
  const [singles, setSingles] = useState(false);
  const xData = useSelector((state) => state.prediction?.data);
  const predictionData = xData ? xData?.data : [];
  const coordinationData = [
    {
      value: '1240',
      id: 'Apr-Jun',
      year: '2023',
    },
    {
      value: '1300',
      id: 'Jan-March',
      year: '2024',
    },
    {
      value: '2000',
      id: 'Jul-Sept',
      year: '2023',
    },
    {
      value: '1000',
      id: 'Oct-Dec',
      year: '2023',
    },
  ];

  useEffect(() => {
    if (types?.length) {
      // setInterval(() => {
      dispatch(forecastingCards({ type: types }));
      // }, 3 * 1000);
    }
  }, [types]);
  useEffect(() => {
    ReactGA.pageview('/dashboard', ['Dashboard Page'], 'dashboard');
    dispatch(plantType({ continent: region }));
    dispatch(companyList({ pageSize: 100, pageCount: 0 }));
    if (role === 'company' || role === 'employee') dispatch(clientList());
    if (role === 'company') dispatch(employeeList());
    setSingles(false);
    setSValue({ ...svalue, type: typesData?.[0] });
  }, [region]);
  useEffect(() => {
    if (plantTypes?.length) {
      setSValue({ ...svalue, type: typesData?.[0] });
      setType(plantTypes?.[0]?.type);
      if (role === 'company') {
        dispatch(
          employeeFilter({
            continent: region,
            type: typesData?.[0]?.value,
          }),
        );
      }
      dispatch(
        companyFilter({
          continent: region,
          type: typesData?.[0]?.value,
        }),
      );
    }
  }, [plantTypes, region]);
  useEffect(() => {
    if (types?.length) {
      dispatch(
        plantList({
          continent: region,
          type: types,
          pageCount: 0,
          pageSize: 10,
        }),
      );
    }
  }, [types, region]);
  const plants = useSelector((state) => state?.plantFilter?.plantFilter?.data);
  const forecastingCardsState = useSelector(
    (state) => state?.forecastingCards.data,
  );
  const min15 = forecastingCardsState?.min15;
  const min30 = forecastingCardsState?.min30;
  const hour = forecastingCardsState?.hour;
  const day = forecastingCardsState?.day;
  const [pieLegendHidden, setPieLegendHidden] = useState([]);
  const [ActiveCordData, setActiveCordData] = useState(coordinationData);

  const color = ['#2FBA8E', '#CC2266', '#6A4EE7', '#FFAB1F'];
  const pieSpec = {
    toggleSerie: true,
    anchor: 'bottom',
    direction: 'row',
    itemWidth: 100,
    itemHeight: 10,
    itemsSpacing: 10,
    symbolSize: 10,
    symbolShape: 'circle',
    itemDirection: 'left-to-right',
  };
  const pieDataHalf = Math.ceil(coordinationData.length / 2);
  const pieLegendClick = (d) => {
    if (!d.hidden) {
      setPieLegendHidden([...pieLegendHidden, d.id]);
    } else if (coordinationData?.find((i) => i.id === d.id).value === '0') {
      setPieLegendHidden(pieLegendHidden);
    } else {
      setPieLegendHidden(pieLegendHidden.filter((i) => i !== d.id));
    }
  };
  useEffect(() => {
    setActiveCordData(
      coordinationData.filter((i) => !pieLegendHidden.includes(i.id)),
    );
  }, [coordinationData, pieLegendHidden]);

  useEffect(() => {
    setPieLegendHidden([
      ...coordinationData.map((i) => {
        if (i.value === '0') return i.id;
        return false;
      }),
    ]);
  }, []);
  const pieSplitLegend = [
    {
      data: coordinationData?.slice(0, pieDataHalf).map((i, n) => {
        const temp = {
          id: i.id,
          label: `${i.id} ${i.year}`,
          color: color[n],
          hidden: pieLegendHidden.includes(i.id),
        };
        return temp;
      }),
      onClick: pieLegendClick,
      translateY: 20,
      ...pieSpec,
    },
    {
      data: coordinationData
        ?.slice(pieDataHalf, coordinationData.length)
        .map((i, n) => {
          const temp = {
            id: i.id,
            label: `${i.id} ${i.year}`,
            color: color[pieDataHalf + n],
            hidden: pieLegendHidden.includes(i.id),
          };
          return temp;
        }),
      onClick: pieLegendClick,
      translateY: 50,
      ...pieSpec,
    },
  ];

  const AgSg = [
    {
      id: 'AG',
      color: 'rgb(0, 0, 255)',
      data: predictionData?.map((i) => ({
        x: i['Time Block'],
        y: parseFloat(i.AG)?.toFixed(2),
      })),
    },
    {
      id: 'SG',
      color: 'rgb(0, 255, 0)',
      data: predictionData?.map((i) => ({
        x: i['Time Block'],
        y: parseFloat(i.SG)?.toFixed(2),
      })),
    },
  ];

  const mae = [
    {
      id: 'MAE',
      color: 'rgb(0, 0, 255)',
      data: predictionData?.map((i) => ({
        x: i['Time Block'],
        y: parseFloat(i.MAE)?.toFixed(2),
      })),
    },
  ];

  const dumm = chart1.reduce((p, i) => {
    const da = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      i.block.slice(8).split(':')[0],
      i.block.slice(8).split(':')[1],
    );
    if (da < new Date()) {
      const d = {
        x: i.block,
        y: i.AG,
      };
      p.push(d);
      return p;
    }
    const d = { x: i.block, y: null };
    p.push(d);
    return p;
  }, []);
  const dumm2 = chart1.map((i) => {
    const d = {
      x: i.block,
      y: i.SG,
    };
    return d;
  });

  const barChartData = chart1.map((i, x) => {
    const f = {
      x: x + 1,
      y: i.SG,
    };
    return f;
  });
  // const utcDate = '2022-01-15T10:50:17Z';
  // console.log(new Date(utcDate).toLocaleTimeString(), moment(new Date()));
  const checkPlantPermission = permission?.includes('PLANT') || true;
  return (
    <div className="eu">
      <Filter
        continent={region}
        single={setSingles}
        type={setType}
        svalue={svalue}
        setSValue={setSValue}
      />
      {/* {isLoading ? ( */}
      {/* <LoadingSpinner />
      ) : ( */}
      <Row>
        <div className="cards">
          <Card className="card-item one">
            <Card className="name">15 min</Card>
            {/* <div className="name">15 min</div> */}
            <div className="value">{min15 && `${min15?.SG} W`}</div>
            <div className="block">
              {min15 && new Date(min15?.Time).toLocaleTimeString()}
            </div>
          </Card>
          <Card className="card-item two">
            <Card className="name">30 min</Card>
            {/* <div className="name">30 min</div> */}
            <div className="value">{min30 && `${min30?.SG} W`}</div>
            <div className="block">
              {min30 && new Date(min30?.Time).toLocaleTimeString()}
            </div>
          </Card>
          <Card className="card-item four">
            <Card className="name">60 min</Card>
            {/* <div className="name">30 min</div> */}
            <div className="value">{hour && `${hour?.SG} W`}</div>
            <div className="block">
              {hour && new Date(hour?.Time).toLocaleTimeString()}
            </div>
          </Card>
          <Card className="card-item three">
            <Card className="name">24 hours</Card>
            {/* <div className="name">15 min</div> */}
            <div className="value">{day && `${day?.Generation} W`}</div>
            <div className="block">
              {day && new Date(day?.Date).toLocaleDateString()}
            </div>
          </Card>
          <Card className="card-item two">
            <Card className="name">7 days</Card>
            {/* <div className="name">15 min</div> */}
            <div className="value">{`${week?.SG?.toFixed(2)} W`}</div>
            <div className="block">{week?.block}</div>
          </Card>
          <Card className="card-item four">
            <Card className="name">30 days</Card>
            {/* <div className="name">15 min</div> */}
            <div className="value">{`${month?.SG?.toFixed(2)} W`}</div>
            <div className="block">{month?.block}</div>
          </Card>
        </div>
        <div className="charts">
          {/* <Col xs={12} lg={6} className="centerGraph">
            <div className="Graph-div">
              <Col className="penalty-label">
                Today&apos;s Generation & Forecast
                <button
                    type="button"
                    onClick={() => setCard(!card)}
                    className="none-btn"
                  >
                    ...
                  </button>
              </Col>
              <Col className="penalty-graph">
                <PenaltyChart
                  data={[
                    {
                      id: 'Forecast',
                      color: 'red',
                      data: dumm2,
                    },
                    {
                      id: 'Generated',
                      color: 'darkgreen',
                      data: dumm,
                    },
                  ]}
                  tip="AGSG"
                />
              </Col>
            </div>
          </Col> */}
          <Col xs={12} lg={12} className="centerGraph">
            <div className="Graph-div">
              <Col className="penalty-label">
                Today v/s Yesterday Generation
                {/* <button
                    type="button"
                    onClick={() => setCard(!card)}
                    className="none-btn"
                  >
                    ...
                  </button> */}
              </Col>
              <Col className="penalty-graph">
                <PenaltyChart
                  data={[
                    {
                      id: 'Yesterday',
                      color: 'blue',
                      data: chart1.map((i) => {
                        const d = {
                          x: i.block,
                          y: i.AG + 12,
                        };
                        return d;
                      }),
                    },
                    {
                      id: 'Forecast',
                      color: 'red',
                      data: dumm2,
                    },
                    {
                      id: 'Generated',
                      color: 'darkgreen',
                      data: dumm,
                    },
                  ]}
                  tip="AGSG"
                />
              </Col>
            </div>
          </Col>
          <Col xs={12} lg={6} className="centerGraph">
            <div className="Graph-div">
              <Col className="penalty-label">
                Months Generation
                {/* <button
                    type="button"
                    onClick={() => setCard(!card)}
                    className="none-btn"
                  >
                    ...
                  </button> */}
              </Col>
              <Col className="penalty-graph">
                <ResponsiveBar
                  data={barChartData.filter((i, x) => x < 30)}
                  keys={['y']}
                  indexBy="x"
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  valueScale={{ type: 'symlog' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={['#009f88']}
                  borderRadius={2}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={null}
                  axisLeft={null}
                  enableGridY={false}
                  enableLabel={false}
                  tooltip={Bartool}
                  padding={0.2}
                />
              </Col>
            </div>
          </Col>
          <Col xs={12} lg={6} className="pieChart">
            <div className="Graph-div">
              <Col className="penalty-label">
                Quarter Yearly
                {/* <button
                    type="button"
                    onClick={() => navigate('../plant/predictionTable')}
                    className="none-btn"
                  >
                    <img src={moreOption} alt="moreOption" />
                  </button> */}
              </Col>
              <Col className="penalty-graph">
                {coordinationData && (
                  <>
                    <div className="center-total">
                      {`${ActiveCordData.reduce(
                        (p, i) => p + parseFloat(i.value),
                        0,
                      )} W`}
                    </div>
                    <ResponsivePie
                      data={coordinationData}
                      tooltip={PieTooltip}
                      colors={color}
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 70,
                        left: 20,
                      }}
                      sortByValue={false}
                      innerRadius={0.75}
                      activeOuterRadiusOffset={2}
                      activeInnerRadiusOffset={2}
                      enableArcLabels={false}
                      enableArcLinkLabels={false}
                      legends={pieSplitLegend}
                    />
                  </>
                )}
              </Col>
            </div>
          </Col>
        </div>
      </Row>
      {/* )} */}
      {!singles ? (
        <div className="table-co">
          {checkPlantPermission && (
            <Row className="dashboardTable">
              <Col>
                <div>
                  <Plant
                    continent="EU"
                    svalue={svalue}
                    setSValue={setSValue}
                    types={types}
                    singles={setSingles}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <div>
          <Row>
            <Col xl={12} xs={12} className="Charts">
              <Row>
                <Col xl={6} xs={12} className="centerGraph">
                  <div className="Graph-div">
                    <Col className="penalty-label">
                      Prediction & Actual Data
                      {/* <button
                        type="button"
                        onClick={() => predNav()}
                        className="none-btn"
                      >
                        <img src={moreOption} alt="moreOption" />
                      </button> */}
                    </Col>
                    <Col className="penalty-graph">
                      {predictionData?.length ? (
                        <PenaltyChart tip="AGSG" data={AgSg} />
                      ) : (
                        <DummyLine place="AGSG" />
                      )}
                    </Col>
                  </div>
                </Col>
                <Col xl={6} xs={12} className="centerGraph">
                  <div className="Graph-div">
                    <Col className="penalty-label" title="Mean Absolute Error">
                      MAE
                      {/* <button
                        type="button"
                        onClick={() => predNav()}
                        className="none-btn"
                      >
                        <img src={moreOption} alt="moreOption" />
                      </button> */}
                    </Col>
                    <Col className="penalty-graph">
                      {predictionData?.length ? (
                        <PenaltyChart tip="MAE" data={mae} />
                      ) : (
                        <DummyLine place="MAE" />
                      )}
                    </Col>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="dashboardTable">
            <Col>
              <div>
                <ForecastingTable plantPredictionId={svalue.plant?.value} />
              </div>
            </Col>
          </Row>
          {plants?.length === 1 && (
            <Row className="dashboardTable">
              <Col>
                <div>
                  <Plant
                    continent="EU"
                    svalue={svalue}
                    setSValue={setSValue}
                    types={types}
                    singles={setSingles}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
}
