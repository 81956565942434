import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { ResponsiveBar } from '@nivo/bar';
import './index.scss';
import moreOption from '../../../components/assets/moreOption.svg';
import LineChart from '../../../components/dashboard/graphs/Line';
import PieChart from '../../../components/dashboard/graphs/Pie';
import BarChart from '../../../components/dashboard/graphs/Bar';
import { BarTooltip } from '../../../components/dashboard/graphs/tooltip';
import {
  agsg,
  mae,
  MonthsBar,
  pie,
  smallPie,
  yearlyBar,
} from '../../../services/DummyDatas';
import PredictionTable from './PredictionTable';

export default function PlantPrediction() {
  const navigate = useNavigate();
  const history = useLocation();
  const predNav = () => {
    navigate('../plant/predictionTable', { state: history.state });
  };
  return (
    <>
      <Row>
        <Col xl={8} xs={12} className="Charts">
          <Row>
            <Col xl={6} xs={12} className="centerGraph">
              <div className="Graph-div">
                <Col className="penalty-label">
                  Prediction & Actual Data
                  <button
                    type="button"
                    onClick={() => predNav()}
                    className="none-btn"
                  >
                    <img src={moreOption} alt="moreOption" />
                  </button>
                </Col>
                <Col className="penalty-graph">
                  <LineChart data={agsg} />
                </Col>
              </div>
            </Col>
            <Col xl={6} xs={12} className="centerGraph">
              <div className="Graph-div">
                <Col className="penalty-label">
                  Mean absolute error
                  <button
                    type="button"
                    onClick={() => predNav()}
                    className="none-btn"
                  >
                    <img src={moreOption} alt="moreOption" />
                  </button>
                </Col>
                <Col className="penalty-graph">
                  <LineChart data={mae} />
                </Col>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6} xs={12} className="centerGraph">
              <div className="Graph-div">
                <Col className="penalty-label">
                  Past Years Penalty
                  <button
                    type="button"
                    onClick={() => predNav()}
                    className="none-btn"
                  >
                    <img src={moreOption} alt="moreOption" />
                  </button>
                </Col>
                <Col className="penalty-graph">
                  <ResponsiveBar
                    data={yearlyBar}
                    keys={['Penalty']}
                    indexBy="Block"
                    colorBy="indexValue"
                    margin={{ top: 10, right: 240, bottom: 60, left: 50 }}
                    groupMode="grouped"
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'category10' }}
                    borderRadius={8}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', '1.4']],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 0,
                      tickPadding: 8,
                      tickRotation: 0,
                      legend: '',
                      ticksPosition: 'before',
                    }}
                    axisLeft={{
                      tickSize: 0,
                      tickPadding: 4,
                      tickRotation: 0,
                      legend: '',
                    }}
                    enableGridY={false}
                    enableLabel={false}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: 'indexes',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 180,
                        translateY: 0,
                        itemsSpacing: 9,
                        itemWidth: 97,
                        itemHeight: 20,
                        symbolShape: 'circle',
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 14,
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    tooltip={BarTooltip}
                    role="application"
                    ariaLabel="Nivo bar chart demo"
                    padding={0.7}
                  />
                </Col>
              </div>
            </Col>
            <Col xl={6} md={12} xs={12} className="centerGraph">
              <div className="Graph-div">
                <Col className="penalty-label">
                  Months Weekly Penalty
                  <button
                    type="button"
                    onClick={() => predNav()}
                    className="none-btn"
                  >
                    <img src={moreOption} alt="moreOption" />
                  </button>
                </Col>
                <Col className="penalty-graph">
                  <PieChart
                    data={smallPie}
                    margin={{ bottom: 80 }}
                    legend={[
                      {
                        toggleSerie: true,
                        data: [
                          {
                            id: pie[0].id,
                            label: pie[0].id,
                            color: '#7E22CE',
                          },
                          {
                            id: pie[1].id,
                            label: pie[1].id,
                            color: '#1D4ED8',
                          },
                        ],
                        anchor: 'bottom',
                        direction: 'row',
                        // justify: false,
                        // translateX: 0,
                        translateY: 30,
                        itemWidth: 70,
                        itemHeight: 10,
                        itemsSpacing: 10,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        itemDirection: 'left-to-right',
                      },
                      {
                        toggleSerie: true,
                        data: [
                          {
                            id: pie[2].id,
                            label: pie[2].id,
                            color: '#EC4899',
                          },
                          {
                            id: pie[3].id,
                            label: pie[3].id,
                            color: '#DBEAFE',
                          },
                        ],
                        anchor: 'bottom',
                        direction: 'row',
                        // justify: false,
                        // translateX: 0,
                        translateY: 60,
                        itemWidth: 70,
                        itemHeight: 10,
                        itemsSpacing: 10,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        itemDirection: 'left-to-right',
                      },
                    ]}
                  />
                </Col>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={4} xs={12} className="pieChart">
          <div className="Graph-div">
            <Col className="penalty-label">
              Quarter Yearly Penalty
              <button
                type="button"
                onClick={() => predNav()}
                className="none-btn"
              >
                <img src={moreOption} alt="moreOption" />
              </button>
            </Col>
            <Col className="penalty-graph">
              <PieChart
                data={pie}
                margin={{ bottom: 50, top: 0 }}
                legend={[
                  {
                    toggleSerie: true,
                    data: [
                      {
                        id: pie[0].id,
                        label: pie[0].id,
                        color: '#7E22CE',
                      },
                      {
                        id: pie[1].id,
                        label: pie[1].id,
                        color: '#1D4ED8',
                      },
                    ],
                    anchor: 'bottom',
                    direction: 'row',
                    // justify: false,
                    // translateX: 0,
                    translateY: -10,
                    itemWidth: 70,
                    itemHeight: 10,
                    itemsSpacing: 10,
                    symbolSize: 10,
                    symbolShape: 'circle',
                    itemDirection: 'left-to-right',
                  },
                  {
                    toggleSerie: true,
                    data: [
                      {
                        id: pie[2].id,
                        label: pie[2].id,
                        color: '#EC4899',
                      },
                      {
                        id: pie[3].id,
                        label: pie[3].id,
                        color: '#DBEAFE',
                      },
                    ],
                    anchor: 'bottom',
                    direction: 'row',
                    // justify: false,
                    // translateX: 0,
                    translateY: 20,
                    itemWidth: 70,
                    itemHeight: 10,
                    itemsSpacing: 10,
                    symbolSize: 10,
                    symbolShape: 'circle',
                    itemDirection: 'left-to-right',
                  },
                ]}
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Row className="MonthBar">
        <div className="Graph-div">
          <Col className="penalty-label">
            Monthly Penalty
            <button
              type="button"
              onClick={() => predNav()}
              className="none-btn"
            >
              <img src={moreOption} alt="moreOption" />
            </button>
          </Col>
          <Col className="penalty-graph">
            <BarChart data={MonthsBar} />
          </Col>
        </div>
      </Row>
      <PredictionTable />
    </>
  );
}
