import React from 'react';

function NotFound() {
  return (
    <div>
      <div className="notFound">
        <h1 style={{ color: '#84BD18' }}>Page not found</h1>
        <div className="notFound-gif" />
      </div>
    </div>
  );
}

export default NotFound;
