import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import AppRoutes from './components/Routes';

function App() {
  return (
    <>
      <main>
        <Header />
        <AppRoutes />
        <Footer />
      </main>
    </>

  );
}

export default App;
