import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, CloseButton, Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { addMultiPlant } from '../../Stores/Action';
import './index.scss';

function AddMultiPlant(props) {
  const [bulkPlantData, setBulkPlantData] = useState([]);
  const dispatch = useDispatch();

  const { onHide, setFiles } = props;

  const handleHide = async () => {
    onHide();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // setErrorShow(true);
    // if (Object.keys(formErrors).length === 0) {
    dispatch(addMultiPlant(bulkPlantData));
    // setErrorShow(false);
    // setformErrors('');
    onHide();
    // }
  };

  const readExcel = (file) => {
    // if (file.type === 'application/vnd.ms-excel' || file.type === '.csv') {
    const promise = new Promise((resolve, reject) => {
      const filrReader = new FileReader();
      filrReader.readAsArrayBuffer(file);
      filrReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);

        FileReader.onerror = (error) => {
          reject(error);
        };
      };
    });

    promise.then((dt) => {
      setBulkPlantData(dt);
    });
    // }
  };
  useEffect(() => {
    const files = setFiles.files[0];
    readExcel(files);
  }, []);
  // const handleChange = (event) => {
  //   const fileObj = event.target.files[0];
  //   readExcel(fileObj);
  //   // setBulkPlantData();
  // };
  return (
    <div style={{ width: '100%' }}>
      <Modal
        {...props}
        backdropClassName="back-drop-plant"
        className="multi-modal"
        style={{ height: '100vh' }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="multi">
          <div className="multi-header">
            <h2
              style={{
                fontWeight: 'bolder',
                color: 'GrayText',
              }}
            >
              Add Plant
            </h2>
            <CloseButton style={{ float: 'right' }} onClick={handleHide} />
          </div>

          {bulkPlantData.length >
            0 /* eslint operator-linebreak: ["error", "after"] */ && (
            <Table className="multi-plant-page" hover>
              <thead>
                <div className="tabHead">
                  <tr>
                    <th>Plant Name</th>
                    <th>Plant Type</th>
                    <th>Location</th>
                    <th>Latitude</th>
                    <th>Longtitude</th>
                    <th>Unit Rate</th>
                    <th>Penalty Rate</th>
                    <th>Key String</th>
                    <th>Database server</th>
                    <th>Database Password</th>
                    <th>Socket Link</th>
                  </tr>
                </div>
              </thead>
              <tbody>
                <div className="tabBody">
                  {bulkPlantData.map((item) => (
                    <tr>
                      {/* <tr onClick={() => handlePlantNav(item.plantuuid)}> */}
                      <td>{item.name}</td>
                      <td>{item.type}</td>
                      <td>{item.location}</td>
                      <td>{item.latitude}</td>
                      <td>{item.longtitude}</td>
                      <td>{item.unitRate}</td>
                      <td>{item.penaltyRate}</td>
                      <td>{item.keyString}</td>
                      <td>{item.dbServer}</td>
                      <td>{item.dbPassword}</td>
                      <td>{item.socketLink}</td>
                    </tr>
                  ))}
                </div>
              </tbody>
            </Table>
          )}
          <div className="btn-reset">
            <Button
              type="button"
              style={{ margin: '0 24px' }}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddMultiPlant;
