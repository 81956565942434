import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { ResponsivePie } from '@nivo/pie';
import { yearlyBar } from '../../services/DummyDatas';
import dummyLineData from '../../services/data.json';

function BarTooltip({ color }) {
  return (
    <p
      style={{
        color: 'white',
        padding: 4,
        borderRadius: 8,
        border: '1px solid rgb(128,128,128,0.5)',
        backgroundColor: color,
      }}
    >
      No data found
    </p>
  );
}

function lineTooltip({ point }) {
  const { serieColor } = point;
  return (
    <p
      style={{
        backgroundColor: serieColor,
        padding: '4px 8px 0px 8px',
        borderRadius: 5,
        lineHeight: 1.5,
        color: 'white',
      }}
    >
      No data found
    </p>
  );
}

function PieTooltip({ datum }) {
  return (
    <p
      style={{
        backgroundColor: datum.color,
        padding: 4,
        borderRadius: 8,
        color: 'white',
      }}
    >
      No data found
    </p>
  );
}

function DummyBar() {
  return (
    <ResponsiveBar
      data={yearlyBar}
      keys={['penalty']}
      indexBy="block"
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      valueScale={{ type: 'symlog' }}
      indexScale={{ type: 'band', round: true }}
      colors={['rgb(168, 168, 168)']}
      borderRadius={2}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableGridY={false}
      enableLabel={false}
      tooltip={BarTooltip}
      padding={0.6}
    />
  );
}

function DummyLine({ place }) {
  const set1 = [
    {
      id: place === 'MAE' ? 'MAE' : 'Penalty',
      color: 'rgb(168, 168, 168)',
      data: dummyLineData.map((i) => ({
        x: i['Time Block'],
        y: (place === 'MAE' && i.MAE) || (place === 'main' && i.Penalty),
      })),
    },
  ];
  const set2 = [
    {
      id: 'AG',
      color: 'rgb(152, 152, 152)',
      data: dummyLineData.map((i) => ({
        x: i['Time Block'],
        y: i.AG,
      })),
    },
    {
      id: 'SG',
      color: 'rgb(184, 184, 184)',
      data: dummyLineData.map((i) => ({
        x: i['Time Block'],
        y: i.SG,
      })),
    },
  ];
  const data = place !== 'AGSG' ? set1 : set2;
  const def = data.map(
    (i) =>
      // eslint-disable-next-line
      linearGradientDef(i.id, [
        { offset: 0, color: i.color, opacity: 0.5 },
        { offset: 100, color: i.color, opacity: 0 },
      ]),
    // eslint-disable-next-line
  );
  const fill = data.map((i) => ({ match: { id: i.id }, id: i.id }));
  return (
    <ResponsiveLine
      data={data}
      tooltip={lineTooltip}
      colors={data.map((i) => i.color)}
      curve="catmullRom"
      margin={{ top: 30, bottom: 20 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      axisBottom={null}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      enableArea
      areaOpacity={1}
      enableCrosshair={false}
      useMesh
      defs={def}
      fill={fill}
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: 10,
          translateY: -30,
          itemWidth: 100,
          itemHeight: 29,
          itemsSpacing: 2,
          symbolSize: 10,
          symbolShape: 'circle',
          itemDirection: 'left-to-right',
          itemTextColor: '#777',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
}

function DummyPie() {
  const data = [{ id: '', value: 1 }];
  const legend = [
    {
      data: [
        {
          id: '',
          label: 'No data available',
          color: 'rgb(168, 168, 168)',
          hidden: true,
        },
      ],
      translateY: 20,
      toggleSerie: false,
      anchor: 'bottom',
      direction: 'row',
      itemWidth: 100,
      itemHeight: 10,
      itemsSpacing: 10,
      symbolSize: 10,
      symbolShape: 'circle',
      itemDirection: 'left-to-right',
    },
  ];
  return (
    <ResponsivePie
      data={data}
      tooltip={PieTooltip}
      colors={['rgb(168, 168, 168)']}
      margin={{ top: 20, right: 20, bottom: 70, left: 20 }}
      sortByValue={false}
      innerRadius={0.75}
      activeOuterRadiusOffset={2}
      activeInnerRadiusOffset={2}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      legends={legend}
    />
  );
}

export { DummyBar, DummyLine, DummyPie };
