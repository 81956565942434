import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  deletePlant,
  deleteClient,
  deleteEmployee,
  deleteCompany,
} from '../Stores/Action';

function DeleteConfirmation(props) {
  // eslint-disable-next-line
  const {
    setIsDelete,
    onHide,
    plantId,
    clientId,
    employeeId,
    companyId,
    type,
  } = props;
  const dispatch = useDispatch();
  const [name, setName] = useState();
  useEffect(() => {
    if (plantId) {
      setName('Plant');
    } else if (clientId) {
      setName('Client');
    } else if (employeeId) {
      setName('Employee');
    } else if (companyId) {
      setName('Company');
    }
  });

  const handleDelete = (id) => {
    if (plantId) {
      dispatch(deletePlant({ plantId: id, type }));
    } else if (clientId) {
      dispatch(deleteClient({ clientId: id }));
    } else if (companyId) {
      dispatch(deleteCompany({ companyId: id }));
    } else if (employeeId) {
      dispatch(deleteEmployee({ employeeId: id }));
    }
  };
  const handleHide = () => {
    setIsDelete(false);
    onHide();
  };
  const handleSuccess = () => {
    setIsDelete(true);
    if (plantId) {
      handleDelete(plantId);
    } else if (clientId) {
      handleDelete(clientId);
    } else if (companyId) {
      handleDelete(companyId);
    } else if (employeeId) {
      handleDelete(employeeId);
    }
  };

  return (
    <Modal
      {...props}
      // style={{width:'500px'}}
      backdropClassName="delete-modal"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          fontWeight: 'bolder',
          color: 'GrayText',
        }}
        closeButton
      >
        <Modal.Title>
          Delete&nbsp;
          {name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div style={{ paddingBottom: '12px' }}>
            <h6
              style={{
                fontWeight: 'bolder',
                color: 'GrayText',
              }}
            >
              Are You Sure You Want to Delete?
            </h6>
          </div>
          <div style={{ float: 'right', padding: '10px' }}>
            <Button
              className="common-delete"
              variant="danger"
              onClick={() => handleSuccess()}
            >
              Yes
            </Button>
            <Button
              className="common-delete"
              variant="primary"
              onClick={() => handleHide()}
            >
              No
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteConfirmation;
