import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { Signin } from '../../../Stores/Action';
import input from '../../../components/Form/input';
import '../index.scss';

// log.enableAll();
function SignIn() {
  useEffect(() => {
    ReactGA.pageview('/login', ['LogIn Page'], 'login');
  }, []);
  const [errorShow, setErrorShow] = useState(false);
  const [userInfo, setuser] = useState({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState({});
  // const [isSubmit, setIsSubmit] = useState(false);
  const store = useSelector((state) => state.userDetails);
  // const successMessage = store.success;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  /* eslint-disable-next-line operator-linebreak */
  // const pass =
  // /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$!%*#?&@+?])[\w@$!%*#?&@+?]{7,16}$/;

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required!';
    } else if (!regex.test(values.email)) {
      // log.error('email format error');
      errors.email = 'This is not a valid email format!';
    }
    if (!values.password) {
      // log.error('password required error');
      errors.password = 'Password is required';
    }
    // else if (!pass.test(values.password)) {
    //   // errors.password = 'Password be like (A-z)+(0-9)+@';
    //   // if (!values.password.match(/[A-Za-z]/i)) {
    //   // errors.password = 'Include letters';
    //   if (!values.password.match(/[a-z]/g)) {
    //     errors.password = 'Include small letters';
    //   } else if (!values.password.match(/[A-Z]/g)) {
    //     errors.password = 'Include capital letters';
    //     // }
    //   } else if (!values.password.match(/[0-9]/g)) {
    //     errors.password = 'Include numbers';
    //   } else if (!values.password.match(/[!@#$%^&*+?]/g)) {
    //     errors.password = 'Include special characters';
    //   } else if (values.password.length < 7) {
    //     errors.password = 'Too small';
    //   } else if (values.password.length > 16) {
    //     errors.password = 'Too large';
    //   }
    // }
    return errors;
  };

  const handleChange = (e) => {
    // setIsSubmit(false);
    const { name, value } = e.target;
    setErrorShow(false);
    setuser({ ...userInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorShow(true);
    // setIsSubmit(false);
    if (Object.keys(formErrors).length === 0) {
      // log.info('dispatch sign in');
      dispatch(Signin(userInfo));
      // setIsSubmit(true);
    }
    // else setIsSubmit(false);
  };

  useEffect(() => {
    setFormErrors(validate(userInfo));
  }, [userInfo]);

  // useEffect(() => {
  //   if (successMessage && isSubmit) navigate('/dashboard');
  // }, [successMessage, isSubmit]);

  useEffect(() => {
    if (store?.user?.token) {
      // log.info('navigate to Dashboard');
      ReactGA.event({
        action: 'redirect to dashboard',
        label: 'login page',
      });
      navigate('/dashboard');
    }
  }, [store?.user]);
  return (
    <div className="login-page">
      {/* className="login-page" */}
      <Row className="image-half" />
      {/* className="image-half" */}
      <Row className="form-half">
        {/* className="flex form-half" */}
        <img className="logo-image" src="/dp_logo.svg" alt="" />
        <p className="flex small-device-error">This Device is too small...</p>
        <Form className="form" onSubmit={handleSubmit}>
          <h2 className="form-header">Login</h2>
          {input({
            handleChange,
            errorShow,
            value: userInfo.email,
            errors: formErrors.email,
            name: 'email',
            label: 'Email',
          })}
          {input({
            handleChange,
            errorShow,
            value: userInfo.password,
            errors: formErrors.password,
            name: 'password',
            label: 'Password',
            // link: {
            //   to: '/forgot',
            //   name: 'Forgot password?',
            // },
          })}
          <div className="buttons">
            <button
              style={{
                width: 'max-content',
                //   color: '#2563EB',
                //   backgroundColor: '#FAFBFC',
              }}
              type="button"
              className="edit -button"
              onClick={() => navigate('/forgot')}
            >
              Forgot password?
            </button>
            <button type="submit" className="submit -button">
              Login
            </button>
          </div>
        </Form>
      </Row>
    </div>
  );
}

export default SignIn;
