import React from "react";
import { FAQs, PriceRange } from "../components/pricingPage";

const PricingPage = () => {
  return (
    <div id="pricingPage" className="relative bg-codGray py-20 sm:py-10">
      <div className="pointer-events-none absolute inset-0 z-[0]">
        <img
          src="/assets/images/bg-abstract-pattern.svg"
          alt="hero-bg-pattern"
          className="w-full object-center"
        />
      </div>
      <div className="relative z-[1]">
        <PriceRange />
        <FAQs />
      </div>
    </div>
  );
};

export default PricingPage;
