import React from 'react';

export default function NoPagePermission() {
  return (
    <div className="accessDenied">
      <div className="accessDenied-gif" />
      <h4>YOU DO NOT HAVE PERMISSION TO ACCESS THIS PAGE!!!</h4>
    </div>
  );
}
