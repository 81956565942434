// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #f1f1f1;
  /* Light grey */
  border-top: 5px solid #1ba1d6;
  /* Blue */
  border-radius: 50%;
  animation: spinner 3s linear infinite; }

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px; }
`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/loadingSpinner.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAuB,EAAA;EAGzB;IACE,yBAAyB,EAAA,EAAA;;AAI7B;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,eAAA;EACA,6BAA6B;EAC7B,SAAA;EACA,kBAAkB;EAClB,qCAAqC,EAAA;;AAGvC;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAA","sourcesContent":["@keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.loading-spinner {\n  width: 40px;\n  height: 40px;\n  border: 5px solid #f1f1f1;\n  /* Light grey */\n  border-top: 5px solid #1ba1d6;\n  /* Blue */\n  border-radius: 50%;\n  animation: spinner 3s linear infinite;\n}\n\n.spinner-container {\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
