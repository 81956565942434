import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row } from 'react-bootstrap';

import { ForgotPassword as ForgotPasswordAPI } from '../../../Stores/Action';
import input from '../../../components/Form/input';

function ForgotPassword() {
  const [errorShow, setErrorShow] = useState(false);
  const [userInfo, setuser] = useState({
    email: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [method, setMethod] = useState('email');

  const store = useSelector((state) => state.userDetails);
  const successMessage = store.success;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})/;
  const phoneregex = /^([0-9]{10})+$/;
  const validateEmail = (values) => {
    const errors = {};
    if (!values.email) {
      // log.error('email required');
      errors.email = 'Email is required!';
    } else if (!regex.test(values.email)) {
      errors.email = 'This is not a valid email format!';
    }
    return errors;
  };
  const validatePhone = (values) => {
    const errors = {};
    if (!values.phone) {
      errors.phone = 'Phone Number is required!';
    } else if (!phoneregex.test(values.phone)) {
      errors.phone = 'phone number is not valid!';
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorShow(false);
    setIsSubmit(false);
    setuser({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      // log.info('dispatch Forgot Password');
      dispatch(ForgotPasswordAPI(userInfo));
      setIsSubmit(true);
    } else {
      setIsSubmit(false);
      // log.error('form error');
    }
  };

  useEffect(() => {
    if (method === 'email') setFormErrors(validateEmail(userInfo));
    else setFormErrors(validatePhone(userInfo));
  }, [userInfo]);

  useEffect(() => {
    if (successMessage && isSubmit) {
      // log.info('Redirect to OTP');
      navigate('/otp', {
        state: {
          userInfo,
        },
      });
    }
  }, [successMessage]);

  return (
    <div className="forgot-password-page">
      <Row className="image-half" />
      <Row className="form-half">
        <img className="logo-image" src="/dp_logo.svg" alt="" />
        <p className="flex small-device-error">This Device is too small...</p>
        <Form className="form" onSubmit={handleSubmit}>
          <h2 className="form-header">Forgot Password</h2>
          {method === 'email' && // eslint operator-linebreak: ["error", "after"]
            input({
              handleChange,
              errorShow,
              value: userInfo.email,
              errors: formErrors.email,
              name: 'email',
              label: 'Email',
              link: {
                to: '/login',
                name: 'Login',
              },
            })}
          {method ===
            'phone' /* eslint operator-linebreak: ["error", "after"] */ &&
            input({
              handleChange,
              errorShow,
              value: userInfo.phone,
              errors: formErrors.phone,
              name: 'phone',
              label: 'Phone Number',
              link: {
                to: '/login',
                name: 'Login',
              },
            })}
          <Button
            style={{ marginTop: '10px' }}
            type="submit"
            className="submit -button"
          >
            Submit
          </Button>
          <Button
            type="button"
            className="under-line"
            onClick={() => {
              setuser({
                email: '',
              });
              if (method === 'email') {
                setMethod('phone');
              } else setMethod('email');
            }}
          >
            {`With ${method === 'email' ? 'Phone Number' : 'Email'}`}
          </Button>
        </Form>
      </Row>
    </div>
  );
}

export default ForgotPassword;
