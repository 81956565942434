import moment from 'moment';
import datajson from './data.json';

const Day = new Date();
const agsg = [
  {
    id: 'AG',
    color: 'rgb(0, 0, 255)',
    data: datajson.map((i) => ({ x: i['Block no'], y: i.AG })),
  },
  {
    id: 'SG',
    color: 'rgb(0, 255, 0)',
    data: datajson.map((i) => ({ x: i['Block no'], y: i?.SG })),
  },
];

const yearlyBar = [
  {
    penalty: 58100,
    block: 3,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 87800,
    block: 4,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 17800,
    block: 7,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 87800,
    block: 5,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 9800,
    block: 6,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
];

const MonthsBar = [
  {
    penalty: 57800,
    block: 1,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 27800,
    block: 2,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 67800,
    block: 3,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 87800,
    block: 4,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 87800,
    block: 5,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 67800,
    block: 6,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 17800,
    block: 7,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 77800,
    block: 8,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 97800,
    block: 9,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 90890,
    block: 10,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 31890,
    block: 11,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 62890,
    block: 12,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 23890,
    block: 13,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 84890,
    block: 14,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 95890,
    block: 15,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 36890,
    block: 16,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 77890,
    block: 17,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 98890,
    block: 18,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 19890,
    block: 19,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 50890,
    block: 20,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 81890,
    block: 21,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 92890,
    block: 22,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 53890,
    block: 23,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 34890,
    block: 24,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 55890,
    block: 25,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 46890,
    block: 26,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 17890,
    block: 27,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 88890,
    block: 28,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 99890,
    block: 29,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
  {
    penalty: 60890,
    block: 30,
    penaltyColor: 'hsl(103, 70%, 50%)',
  },
];

// datajson.reduce(
//   (prev, now) => {
//     if (prev.b === 4) {
//       prev.c.push({
//         Penalty: prev.a.toFixed(),
//         Block: prev.c.length + 1,
//         PenaltyColor: 'hsl(103, 70%, 50%)',
//       });
//       return {
//         a: now.Penalty,
//         b: 1,
//         c: prev.c,
//       };
//     }
//     return {
//       a: prev.a + now.Penalty,
//       b: prev.b + 1,
//       c: prev.c,
//     };
//   },
//   { a: 0, b: 1, c: [] },
// ).c;

const rolePermissions = [
  {
    permission: 'Dashboard',
    actions: ['View'],
  },
  {
    permission: 'Profile',
    actions: ['View', 'Edit'],
  },
  {
    permission: 'Plant',
    actions: ['View', 'Add', 'Edit', 'Delete'],
  },
  {
    permission: 'Company',
    actions: ['View', 'Add', 'Edit', 'Delete'],
  },
  {
    permission: 'Client',
    actions: ['View', 'Add', 'Edit', 'Delete'],
  },
  {
    permission: 'Employee',
    actions: ['View', 'Add', 'Edit', 'Delete'],
  },
];

const userPermissions = [
  {
    permission: 'Dashboard',
    actions: ['View'],
  },
  {
    permission: 'Profile',
    actions: ['View', 'Edit'],
  },
  {
    permission: 'Plant',
    actions: ['View', 'Add'],
  },
  {
    permission: 'Company',
    actions: ['View', 'Add'],
  },
];

const smallPie = [
  {
    id: '1st week',
    label: '1st week',
    value: 8743665,
    color: 'hsl(238, 70%, 50%)',
  },
  {
    id: '2nd week',
    label: '2nd week',
    value: 4873684,
    color: 'hsl(238, 70%, 50%)',
  },
  {
    id: '3rd week',
    label: '3rd week',
    value: 7368467,
    color: 'hsl(238, 70%, 50%)',
  },
  {
    id: '4th week',
    label: '4th week',
    value: 2563684,
    color: 'hsl(238, 70%, 50%)',
  },
];

const pie = [
  {
    id: '00 TO 06',
    label: '00 TO 06',
    value: datajson
      .filter((i) => i['Block no'] < 24)
      .reduce((a, b) => a + b.Penalty, 0),
    color: 'hsl(238, 70%, 50%)',
  },
  {
    id: '06 TO 12',
    label: '06 TO 12',
    value: datajson
      .filter((i) => i['Block no'] > 24 && i['Block no'] < 48)
      .reduce((a, b) => a + b.Penalty, 0),
    color: 'hsl(26, 70%, 50%)',
  },
  {
    id: '12 TO 18',
    label: '12 TO 18',
    value: datajson
      .filter((i) => i['Block no'] > 48 && i['Block no'] < 72)
      .reduce((a, b) => a + b.Penalty, 0),
    color: 'hsl(170, 70%, 50%)',
  },
  {
    id: '18 TO 24',
    label: '18 TO 24',
    value: datajson
      .filter((i) => i['Block no'] > 72 && i['Block no'] < 96)
      .reduce((a, b) => a + b.Penalty, 0),
    color: 'hsl(238, 70%, 50%)',
  },
];

const mae = [
  {
    id: 'MAE',
    color: 'rgb(0, 0, 255)',
    data: datajson.map((i) => ({ x: i['Block no'], y: i.MAE })),
  },
];

const min15 = () => {
  const at = datajson.find((i) => {
    const startTime = i['Time Block'].slice(0, 5); // 00:00
    const endTime = i['Time Block'].slice(8); // 00:15

    // create date with current time
    const startDate = new Date(Day.getTime());
    // replace hours from start time (00)
    startDate.setHours(startTime.split(':')[0]);
    // replace minites from start time (00)
    startDate.setMinutes(startTime.split(':')[1]);

    // create date with current time
    const endDate = new Date(Day.getTime());
    // replace hours from end time (00)
    endDate.setHours(endTime.split(':')[0]);
    // replace minites from end time (15)
    endDate.setMinutes(endTime.split(':')[1]);
    // check if current time is in bitween start and end
    return startDate < Day && endDate > Day;
  });
  return { SG: at?.SG, Block: at?.['Time Block'] };
};
const min30 = () => {
  const find = datajson.find((i) => {
    const startTime = i['Time Block'].slice(0, 5);
    const endTime = i['Time Block'].slice(8, 13);

    const startDate = new Date(Day.getTime());
    startDate.setHours(startTime.split(':')[0]);
    startDate.setMinutes(startTime.split(':')[1]);

    const endDate = new Date(Day.getTime());
    endDate.setHours(endTime.split(':')[0]);
    endDate.setMinutes(endTime.split(':')[1]);
    return startDate < Day && endDate > Day;
  });
  const at = datajson.indexOf(find);
  // eslint-disable-next-line
  const SG =
    // eslint-disable-next-line
    datajson[at]?.SG &&
    // eslint-disable-next-line
    datajson[at + 1]?.SG &&
    datajson[at].SG + datajson[at + 1].SG;
  const start = datajson[at]?.['Time Block'].slice(0, 5);
  const end = datajson[at + 1]?.['Time Block'].slice(6, 13);
  const block = start + end;
  // const block = '00:00 - 00:00';
  return { SG, block };
};
const hour = () => {
  const find = datajson.find((i) => {
    const startTime = i['Time Block'].slice(0, 5);
    const endTime = i['Time Block'].slice(8, 13);

    const startDate = new Date(Day.getTime());
    startDate.setHours(startTime.split(':')[0]);
    startDate.setMinutes(startTime.split(':')[1]);

    const endDate = new Date(Day.getTime());
    endDate.setHours(endTime.split(':')[0]);
    endDate.setMinutes(endTime.split(':')[1]);
    return startDate < Day && endDate > Day;
  });
  const at = datajson.indexOf(find);
  const o = datajson[at]?.SG;
  const t = datajson[at + 1]?.SG;
  const h = datajson[at + 2]?.SG;
  const f = datajson[at + 3]?.SG;
  // eslint-disable-next-line
  const SG =
    // eslint-disable-next-line
    datajson[at]?.SG &&
    // eslint-disable-next-line
    datajson[at + 1]?.SG &&
    // eslint-disable-next-line
    datajson[at + 2]?.SG &&
    // eslint-disable-next-line
    datajson[at + 3]?.SG &&
    o + t + h + f;
  const start = datajson[at]?.['Time Block'].slice(0, 5);
  const end = datajson[at + 3]?.['Time Block'].slice(6, 13);
  const block = start + end;
  // const block = '00:00 - 00:00';
  return { SG, block };
};

const day = {
  SG: datajson.reduce((p, c) => p + c.SG, 0),
  block: moment(Day).format('D MMMM Y'),
};
const afterWeekDate = new Date();
afterWeekDate.setDate(Day.getDate() + 7);
const week = {
  SG: day.SG * 6.92,
  block: `${Day.getDate()} ${moment(Day).format(
    'MMMM',
  )} - ${afterWeekDate.getDate()} ${moment(afterWeekDate).format('MMMM')}`,
};

const month = {
  SG: day.SG * 29.72,
  block: moment(Day).format('MMMM Y'),
};

const chart1 = datajson.map((i) => {
  const temp = { SG: i?.SG, AG: i.AG, block: i['Time Block'] };
  return temp;
});

export {
  min15,
  min30,
  hour,
  day,
  week,
  month,
  chart1,
  agsg,
  yearlyBar,
  smallPie,
  MonthsBar,
  pie,
  mae,
  rolePermissions,
  userPermissions,
};
