// import { useState } from 'react';
import Form from 'react-bootstrap/Form';

function Check(props) {
  const { disable, actions, handleCheck, permission } = props;
  // const [action, setAction] = useState(actions);
  // const handleCheck = (i) => {
  //   if (action?.includes(i)) setAction(action.filter((k) => k !== i));
  //   else setAction([...action, i]);
  // };
  return (
    <Form>
      <div key="inline-checkbox" className="mb-3">
        <Form.Check
          inline
          checked={actions?.includes('add')}
          disabled={disable}
          label="Add"
          name="add"
          type="checkbox"
          id="inline-checkbox-2"
          onChange={() => handleCheck(permission, 'add')}
        />
        <Form.Check
          inline
          checked={actions?.includes('edit')}
          disabled={disable}
          label="Edit"
          name="edit"
          type="checkbox"
          id="inline-checkbox-3"
          onChange={() => handleCheck(permission, 'edit')}
        />
        <Form.Check
          inline
          checked={actions?.includes('delete')}
          disabled={disable}
          name="edit"
          label="delete"
          type="checkbox"
          id="inline-checkbox-4"
          onChange={() => handleCheck(permission, 'delete')}
        />
        <Form.Check
          inline
          checked={actions?.includes('view')}
          disabled={disable}
          label="View"
          name="view"
          type="checkbox"
          id="inline-checkbox-1"
          onChange={() => handleCheck(permission, 'view')}
        />
      </div>
    </Form>
  );
}

export default Check;
