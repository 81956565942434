import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal, Button, Form } from 'react-bootstrap';
import input from '../../components/Form/input';
import { ResetPassword as ResetPasswordAPI } from '../../Stores/Action';
import './index.scss';

function ResetPassword(props) {
  const [formErrors, setformErrors] = useState({});
  const [errorShow, setErrorShow] = useState(false);
  const [initialValues, setInitialValues] = useState({
    newPassword: '',
  });
  const dispatch = useDispatch();

  const validator = (values) => {
    const errors = {};
    const pass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;

    if (!values.newPassword) {
      errors.newPassword = 'New Password is required';
    } else if (!pass.test(values.newPassword)) {
      errors.newPassword = `Password should be
        like (A-z)+(0-9)+@ and length should atleast 8 long`;
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is not match';
    }
    return errors;
  };
  const handleHide = async () => {
    const { onHide } = props;
    setInitialValues('');
    setErrorShow(false);
    onHide();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      const { onHide } = props;
      dispatch(ResetPasswordAPI(initialValues));
      setInitialValues('');
      setErrorShow(false);
      onHide();
    }
  };

  useEffect(() => {
    setformErrors(validator(initialValues));
  }, [initialValues]);

  const handleChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;

    setErrorShow(false);
    setInitialValues((prevalue) => ({
      ...prevalue,
      [name]: value,
    }));
  };

  return (
    <Modal
      {...props}
      size="sm"
      backdropClassName="back-drop-plant"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className="form-header">Reset Password</h4>
        <Form>
          {input({
            handleChange,
            errorShow,
            value: initialValues.newPassword,
            errors: formErrors.newPassword,
            name: 'newPassword',
            label: 'New Password',
          })}
          {input({
            handleChange,
            errorShow,
            value: initialValues.confirmPassword,
            errors: formErrors.confirmPassword,
            name: 'confirmPassword',
            label: 'Confirm Password',
          })}
        </Form>
        <div className="btn-reset">
          <Button
            type="button"
            style={{ margin: '0 24px' }}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
          <Button onClick={handleHide}>Close</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ResetPassword;
