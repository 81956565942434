import React, { useState } from "react";

const FAQs = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="py-20 sm:py-10">
      <div className="container">
        <div className="mx-auto mb-14 flex max-w-[70%] flex-col items-center justify-center gap-4 sm:mb-8 sm:max-w-none">
          <h3 className="font-epilogue text-10xl font-semibold text-textColor xl:text-7xl sm:text-6xl">
            FAQs
          </h3>
        </div>
        <div className="flex flex-col gap-7">
          {[...Array(6)].map((_, index) => (
            <button
              onClick={() => setActiveTab(index)}
              className="w-full border-b border-b-textColor pb-8"
            >
              <div className="flex w-full items-center justify-between">
                <p className="text-2xl font-normal text-textColor">
                  What is the duration of the free trial?
                </p>
                <span
                  className={`flex items-center justify-center duration-500 ${activeTab === index && "-rotate-180"}`}
                >
                  <img
                    className="size-4"
                    src="/assets/images/arrow-down.svg"
                    alt="arrow"
                  />
                </span>
              </div>
              <div
                className={`${activeTab === index ? "mt-4 max-h-[1000px]" : "mt-0 max-h-0"} overflow-hidden duration-500`}
              >
                <p className="text-left text-base font-normal text-textColor">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad
                  quae perferendis eligendi quas adipisci architecto dignissimos
                  quod numquam quidem cum, culpa magnam assumenda cumque
                  reiciendis, voluptatem esse dicta animi facere! Veritatis,
                  adipisci saepe ex itaque a officia in tempore. Totam labore,
                  sed ipsa eius aut consequuntur id alias voluptate laboriosam
                  deserunt soluta neque, distinctio perferendis exercitationem
                  fugiat. Reiciendis, error assumenda. vel.
                </p>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
