import React, { useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { clientList } from '../../Stores/Action';
import AddClient from './addClient';
import AddMultiClient from './addMultiClients';
// import store from '../../Stores/Store';
import upload from '../../components/assets/upload.svg';
import ClientDetail from './clientDetail';
import './index.scss';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import next from '../../components/assets/next.svg';
import previous from '../../components/assets/previous.svg';

const itemsPerPage = 10;

export default function Client() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(clientList());
  // }, []);
  const pers = useSelector((state) => state.permissions?.data);
  let i = [];
  pers?.map((p) => {
    // let i = '';
    if (p.name === 'CLIENT') {
      i = p.actions;
      return i;
    }
    return null;
  });
  const pData = useSelector((state) => state.client?.client);
  const clientdata = pData?.data?.clientList;
  const totalLength = pData?.data?.count;
  const userAssociate = useSelector(
    (state) => state.userDetails?.user?.userName,
  );
  const [single, setSingle] = useState(true);
  const [value, setValue] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [modal, setModal] = useState('view');
  const [clientId, setClientId] = useState('');
  const [clientShow, setclientShow] = React.useState(false);
  const [addClientShow, setAddClientShow] = useState(false);
  const [file, setFile] = useState('');

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [disable, isDisable] = React.useState(true);
  useEffect(() => {
    const totalCount = Math.ceil(totalLength / itemsPerPage);
    setPageCount(totalCount);
  }, [totalLength]);

  useEffect(() => {
    // log.info(`On page ${currentPage}`);
    dispatch(clientList({ pageSize: itemsPerPage, pageCount: currentPage }));
  }, [currentPage]);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  // const handleNav = (cId) => {
  //   navigate('/', { state: { id: cId } });
  // };

  const add = single ? (
    <AddClient
      show={addClientShow}
      setSingle={setSingle}
      onHide={() => {
        setAddClientShow(false);
        setFile('');
        setClientId('');
      }}
    />
  ) : (
    <AddMultiClient
      show={addClientShow}
      setSingle={setSingle}
      setFile={file}
      onHide={() => {
        setAddClientShow(false);
        setFile('');
        setValue('');
      }}
    />
  );

  return (
    <div className="client-list">
      <div className="client-header">
        <h5
          style={{
            fontWeight: 'bold',
          }}
        >
          Client Details
        </h5>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            type="button"
            className="add-btn"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Add Client"
            onClick={() => {
              // log.info('clicked on add client');
              setSingle(true);
              setClientId('');
              setFile('');
              setAddClientShow(true);
            }}
          >
            +
          </button>
          <label
            htmlFor="client-upload"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Upload client"
            className="multi-upload"
          >
            <img src={upload} alt="upload" />
            <input
              id="client-upload"
              type="file"
              value={value}
              disabled
              accept=".csv,.xlsx,.xls"
              onChange={(e) => {
                // log.info('clicked on multiple add client');
                setSingle(false);
                setClientId('');
                setFile(e.target);
                setValue(e.target.value);
                setAddClientShow(true);
              }}
            />
          </label>
        </div>
      </div>
      <Table className="client-page" hover>
        <thead>
          <tr>
            <th className="th1" aria-label="pi" />
            <th className="th2">Email</th>
            <th className="th3">Name</th>
            <th className="th4">Company</th>
            <th className="th4">Associative</th>
            <th className="th5">Action</th>
          </tr>
        </thead>
        <tbody>
          {clientdata /* eslint operator-linebreak: ["error", "after"] */ &&
            clientdata.map((item) => (
              <tr>
                <td>
                  {item.profileUrl ? (
                    <img src={item.profileUrl} alt="" />
                  ) : (
                    <img className="image-profile" alt="" />
                  )}
                </td>
                <td>{item.email}</td>
                <td>{item.userName}</td>
                <td>
                  &nbsp;&nbsp;
                  {item.numberofcompany}
                </td>
                <td>{userAssociate}</td>
                <td>
                  <div className="actions">
                    {i.map((action) => {
                      if (action === 'view') {
                        return (
                          <span
                            aria-hidden
                            className="eyeIcon"
                            onClick={() => {
                              setModal('view');
                              isDisable(true);
                              setclientShow(true);
                              setClientId(item.uuid);
                            }}
                          />
                        );
                      }
                      if (action === 'edit') {
                        return (
                          <span
                            aria-hidden
                            className="editIcon"
                            onClick={() => {
                              setModal('view');
                              isDisable(false);
                              setclientShow(true);
                              setClientId(item.uuid);
                            }}
                          />
                        );
                      }
                      if (action === 'delete') {
                        return (
                          <span
                            aria-hidden
                            className="deleteIcon"
                            onClick={() => {
                              setModal('delete');
                              setIsDelete(false);
                              setclientShow(true);
                              setClientId(item.uuid);
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {pageCount > 0 && (
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeLinkClassName="activetab"
          activeClassName="active"
          previousLabel={<img src={previous} alt="upload" />}
          nextLabel={<img src={next} alt="upload" />}
          breakLabel="..."
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
        />
      )}
      <div>
        {clientShow && clientId && modal === 'view' ? (
          <ClientDetail
            show={clientShow}
            clientId={clientId}
            isDisable={disable}
            onHide={() => {
              setclientShow(false);
              setClientId('');
            }}
          />
        ) : null}
      </div>
      <div>
        {clientShow && clientId && !isDelete && modal === 'delete' ? (
          <DeleteConfirmation
            show={clientShow}
            clientId={clientId}
            setIsDelete={setIsDelete}
            onHide={() => {
              setclientShow(false);
            }}
          />
        ) : null}
      </div>
      <div>{addClientShow && clientId === '' ? add : null}</div>
    </div>
  );
}
