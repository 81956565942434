import React from 'react'
import { Link } from 'react-router-dom'
import RightArrow from '../icons/RightArrow'

const SuccessStoryCard = ({ stat, statLine, clientLogoUrl, clientName, clientTitle, clientMessage, clientAvatarUrl }) => {
    return (
        <div className='h-fit rounded-radius-4 bg-darkBlueGradient py-5'>
            <div className="relative mb-5 flex items-center gap-3 px-7 after:absolute after:left-0 after:top-0 after:h-full after:w-1 after:bg-primary xl:px-5">
                <p className="text-11xl font-extrabold text-textColor xl:text-6xl">{stat}</p>
                <p className='text-4xl font-medium leading-snug text-textColor xl:text-2xl'>{statLine}</p>
            </div>
            <div className="px-7 xl:px-5">
                <div className="border-t border-t-mineShaft pt-3">
                    <div className="mb-5 max-h-14 xl:max-h-12">
                        <img src={clientLogoUrl} alt="client-logo" />
                    </div>
                    <div className="mb-7 flex flex-col gap-4">
                        <p className='text-xl font-normal leading-[1.5] text-textColor xl:text-base xl:leading-[1.5]'>"{clientMessage}"</p>

                        <Link to={'/'} className='btn-secondary flex w-fit items-center gap-2 rounded-radius-3 px-5 py-2 text-xs font-medium'>
                            <span >See case study</span>
                            <span className='flex items-center justify-center'>
                                <RightArrow sizeClass={'sm:size-4'} />
                            </span>
                        </Link>

                    </div>
                    <div className="flex items-center justify-end gap-5">
                        <div className="flex flex-col gap-1">
                            <p className='text-right text-2xl font-semibold text-textColor xl:text-base'>
                                {clientName}
                            </p>
                            <p className='text-end text-base font-normal text-textColor opacity-50 xl:text-xs'>{clientTitle}</p>
                        </div>
                        <div className="size-14 shrink-0 overflow-hidden rounded-full xl:size-12">
                            <img className='h-full w-full object-cover' src={clientAvatarUrl} alt="client-avatar" />
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}

export default SuccessStoryCard
