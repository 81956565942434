import React from 'react';

function Cases() {
  return (
    <section className="cases content-wrapper">
      <div id="hero">
        <div className="heading">
          <div className="fill" />
          <h4>Energy forecasting consulting and application cases</h4>
          <p>
            Penalty Optimizer generates forecasts in the energy sector for the
            short, medium and long term horizons. Over the course of 23 years
            these forecasts of
            <b> prices, demand, photovoltaic or wind production </b>
            have been used as products or as services, constituting the
            fundamental input of a set of cases of applications, solutions or
            consultancies applied to various energy sectors such as utilities ,
            traders, renewable developers, large consumers, traders, investment
            funds and banks.
          </p>
        </div>
        <div className="discript">
          <div className="row">
            <div className="col carder">
              <div className="fill" />
              <h2>Market forecasting for PPA and synergies</h2>
              <p>
                One of the most important aspects when defining a PPA is the
                value of the energy that is bought or sold. AleaSoft offers a
                forecast report with a 30-year horizon. In this report the price
                is broken down on an hourly basis, and the rest of the variables
                that determine the market price are broken down with annual
                granularity. This long-term forecast report is the basis for
                consulting on the entire process of risk identification,
                definition of agreements and termsheets, negotiation and closing
                of PPAs.
              </p>
            </div>
            <div className="col carder">
              <div className="fill" />
              <h2>Market forecasting for PPA and synergies</h2>
              <p>
                One of the most important aspects when defining a PPA is the
                value of the energy that is bought or sold. AleaSoft offers a
                forecast report with a 30-year horizon. In this report the price
                is broken down on an hourly basis, and the rest of the variables
                that determine the market price are broken down with annual
                granularity. This long-term forecast report is the basis for
                consulting on the entire process of risk identification,
                definition of agreements and termsheets, negotiation and closing
                of PPAs.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col carder">
              <div className="fill" />
              <h2>Market forecasting for PPA and synergies</h2>
              <p>
                One of the most important aspects when defining a PPA is the
                value of the energy that is bought or sold. AleaSoft offers a
                forecast report with a 30-year horizon. In this report the price
                is broken down on an hourly basis, and the rest of the variables
                that determine the market price are broken down with annual
                granularity. This long-term forecast report is the basis for
                consulting on the entire process of risk identification,
                definition of agreements and termsheets, negotiation and closing
                of PPAs.
              </p>
            </div>
            <div className="col carder">
              <div className="fill" />
              <h2>Market forecasting for PPA and synergies</h2>
              <p>
                One of the most important aspects when defining a PPA is the
                value of the energy that is bought or sold. AleaSoft offers a
                forecast report with a 30-year horizon. In this report the price
                is broken down on an hourly basis, and the rest of the variables
                that determine the market price are broken down with annual
                granularity. This long-term forecast report is the basis for
                consulting on the entire process of risk identification,
                definition of agreements and termsheets, negotiation and closing
                of PPAs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cases;
