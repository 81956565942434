import React, { useEffect, useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

import { employeeList } from '../../Stores/Action';
import AddEmployee from './addEmployee';
import AddMultiEmployee from './addMultiEmployee';
import upload from '../../components/assets/upload.svg';
import EmployeeDetail from './employeeDetail';
import './index.scss';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import next from '../../components/assets/next.svg';
import previous from '../../components/assets/previous.svg';

const itemsPerPage = 10;
export default function Employee() {
  const dispatch = useDispatch();
  const pers = useSelector((state) => state.permissions?.data);
  let i = [];
  pers?.map((p) => {
    if (p.name === 'EMPLOYEE') {
      i = p.actions;
      return i;
    }
    return null;
  });
  const pData = useSelector((state) => state.employeeList?.employee);
  const employeedata = pData?.data?.employeeList;
  const totalLength = pData?.data?.count;
  const [employeeId, setEmployeeId] = useState('');
  const [single, setSingle] = useState(true);
  const [file, setFile] = useState('');
  const [value, setValue] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [modal, setModal] = useState('view');
  const [employeeShow, setemployeeShow] = React.useState(false);
  const [addEmployeeShow, setAddEmployeeShow] = useState(false);
  const [disable, isDisable] = React.useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const ref = useRef();
  useEffect(() => {
    const totalCount = Math.ceil(totalLength / itemsPerPage);
    setPageCount(totalCount);
  }, [totalLength]);
  useEffect(() => {
    dispatch(employeeList({ pageSize: itemsPerPage, pageCount: currentPage }));
  }, [currentPage]);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    ref.current = data.selected;
  };

  const add = single ? (
    <AddEmployee
      show={addEmployeeShow}
      setSingle={setSingle}
      onHide={() => {
        setAddEmployeeShow(false);
        setEmployeeId('');
      }}
    />
  ) : (
    <AddMultiEmployee
      show={addEmployeeShow}
      setSingle={setSingle}
      setFile={file}
      onHide={() => {
        setAddEmployeeShow(false);
        setValue('');
        setFile('');
      }}
    />
  );

  return (
    <div className="employee-list">
      <div className="employee-header">
        <h5
          style={{
            fontWeight: 'bold',
          }}
        >
          Employee Details
        </h5>
        {i.map((action) => {
          if (action === 'add') {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                  type="button"
                  className="add-btn"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Add Employee"
                  onClick={() => {
                    // log.info('Clicked On add Employee');
                    setEmployeeId('');
                    setSingle(true);
                    setFile('');
                    setAddEmployeeShow(true);
                  }}
                >
                  +
                </button>
                <label
                  htmlFor="plant-add"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Upload Employee"
                  className="multi-upload"
                >
                  <img src={upload} alt="upload" />
                  <input
                    id="plant-add"
                    type="file"
                    value={value}
                    disabled
                    accept=".csv,.xlsx,.xls"
                    onChange={(e) => {
                      // log.info('Clicked On multiple add Employee');
                      setSingle(false);
                      setFile(e.target);
                      setValue(e.target.value);
                      setEmployeeId('');
                      setAddEmployeeShow(true);
                    }}
                  />
                </label>
              </div>
            );
          }
          return null;
        })}
      </div>
      <Table className="employee-page" hover>
        <thead>
          <tr>
            <th aria-label="pi" />
            <th>Email</th>
            <th>Name</th>
            <th>User Name</th>
            <th>Plant</th>
            <th>Company</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {employeedata /* eslint operator-linebreak: ["error", "after"] */ &&
            employeedata?.map((item) => (
              <tr>
                <td>
                  {item.profileUrl ? (
                    <img src={item.profileUrl} alt="" />
                  ) : (
                    <img className="image-profile" alt="" />
                  )}
                </td>
                <td>{item.email}</td>
                <td>{item.name}</td>
                <td>{item.userName}</td>
                <td>{item.numberofplant}</td>
                <td>{item.numberofcompany}</td>
                <td>
                  <div className="actions">
                    {i.map((action) => {
                      if (action === 'view') {
                        return (
                          <span
                            aria-hidden
                            className="eyeIcon"
                            onClick={() => {
                              setModal('view');
                              setemployeeShow(true);
                              isDisable(true);
                              setEmployeeId(item.uuid);
                            }}
                          />
                        );
                      }
                      if (action === 'edit') {
                        return (
                          <span
                            aria-hidden
                            className="editIcon"
                            onClick={() => {
                              setModal('view');
                              setemployeeShow(true);
                              isDisable(false);
                              setEmployeeId(item.uuid);
                            }}
                          />
                        );
                      }
                      if (action === 'delete') {
                        return (
                          <span
                            aria-hidden
                            className="deleteIcon"
                            onClick={() => {
                              setEmployeeId(item.uuid);
                              setIsDelete(false);
                              setemployeeShow(true);
                              setModal('delete');
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
        <div>
          {employeeShow && employeeId !== '' && modal === 'view' ? (
            <EmployeeDetail
              show={employeeShow}
              isDisable={disable}
              employeeId={employeeId}
              onHide={() => {
                setemployeeShow(false);
                setEmployeeId('');
              }}
            />
          ) : null}
        </div>
        <div>
          {employeeShow && employeeId && !isDelete && modal === 'delete' ? (
            <DeleteConfirmation
              show={employeeShow}
              employeeId={employeeId}
              setIsDelete={setIsDelete}
              onHide={() => {
                setemployeeShow(false);
              }}
            />
          ) : null}
        </div>
        <div>{addEmployeeShow && employeeId === '' ? add : null}</div>
      </Table>
      {pageCount > 0 && (
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeLinkClassName="activetab"
          activeClassName="active"
          previousLabel={<img src={previous} alt="upload" />}
          nextLabel={<img src={next} alt="upload" />}
          breakLabel="..."
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
        />
      )}
    </div>
  );
}
