import React from 'react';

import back1 from '../../components/assets/oneBack.png';
import back2 from '../../components/assets/twoBack.jpg';
import back3 from '../../components/assets/threeBack.jpg';

function Services() {
  return (
    <section className="services content-wrapper">
      <div id="hero">
        <div className="heading">
          <div className="fill" />
          <h4>Services</h4>
          <p>
            Our products and services include
            <b> highly reliable forecasts </b>
            for prices, demand and renewable energy
            <b> adapted to the needs of the energy market </b>
            for the short, medium and long-term horizons.
          </p>
        </div>
        <div className="discript">
          <div className="carded price">
            <div className="left" style={{ backgroundImage: `url(${back1})` }}>
              <div>
                <h4>Energy price forecasting</h4>
                <p>
                  Penalty Optimizer developed an energy forecasting methodology
                  that is unique, guaranteeing the highest degree of efficiency
                  and accuracy
                </p>
              </div>
            </div>
            <div className="right">
              <div className="box">
                Penalty Optimizer developed an energy forecasting methodology
                that is unique, guaranteeing the highest degree of efficiency
                and accuracy
              </div>
            </div>
          </div>
          <div className="carded demand">
            <div className="left" style={{ backgroundImage: `url(${back2})` }}>
              <div>
                <h4>Energy demand forecasting</h4>
                <p>
                  Penalty Optimizer developed an energy forecasting methodology
                  that is unique, guaranteeing the highest degree of efficiency
                  and accuracy
                </p>
              </div>
            </div>
            <div className="right">
              <div className="box">
                Penalty Optimizer developed an energy forecasting methodology
                that is unique, guaranteeing the highest degree of efficiency
                and accuracy
              </div>
            </div>
          </div>
          <div className="carded energy">
            <div className="left" style={{ backgroundImage: `url(${back3})` }}>
              <div>
                <h4> Renewable energy forecasting</h4>
                <p>
                  Penalty Optimizer offers services for renewable energy
                  forecasts. Using a unique methodology for energy forecasting,
                  combining statistical and artificial intelligence techniques,
                  the highest degree of accuracy is guaranteed.
                </p>
              </div>
            </div>
            <div className="right">
              <div className="box">
                Penalty Optimizer offers services for renewable energy
                forecasts. Using a unique methodology for energy forecasting,
                combining statistical and artificial intelligence techniques,
                the highest degree of accuracy is guaranteed.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
