import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import { ScrollContext } from '../../context/SmoothScroll';

const navItems = [
    {
        text: 'Platform', hasDropdown: true,
        subLinks: [
            { text: 'Sublink 1', href: '/' },
            { text: 'Sublink 2', href: '/' },
            { text: 'Sublink 3', href: '/' },
        ]
    },
    {
        text: 'Solutions', hasDropdown: true,
        subLinks: [
            { text: 'Sublink 4', href: '/' },
            { text: 'Sublink 5', href: '/' },
            { text: 'Sublink 6', href: '/' },
            { text: 'Sublink 7', href: '/' },
            { text: 'Sublink 8', href: '/' },
            { text: 'Sublink 9', href: '/' },
        ]
    },
    { text: 'Customers', href: '/', hasDropdown: false },
    { text: 'Pricing', href: '/pricing', hasDropdown: false },
    {
        text: 'Resources', hasDropdown: true,
        subLinks: [
            { text: 'Sublink 10', href: '/' },
            { text: 'Sublink 11', href: '/' },
            { text: 'Sublink 12', href: '/' },
            { text: 'Sublink 13', href: '/' },
            { text: 'Sublink 14', href: '/' },
            { text: 'Sublink 15', href: '/' },
        ]
    },
];

const Header = () => {
    const { scrollDirection } = useContext(ScrollContext);
    const [isLinkHover, setIsLinkHover] = useState(false);
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null); // Track which dropdown is open
    useEffect(() => {
        if (openMobileMenu) {
            document.querySelector('html').style.overflowY = 'hidden';
        } else {
            document.querySelector('html').style.overflowY = 'auto';
        }
    }, [openMobileMenu])

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(prevIndex => (prevIndex === index ? null : index)); // Toggle dropdown
    };

    return (
        <>
            <header className={` 
            ${scrollDirection == '1' ? "-translate-y-full" : "translate-y-0"}
             fixed left-0 top-0 w-full px-[30px] z-50 xl:py-6 sm:py-4 duration-500 
             ${isLinkHover ? 'bg-white' : 'bg-codGray'}`} >
                <div className={`border-b  ${isLinkHover ? 'border-b-black' : 'border-b-transparent'} duration-500 xl:border-none`}>
                    <div className="flex items-center justify-between">
                        <div className="relative z-10 max-w-36">
                            <Link to={'/'} className='block'>
                                {/* <img className={`duration-500 ${isLinkHover || openMobileMenu ? 'brightness-0 grayscale-100' : 'invert-0'}`} src="/assets/images/logo.svg" alt="logo" /> */}
                                <h3 className={`block font-epilogue text-7xl xxl:text-6xl font-semibold  duration-500 ${isLinkHover || openMobileMenu ? 'text-black' : 'text-whiteIce'}`}>Indite</h3>
                            </Link>
                        </div>
                        <div className="mx-auto xl:hidden">
                            <Navigation navItems={navItems} isLinkHover={isLinkHover} setIsLinkHover={setIsLinkHover} />
                        </div>
                        <div className="flex items-center gap-3 xl:hidden">
                            <Link to={'/'} className='btn-secondary rounded-radius-2 px-5 py-2 text-xs mix-blend-difference duration-500'>Try for free</Link>
                            <Link to={'/'} className='btn-primary rounded-radius-2 px-5 py-2 text-xs'>Sign In</Link>
                        </div>
                        <div className='relative z-10 hidden xl:block'>
                            {
                                openMobileMenu ? (
                                    <button className='hidden cursor-pointer xl:block' onClick={() => setOpenMobileMenu(false)}>
                                        <img className={`duration-500 ${openMobileMenu ? 'brightness-0 grayscale-100' : 'invert-0'}`} src="/assets/images/close-icon.svg" alt="Close" />
                                    </button>
                                ) : (
                                    <button className='hidden cursor-pointer xl:block' onClick={() => setOpenMobileMenu(true)}>
                                        <img src="/assets/images/menu-icon.svg" alt="Menu" />
                                    </button>
                                )
                            }
                        </div>


                        {/* mobile nav */}
                        <div className={`hidden xl:block fixed h-screen  z-[2] inset-0 bg-white duration-500 ${openMobileMenu ? 'translate-x-0' : 'translate-x-full'}`}>
                            <div className="mb-6 mt-[77px] px-[30px]">
                                <div className="flex items-center gap-3">
                                    <Link to={'/'} className='btn-secondary w-full rounded-radius-2 px-5 py-2 text-xs mix-blend-difference duration-500'>Try for free</Link>
                                    <Link to={'/'} className='btn-primary w-full rounded-radius-2 px-5 py-2 text-xs'>Sign In</Link>
                                </div>
                            </div>
                            <div className="no-scrollbar flex flex-col gap-6 overflow-auto border-t border-t-black p-[30px]">
                                {
                                    navItems.map((item, index) => (
                                        item.hasDropdown ? (
                                            <div key={index} className={`flex flex-col overflow-hidden ease duration-500 ${openDropdownIndex === index ? 'gap-4' : 'gap-0'}`}>
                                                <button
                                                    className="flex w-fit cursor-pointer items-center gap-2 text-xl font-normal text-gray-700"
                                                    onClick={() => toggleDropdown(index)} // Toggle dropdown on click
                                                >
                                                    <span className='block'>{item.text}</span>
                                                    <span className={`flex items-center justify-center duration-500 ${openDropdownIndex === index ? 'rotate-180' : 'rotate-0'}`}>
                                                        <img className='grayscale-100 size-3 brightness-0' src="/assets/images/arrow-down.svg" alt="arrow" />
                                                    </span>
                                                </button>
                                                <ul className={`origin-top duration-500 ease-in-out transition-max-height overflow-hidden ${openDropdownIndex === index ? 'max-h-screen' : 'max-h-0'}`}>
                                                    {item.subLinks.map((subLink, subIndex) => (
                                                        <li key={subIndex}>
                                                            <Link to={subLink.href} className='flex rounded-radius-2 bg-white px-6 py-3 duration-500 hover:bg-whiteIce'>
                                                                <span className='text-base font-semibold text-black'>
                                                                    {subLink.text}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <Link key={index} to={item.href} className="w-fit cursor-pointer text-xl font-normal text-gray-700">
                                                {item.text}
                                            </Link>
                                        )
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="h-[72px] w-full bg-codGray sm:h-[70px]"></div>
        </>
    )
}

export default Header;
