import React from 'react';

export default function input(props) {
  const {
    handleChange,
    errorShow,
    value,
    errors,
    name,
    label,
    link,
    disabled,
    maxLength,
  } = props;
  return (
    <div className="flex form-input-controller">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={`${name}-box`}
        id={`${name}-label`}
        className={`${name}-label`}
      >
        {label}
      </label>
      <div>
        <input
          id={`${name}-box`}
          type={name}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={`Enter ${label}`}
          disabled={disabled}
          maxLength={maxLength}
        />
        <p className="text-danger">{errorShow ? errors : null}</p>
        {link && <a href={link.to}>{link.name}</a>}
      </div>
    </div>
  );
}
