import React from 'react'
import SuccessStoryCard from './SuccessStoryCard'
import { PRODUCT_NAME } from '../../constants'

const SuccessStories = () => {
    return (
        <div className='relative py-28 xl:py-20 sm:py-16'>

            <div className="container relative z-[1]">
                <div className="mb-14 flex items-center justify-center xl:mb-10 sm:mb-7">
                    <h4 className='mx-auto max-w-[55%] text-center font-epilogue text-7xl font-semibold !leading-[1.5] text-textColor xl:max-w-[80%] sm:max-w-none sm:text-6xl'>
                        What success looks like with {PRODUCT_NAME}
                    </h4>
                </div>
                <div className="grid grid-cols-3 gap-12 sm:grid-cols-1 sm:gap-7">
                    <SuccessStoryCard stat={'60K'} statLine={'leads generated every month'} clientLogoUrl={'/assets/images/client-logo-1.svg'} clientName={'Cheytan Kumbhar'} clientTitle={'Group CEO'} clientMessage={'Reflecting back to when we started, we have received over 300K+ leads from all our websites, is an outstanding achievement. We\'ve never seen an ROI of this level in any other martech platform.'} clientAvatarUrl={'/assets/images/client-1.jpg'} />

                    <SuccessStoryCard stat={'175K'} statLine={'feedbacks collected on WhatsApp'} clientLogoUrl={'/assets/images/client-logo-2.svg'} clientName={'Khaled Ahmed'} clientTitle={'IT Manager'} clientMessage={`Thanks to ${PRODUCT_NAME}, we effortlessly automated feedback collection from over 100k patients via Whatsapp chatbots. Their seamless integration made the process smooth, enhancing patient engagement significantly.`} clientAvatarUrl={'/assets/images/client-2.jpg'} />

                    <SuccessStoryCard stat={'80%'} statLine={'reduction in delays'} clientLogoUrl={'/assets/images/client-logo-3.svg'} clientName={'Shashank Shah'} clientTitle={'Asst. Manager - Business Transformation'} clientMessage={"I love how helpful their sales teams were throughout the process. The sales team understood our challenge custom-fit solution to us."} clientAvatarUrl={'/assets/images/client-3.jpg'} />
                </div>

            </div>

        </div>
    )
}

export default SuccessStories
