import React from 'react'

const ListIcon = () => {
    return (
        <>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_77_4637)">
                    <path d="M15.4982 4.07418L14.9052 4.87518L7.60318 14.7382C7.42818 14.9742 7.15818 15.1212 6.86418 15.1392C6.57118 15.1582 6.28518 15.0462 6.08118 14.8342L2.11918 10.6932L1.43018 9.97318L2.87018 8.59518L3.55918 9.31518L6.70418 12.6012L13.3032 3.68918L13.8962 2.88818L15.4982 4.07418Z" fill="#EAE9FC" />
                </g>
                <defs>
                    <clipPath id="clip0_77_4637">
                        <rect width="15" height="13" fill="white" transform="translate(0.5 2.16504)" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}

export default ListIcon
