import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, Welcome } from '../Stores/Action';

export default function ServerNotFound() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const welcome = useSelector((state) => state.welcome.message);

  useEffect(() => {
    dispatch(Welcome());
  }, []);
  useEffect(() => {
    if (welcome) navigate('/dashboard');
  }, [welcome]);
  return (
    <div>
      <div className="serverNotFound">
        <h1>
          Server is not responding
          <br />
          Please Try later
        </h1>
        <h3>
          <button
            type="button"
            className="logoutOption"
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Go to Dashboard
          </button>
          <button
            type="button"
            className="logoutOption"
            onClick={() => {
              dispatch(logout());
              navigate('/login');
            }}
          >
            Go to Login
          </button>
          <button
            type="button"
            className="logoutOption"
            onClick={() => {
              dispatch(logout());
              navigate('/');
            }}
          >
            Go to Home
          </button>
        </h3>
        {/* <div className="serverNotFound-gif" /> */}
      </div>
    </div>
  );
}
