import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import { LineTooltip, SGAGTooltip, MAETooltip } from './tooltip';

function PenaltyGraph({ data, tip }) {
  const yes = true;
  const tooltip = () => {
    switch (tip) {
      case 'MAE':
        return MAETooltip;
      case 'AGSG':
        return SGAGTooltip;
      default:
        return LineTooltip;
    }
  };
  const def = data?.map(
    (i) =>
      // eslint-disable-next-line
      linearGradientDef(i.id, [
        { offset: 0, color: i.color, opacity: 0.5 },
        { offset: 100, color: i.color, opacity: 0 },
      ]),
    // eslint-disable-next-line
  );
  const fill = data?.map((i) => ({ match: { id: i.id }, id: i.id }));
  return (
    <ResponsiveLine
      data={data}
      // debugMesh
      tooltip={tooltip()}
      colors={data?.map((i) => i.color)}
      curve="catmullRom"
      margin={{ top: 30, bottom: 20 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      axisBottom={null}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      enableArea={yes}
      // areaBaselineValue={Math.min(...data[0].data.map((i) => i.y))}
      areaOpacity={1}
      enableCrosshair={false}
      useMesh={yes}
      defs={def}
      fill={fill}
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: 10,
          translateY: -30,
          itemWidth: 100,
          itemHeight: 29,
          itemsSpacing: 2,
          symbolSize: 10,
          symbolShape: 'circle',
          itemDirection: 'left-to-right',
          itemTextColor: '#777',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
}

export default PenaltyGraph;
