import Banner from "./Banner";
import PlatformInfoSection from "./PlatformInfoSection";
import BotShowcase from "./BotShowcase";
import SuccessStories from "./SuccessStories";
import SuccessStoryCard from "./SuccessStoryCard";
import CtaSection from "./CtaSection";
import Deliver from "./Deliver";
export {
    Banner,
    PlatformInfoSection,
    BotShowcase,
    SuccessStories,
    SuccessStoryCard,
    CtaSection as CTA,
    Deliver
}