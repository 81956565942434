import { useNavigate } from 'react-router-dom';
import Linkedin from '../../components/assets/linkedin.png';
import Skype from '../../components/assets/skype.png';
import Whatsapp from '../../components/assets/whatsapp.svg';
import Mail from '../../components/assets/mail.png';
import Map from '../../components/assets/map.png';
import Internet from '../../components/assets/internet.png';
import Call from '../../components/assets/call.png';

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="bottom">
        <div className="left">
          <div className="row" style={{ fontSize: 12 }}>
            <div className="col-img">
              <img alt="" src={Map} />
            </div>
            <div className="col-text">
              <a
                className="text-white"
                href="https://goo.gl/maps/Pm8KSEZkMm9MuGWo7"
              >
                <b>Ahmedabad</b>
                <p>
                  1107, Shivalik shilp 2, Opp. ITC Narmada hotel, Judges
                  bungalow road, Ahmedabad - 380015
                </p>
              </a>
            </div>
          </div>
          <iframe
            title="map"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Shivalik Shilp 2&t=&z=15&ie=UTF8&iwloc=&output=embed"
          />
        </div>
        <div className="right">
          <h1>Let&apos;s talk</h1>
          <div className="social-icons">
            <div className="top">
              <a href="https://www.dataprophets.in">
                <img alt="" src={Internet} />
                <span>Data Prophets Website</span>
              </a>
              <a href="mailto:analyst@dataprophets.in">
                <img alt="" src={Mail} />
                <span>analyst@dataprophets.in</span>
              </a>
              <a href="tel:+919664797701">
                <img alt="" src={Call} />
                <span>9664797701</span>
              </a>
            </div>
            <div className="bottom">
              <a href="https://www.linkedin.com/company/data-prophets/">
                <img alt="" src={Linkedin} />
                <span>Data Prophets</span>
              </a>
              <a href="skype:live:akshaydodhiwala23?chat">
                <img alt="" src={Skype} />
                <span>akshaydodhiwala23</span>
              </a>
              <a href="http://wa.me/919664797701">
                <img alt="" src={Whatsapp} />
                <span>wa.me/919664797701</span>
              </a>
            </div>
            {/* <a href="http://wa.me/919664797701">
              <img alt="" src={Whatsapp} />
              wa.me/919664797701
            </a> */}
          </div>
        </div>
      </div>
      <div className="lines">
        <a href="terms" className="btn terms">
          Terms and Conditions
        </a>
        <a href="policy" className="btn policy">
          Privacy Policy
        </a>
        <a
          href="#main"
          className="btn policy"
          onClick={() => navigate('contact')}
        >
          Contact Us
        </a>
      </div>
    </div>
  );
}

export default Footer;
