import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../services/configHeader';
import reduxStore from '../Store';

import {
  ROLE_PERMISSION_REQUEST,
  ROLE_PERMISSION_SUCCESS,
  ROLE_PERMISSION_FAIL,
  ROLE_PERMISSION_EDIT_REQUEST,
  ROLE_PERMISSION_EDIT_SUCCESS,
  ROLE_PERMISSION_EDIT_FAIL,
} from '../Types';

const permissionAPI = `${process.env.REACT_APP_API_URL}rolePermission`;

const rolePermissionView = () => async (dispatch) => {
  try {
    dispatch({ type: ROLE_PERMISSION_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${permissionAPI}/viewRolePermission`,
      config,
    );
    await dispatch({
      type: ROLE_PERMISSION_SUCCESS,
      payload: data,
    });
    // toast.success(data.message);
  } catch (error) {
    dispatch({
      type: ROLE_PERMISSION_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const rolePermissionEdit = (permissionData) => async (dispatch) => {
  try {
    dispatch({ type: ROLE_PERMISSION_EDIT_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.post(
      `${permissionAPI}/editRolePermission`,
      permissionData,
      config,
    );
    await dispatch({
      type: ROLE_PERMISSION_EDIT_SUCCESS,
      payload: data,
    });
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: ROLE_PERMISSION_EDIT_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

export { rolePermissionView, rolePermissionEdit };
