import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='bg-footerGradient py-8 sm:py-8'>

            <div className="container">
                <div className='mb-8'>
                    {/* <img src="/assets/images/logo.svg" alt="logo.svg" /> */}
                    <h3 className='block font-epilogue text-7xl font-semibold text-textColor sm:text-6xl'>Indite</h3>
                </div>
                <div className="border-t border-t-mineShaft pt-14 sm:pt-10">
                    <div className="grid grid-cols-4 pb-16 sm:grid-cols-1 sm:gap-10 sm:pb-4">
                        <div className="flex flex-col gap-10 sm:gap-5">
                            <p className='text-xl font-semibold text-textColor'>PLATFORM</p>
                            <div className="flex flex-col gap-3">
                                <Link to={'/'} className='text-base font-normal text-textColor'>Bot Builder</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>AI Studio</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>AI Studio</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Analytics</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Integrations</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Outbound Bots</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>WhatsApp Chatbot</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Professional Services</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>All Features</Link>
                            </div>
                        </div>
                        <div className="flex flex-col gap-10 sm:gap-5">
                            <p className='text-xl font-semibold text-textColor'>SOLUTIONS</p>


                            <div className="flex flex-col gap-10 sm:gap-3">
                                <p className='text-xl font-semibold text-textColor sm:w-fit sm:border-b sm:border-b-customGray sm:text-base'>BY USE CASE</p>
                                <div className="flex flex-col gap-3">
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Lead Generation</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Customer Support</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Appointment Booking</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>View all</Link>
                                </div>
                            </div>
                            <div className="flex flex-col gap-10 sm:gap-3">
                                <p className='text-xl font-semibold text-textColor sm:w-fit sm:border-b sm:border-b-customGray sm:text-base'>BY INDUSTRY</p>
                                <div className="flex flex-col gap-3">
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Manufacturing</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Real Estate</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Healthcare</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Insurance</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Travel</Link>
                                </div>
                            </div>
                            <div className="flex flex-col gap-10 sm:gap-3">
                                <p className='text-xl font-semibold text-textColor sm:w-fit sm:border-b sm:border-b-customGray sm:text-base'>BY COMPANY SIZE</p>
                                <div className="flex flex-col gap-3">
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Small Business</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Mid-Market</Link>
                                    <Link to={'/'} className='text-base font-normal text-textColor'>Enterprise</Link>
                                </div>
                            </div>


                        </div>

                        <div className="flex flex-col gap-10 sm:gap-5">
                            <p className='text-xl font-semibold text-textColor'>COMPANY</p>
                            <div className="flex flex-col gap-3">
                                <Link to={'/'} className='text-base font-normal text-textColor'>About us</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Customers</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Professional Services</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Partner</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Whitelabel</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Support</Link>
                                <Link to={'/contact'} className='text-base font-normal text-textColor'>Contact us</Link>
                            </div>
                        </div>


                        <div className="flex flex-col gap-10 sm:gap-5">
                            <p className='text-xl font-semibold text-textColor'>RESOURCES</p>
                            <div className="flex flex-col gap-3">
                                <Link to={'/'} className='text-base font-normal text-textColor'>Blogs</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Customer Stories</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Comparisons</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Help Articles</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Videos</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Tickets</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Changelog</Link>
                                <Link to={'/'} className='text-base font-normal text-textColor'>Roadmap</Link>

                            </div>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-center border-b border-b-mineShaft py-8">
                        <div className="flex items-center gap-10">
                            <Link to={'/'} className='block size-6'><img src="/assets/images/facebook-icon.svg" alt="facebook" /></Link>
                            <Link to={'/'} className='block size-6'><img src="/assets/images/instagram-icon.svg" alt="instagram" /></Link>
                            <Link to={'/'} className='block size-6'><img src="/assets/images/twitter-icon.svg" alt="twitter" /></Link>
                            <Link to={'/'} className='block size-6'><img src="/assets/images/youtube-icon.svg" alt="youtube" /></Link>
                            <Link to={'/'} className='block size-6'><img src="/assets/images/linkedin-icon.svg" alt="linkedin" /></Link>
                        </div>
                    </div>
                    <div className="flex items-center justify-between pt-8 sm:flex-col sm:items-start sm:gap-5">
                        <p className="text-xs text-[#E1F5FA36] sm:order-2">© 2024 Indite. All rights reserved.</p>
                        <div className="flex items-center gap-4 sm:order-1 sm:flex-col sm:items-start">
                            <Link to={'/'} className='block text-base text-textColor sm:text-xs'>Security</Link>
                            <span className="block text-textColor sm:hidden">|</span>
                            <Link to={'/'} className='block text-base text-textColor sm:text-xs'>Terms & Conditions</Link>
                            <span className="block text-textColor sm:hidden">|</span>
                            <Link to={'/'} className='block text-base text-textColor sm:text-xs'>Privacy Policy</Link>
                            <span className="block text-textColor sm:hidden">|</span>
                            <Link to={'/'} className='block text-base text-textColor sm:text-xs'>Status</Link>
                        </div>
                        <div className="flex items-center gap-3 sm:order-3">
                            <p className="text-xs text-[#E1F5FA36]">Made in India</p>
                            <span className='block'>
                                <img src="/assets/images/india-flag.svg" alt="india-flag" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
