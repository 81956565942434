import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../services/configHeader';
import reduxStore from '../Store';
import {
  COMPANY_LIST_VIEW_REQUEST,
  COMPANY_LIST_VIEW_SUCCESS,
  COMPANY_LIST_VIEW_FAIL,
  COMPANY_VIEW_REQUEST,
  COMPANY_VIEW_SUCCESS,
  COMPANY_VIEW_FAIL,
  COMPANY_ADD_REQUEST,
  COMPANY_ADD_SUCCESS,
  COMPANY_ADD_FAIL,
  EDIT_COMPANY_REQUEST,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAIL,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  MULTI_COMPANY_ADD_REQUEST,
  MULTI_COMPANY_ADD_SUCCESS,
  MULTI_COMPANY_ADD_FAIL,
} from '../Types';

const companyAPI = `${process.env.REACT_APP_API_URL}clientCompany`;
const companyList = (pageData) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_LIST_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const page = pageData
      ? `?pageSize=${pageData.pageSize}&pageCount=${pageData.pageCount}`
      : '';
    const continent = `&continent=${reduxStore.getState().continent}`;
    const { data } = await axios.get(
      `${companyAPI}/clientCompanyList${page}${continent}`,
      config,
    );

    await dispatch({ type: COMPANY_LIST_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPANY_LIST_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    if (error.response && error.response.data.error.message) {
      toast.error(error.response.data.error.message);
    }
    // toast.error(
    //   error.response && error.response.data.error.message
    //     ? error.response.data.error.message
    //     : error.message,
    // );
  }
};
const companyView = (companyID) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${companyAPI}/clientCompanyDetail?companyId=${companyID}`,
      config,
    );
    await dispatch({ type: COMPANY_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMPANY_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const addCompany = (companyData) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_ADD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.post(
      `${companyAPI}/addClientCompany`,
      companyData,
      config,
    );
    await dispatch({ type: COMPANY_ADD_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(companyList());
  } catch (error) {
    dispatch(companyList());

    dispatch({
      type: COMPANY_ADD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const deleteCompany = (companyData) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COMPANY_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.delete(`${companyAPI}/deleteClientCompany`, {
      ...config,
      data: companyData,
    });
    await dispatch({ type: DELETE_COMPANY_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(companyList());
  } catch (error) {
    dispatch({
      type: DELETE_COMPANY_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const companyEdit = (companyData) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_COMPANY_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${companyAPI}/editClientCompany`,
      companyData,
      config,
    );
    await dispatch({ type: EDIT_COMPANY_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(companyList());
  } catch (error) {
    dispatch({
      type: EDIT_COMPANY_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const addMultiCompany = (companyData) => async (dispatch) => {
  try {
    dispatch({ type: MULTI_COMPANY_ADD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.post(
      `${companyAPI}/addMultiClientCompany`,
      companyData,
      config,
    );
    dispatch(companyList());

    await dispatch({ type: MULTI_COMPANY_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch(companyList());
    dispatch({
      type: MULTI_COMPANY_ADD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

export {
  companyList,
  companyView,
  addCompany,
  deleteCompany,
  companyEdit,
  addMultiCompany,
};
