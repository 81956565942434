"use client";
import React, { useState } from "react";
import ListIcon from "../icons/ListIcon";
import { Link } from "react-router-dom";

const PriceRange = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="mb-100 relative z-[1] sm:mb-12">
      <div className="container">
        <div className="mx-auto flex flex-col items-center gap-4">
          <h1 className="mb-8 text-center font-epilogue text-14xl font-semibold text-textColor xxl:text-11xl xl:mb-6 sm:mb-4 sm:text-6xl">
            Our pricing
          </h1>
          <p className="mx-auto text-center text-4xl font-normal text-textColor xxl:text-xl xxl:!leading-[1.5] sm:text-base">
            All your chatbot needs bundled with every plan.
          </p>
        </div>
        <div className="mx-auto my-16 flex w-full items-center justify-center border-b border-b-silverChalice sm:w-full">
          <button
            onClick={() => setActiveTab(1)}
            className={`${activeTab === 1 ? "after:scale-x-100" : "after:scale-x-0"} relative w-full px-9 pb-5 text-2xl font-semibold text-textColor duration-500 after:absolute after:bottom-[-1px] after:left-0 after:z-[2] after:h-[3px] after:w-full after:origin-right after:bg-primary after:duration-500 md:after:bottom-[-4px] sm:flex-col sm:pb-4 sm:text-xs`}
          >
            MONTHLY
          </button>
          <button
            onClick={() => setActiveTab(2)}
            className={`${activeTab === 2 ? "after:scale-x-100" : "after:scale-x-0"} relative flex w-full items-center justify-center gap-4 px-9 pb-5 text-2xl font-semibold text-textColor duration-500 after:absolute after:bottom-[-1px] after:left-0 after:z-[2] after:h-[3px] after:w-full after:origin-left after:bg-primary after:duration-500 md:after:bottom-[-4px] sm:flex-col sm:pb-4 sm:text-xs`}
          >
            YEARLY{" "}
            <span className="block rounded-full bg-primary px-2 py-1 text-xs text-textColor">
              Save 20%
            </span>
          </button>
        </div>
        {activeTab === 1 ? (
          <div className="grid grid-cols-4 gap-6 xl:grid-cols-1">
            <div className="relative flex flex-col justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6 xl:gap-10">
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor xl:text-base">
                    FREE
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor xl:text-6xl">
                      $0
                    </h3>
                    <p className="text-xl font-normal text-textColor">/month</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Free includes:
                  </p>
                  <ul className="flex list-inside flex-col gap-3 text-sm text-textColor xl:flex-row xl:flex-wrap">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">100 chats</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">20 AI answers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">No-code chatbot builder</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Help articles</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block h-fit w-fit shrink-0">
                Get Started
              </Link>
            </div>

            <div className="relative flex flex-col justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6 xl:gap-10">
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor xl:text-base">
                    Starter
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor xl:text-6xl">
                      $99
                    </h3>
                    <p className="text-xl font-normal text-textColor">/month</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Everything in Free, plus:
                  </p>
                  <ul className="flex list-inside flex-col gap-3 text-sm text-textColor xl:flex-row xl:flex-wrap">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">5,000 chats</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">1,000 AI answers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">WhatsApp API</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">All integrations</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Outbound bots</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Ticketing support</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block h-fit w-fit shrink-0">
                Get Started
              </Link>
            </div>

            <div className="relative flex flex-col justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6 xl:gap-10">
              <div className="absolute right-0 bg-primary px-3 py-1">
                <p className="text-center text-xs font-bold uppercase text-textColor">
                  MOST POPULAR
                </p>
              </div>
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor xl:text-base">
                    Premium
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor xl:text-6xl">
                      $299
                    </h3>
                    <p className="text-xl font-normal text-textColor">/month</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Everything in Starter, plus:
                  </p>
                  <ul className="flex list-inside flex-col gap-3 text-sm text-textColor xl:flex-row xl:flex-wrap">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">10,000 chats</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">2,500 AI answers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Live Chat</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">HTTP requests</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Dynamic data</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Custom answers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">AI co-pilot</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Hide branding</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Function calling</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block h-fit w-fit shrink-0">
                Get Started
              </Link>
            </div>

            <div className="relative flex flex-col justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6 xl:gap-10">
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor xl:text-base">
                    Ultimate
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor xl:text-6xl">
                      $899
                    </h3>
                    <p className="text-xl font-normal text-textColor">/month</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Everything in Premium, plus:
                  </p>
                  <ul className="flex list-inside flex-col gap-3 text-sm text-textColor xl:flex-row xl:flex-wrap">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">20,000 chats</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block"></span>
                      5,000 AI answers
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Professional services</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Custom integrations</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block"></span>
                      InfoSec & legal review
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Onboarding & training</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Account manager</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Priority support</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Account optimization</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block h-fit w-fit shrink-0">
                Get Started
              </Link>
            </div>

            <div className="relative col-span-4 flex justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6 xl:col-span-1 xl:flex-col xl:gap-10">
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor xl:text-base">
                    ENTERPRISE
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor xl:text-6xl">
                      Custom
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Everything you need in an enterprise chatbot
                  </p>
                  <ul className="flex list-inside flex-wrap gap-3 text-sm text-textColor">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Audit logs</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block"></span>
                      SSO integration
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Workspaces</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Custom dashboard</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block"></span>
                      On-prem installation
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">HIPAA compliance</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block h-fit w-fit shrink-0">
                Get Started
              </Link>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-4 gap-6">
            <div className="relative flex flex-col justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6">
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor">
                    FREE
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor">
                      $0
                    </h3>
                    <p className="text-xl font-normal text-textColor">/year</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Free includes:
                  </p>
                  <ul className="flex list-inside flex-col gap-3 text-sm text-textColor">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">100 chats</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">20 AI answers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">No-code chatbot builder</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Help articles</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block">
                Get Started
              </Link>
            </div>

            <div className="relative flex flex-col justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6">
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor">
                    Starter
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor">
                      $99
                    </h3>
                    <p className="text-xl font-normal text-textColor">/year</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Everything in Free, plus:
                  </p>
                  <ul className="flex list-inside flex-col gap-3 text-sm text-textColor">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">5,000 chats</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">1,000 AI answers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">WhatsApp API</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">All integrations</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Outbound bots</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Ticketing support</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block">
                Get Started
              </Link>
            </div>

            <div className="relative flex flex-col justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6">
              <div className="absolute right-0 bg-primary px-3 py-1">
                <p className="text-center text-xs font-bold uppercase text-textColor">
                  MOST POPULAR
                </p>
              </div>
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor">
                    Premium
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor">
                      $299
                    </h3>
                    <p className="text-xl font-normal text-textColor">/year</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Everything in Starter, plus:
                  </p>
                  <ul className="flex list-inside flex-col gap-3 text-sm text-textColor">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">10,000 chats</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">2,500 AI answers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Live Chat</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">HTTP requests</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Dynamic data</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Custom answers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">AI co-pilot</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Hide branding</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Function calling</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block">
                Get Started
              </Link>
            </div>

            <div className="relative flex flex-col justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6">
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor">
                    Ultimate
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor">
                      $899
                    </h3>
                    <p className="text-xl font-normal text-textColor">/year</p>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Everything in Premium, plus:
                  </p>
                  <ul className="flex list-inside flex-col gap-3 text-sm text-textColor">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">20,000 chats</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block"></span>
                      5,000 AI answers
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Professional services</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Custom integrations</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block"></span>
                      InfoSec & legal review
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Onboarding & training</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Account manager</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Priority support</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Account optimization</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block">
                Get Started
              </Link>
            </div>

            <div className="relative col-span-4 flex justify-between gap-20 overflow-hidden rounded-radius-4 bg-darkBlueGradient p-6">
              <div>
                <div className="mb-6 flex flex-col gap-2 border-b border-b-mineShaft pb-[18px]">
                  <p className="text-xl font-semibold uppercase text-textColor">
                    ENTERPRISE
                  </p>
                  <div className="flex items-center gap-1">
                    <h3 className="text-8xl font-semibold text-textColor">
                      Custom
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <p className="text-xl font-semibold text-textColor">
                    Everything you need in an enterprise chatbot
                  </p>
                  <ul className="flex list-inside flex-wrap gap-3 text-sm text-textColor">
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Audit logs</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block"></span>
                      SSO integration
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Workspaces</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">Custom dashboard</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block"></span>
                      On-prem installation
                    </li>
                    <li className="flex items-center gap-2">
                      <ListIcon />
                      <span className="block">HIPAA compliance</span>
                    </li>
                  </ul>
                </div>
              </div>
              <Link to={"/"} className="btn-primary block h-fit w-fit shrink-0">
                Get Started
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceRange;
