import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';

import { companyList } from '../../Stores/Action';
import AddCompany from './addCompany';
import AddMultiCompany from './addMultiCompanys';
import upload from '../../components/assets/upload.svg';
import CompanyDetail from './companyDetail';
import './index.scss';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import next from '../../components/assets/next.svg';
import previous from '../../components/assets/previous.svg';

const itemsPerPage = 10;

export default function Company() {
  const dispatch = useDispatch();

  const pers = useSelector((state) => state.permissions?.data);
  let i = [];
  pers?.map((p) => {
    if (p.name === 'CLIENT_COMPANY') {
      i = p.actions;
      return i;
    }
    return null;
  });
  const cData = useSelector((state) => state.company?.company);
  const continent = useSelector((state) => state.continent);
  const companydata = cData?.data?.clientCompanyList;
  const totalLength = cData?.data?.count;
  const [single, setSingle] = useState(true);
  const [value, setValue] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [modal, setModal] = useState('view');
  const [companyId, setCompanyId] = useState('');
  const [companyShow, setcompanyShow] = React.useState(false);
  const [addCompanyShow, setAddCompanyShow] = useState(false);
  const [file, setFile] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [disable, isDisable] = React.useState(true);
  useEffect(() => {
    const totalCount = Math.ceil(totalLength / itemsPerPage);
    setPageCount(totalCount);
  }, [totalLength]);
  useEffect(() => {
    dispatch(companyList({ pageSize: itemsPerPage, pageCount: currentPage }));
  }, [currentPage, continent]);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  const add = single ? (
    <AddCompany
      show={addCompanyShow}
      setSingle={setSingle}
      onHide={() => {
        setAddCompanyShow(false);
        setFile('');
        setCompanyId('');
      }}
    />
  ) : (
    <AddMultiCompany
      show={addCompanyShow}
      setSingle={setSingle}
      setFile={file}
      onHide={() => {
        setAddCompanyShow(false);
        setFile('');
        setValue('');
      }}
    />
  );

  return (
    <div className="company-list">
      <div className="company-header">
        <h5
          style={{
            fontWeight: 'bold',
          }}
        >
          Company Details
        </h5>
        {i?.map((action) => {
          if (action === 'add') {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                  type="button"
                  className="add-btn"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Add Company"
                  onClick={() => {
                    // log.info('clicked on add Company');
                    setSingle(true);
                    setCompanyId('');
                    setFile('');
                    setAddCompanyShow(true);
                  }}
                >
                  +
                </button>
                <label
                  htmlFor="company-upload"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Upload company"
                  className="multi-upload"
                >
                  <img src={upload} alt="upload" />
                  <input
                    id="company-upload"
                    type="file"
                    disabled
                    accept=".csv,.xlsx,.xls"
                    value={value}
                    onChange={(e) => {
                      // log.info('clicked on multiple add Company');
                      setSingle(false);
                      setCompanyId('');
                      setFile(e.target);
                      setValue(e.target.value);
                      setAddCompanyShow(true);
                    }}
                  />
                </label>
              </div>
            );
          }
          return null;
        })}
      </div>
      <Table className="company-page" hover>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Owner Name</th>
            <th>Employee Name</th>
            <th>Plant</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companydata /* eslint operator-linebreak: ["error", "after"] */ &&
            companydata?.map((item) => (
              <tr>
                <td>{item.companyname}</td>
                <td>{item.ownername}</td>
                <td>{item.employeeName}</td>
                <td>{item.numberofplant}</td>
                <td>{item.address}</td>
                {/* <td>{item.isActive ? 'Active' : 'Close'}</td> */}
                <td>
                  <div className="actions">
                    {i?.map((action) => {
                      if (action === 'view') {
                        return (
                          <span
                            aria-hidden
                            className="eyeIcon"
                            onClick={() => {
                              setModal('view');
                              isDisable(true);
                              setcompanyShow(true);
                              setCompanyId(item.uuid);
                            }}
                          />
                        );
                      }
                      if (action === 'edit') {
                        return (
                          <span
                            aria-hidden
                            className="editIcon"
                            onClick={() => {
                              setModal('view');
                              isDisable(false);
                              setcompanyShow(true);
                              setCompanyId(item.uuid);
                            }}
                          />
                        );
                      }
                      if (action === 'delete') {
                        return (
                          <span
                            aria-hidden
                            className="deleteIcon"
                            onClick={() => {
                              setModal('delete');
                              setIsDelete(false);
                              setcompanyShow(true);
                              setCompanyId(item.uuid);
                            }}
                          />
                        );
                      }
                      return null;
                    })}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {pageCount > 0 && (
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeLinkClassName="activetab"
          activeClassName="active"
          previousLabel={<img src={previous} alt="upload" />}
          nextLabel={<img src={next} alt="upload" />}
          breakLabel="..."
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
        />
      )}
      <div>
        {companyShow && companyId && modal === 'view' ? (
          <CompanyDetail
            show={companyShow}
            companyId={companyId}
            isDisable={disable}
            onHide={() => {
              setcompanyShow(false);
              setCompanyId('');
            }}
          />
        ) : null}
      </div>
      <div>
        {companyShow && companyId && !isDelete && modal === 'delete' ? (
          <DeleteConfirmation
            show={companyShow}
            companyId={companyId}
            setIsDelete={setIsDelete}
            onHide={() => {
              setcompanyShow(false);
            }}
          />
        ) : null}
      </div>
      <div>{addCompanyShow && companyId === '' ? add : null}</div>
    </div>
  );
}
