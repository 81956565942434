import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { confirmAlert } from 'react-confirm-alert';
// import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Button, Form, CloseButton } from 'react-bootstrap';
import input from '../../components/Form/input';
import { editPlant, singlePlant } from '../../Stores/Action';
import './index.scss';

function PlantDetails(props) {
  const { plantId, onHide, isDisable, svalue, continent } = props;
  const dispatch = useDispatch();
  const id = plantId;
  useEffect(() => {
    dispatch(singlePlant(plantId));
  }, [plantId]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    plantName: '',
    type: '',
    location: '',
    penaltyRate: '',
    unitRate: '',
    longtitude: '',
    latitude: '',
    dbServer: '',
    dbPassword: '',
    keyString: '',
    socketLink: '',
    plantId: id,
  });

  const singlePlantData = useSelector((state) => state?.singlePlant);
  const successMessage = singlePlantData?.edit;
  const singledata = singlePlantData.Plant?.data;
  const singleloading = singlePlantData.loading;
  useEffect(() => {
    if (singledata) {
      setInitialValues({ ...initialValues, ...singledata });
    }
  }, [singledata]);
  const [formErrors, setformErrors] = useState({});
  const [errorShow, setErrorShow] = useState(false);
  // const [method, setMethod] = useState('plant');
  const handleHide = async () => {
    onHide();
  };
  const validate = (values) => {
    const errors = {};
    if (!values.plantName) {
      errors.plantName = 'Plant Name is required!';
    }
    if (!values.location) {
      errors.location = 'Location is required!';
    }
    if (!values.unitRate) {
      errors.unitRate = 'Unit Rate is required!';
    }
    if (!values.latitude) {
      errors.latitude = 'Latitude is required!';
    }
    if (!values.longtitude) {
      errors.longtitude = 'Longtitude is required!';
    }
    return errors;
  };
  useEffect(() => {
    setformErrors(validate(initialValues));
  }, [initialValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      dispatch(
        editPlant({
          filter: {
            type: initialValues.type,
            employeeId: svalue?.employee?.value,
            clientId: svalue?.client?.value,
            companyId: svalue?.company?.value,
            pageSize: 10,
            pageCount: 0,
          },
          data: {
            name: initialValues.plantName,
            location: initialValues.location,
            penaltyRate: initialValues.penaltyRate,
            unitRate: initialValues.unitRate,
            longtitude: initialValues.longtitude,
            latitude: initialValues.latitude,
            plantId: id,
          },
        }),
      );
      setErrorShow(false);
      setformErrors('');
    }
  };
  useEffect(() => {
    if (isSubmit && successMessage) onHide();
  });
  const handleChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    setIsSubmit(false);
    setErrorShow(false);
    setInitialValues((prevalue) => ({
      ...prevalue,
      [name]: value,
    }));
  };

  return singleloading ? null : (
    <div style={{ width: '70%' }}>
      <Modal
        {...props}
        backdropClassName="back-drop-plant"
        className="plant-modal"
        style={{
          height: '100vh',
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="plant-header">
            <h2
              style={{
                fontWeight: 'bolder',
                color: 'GrayText',
              }}
            >
              Plant Details
            </h2>
            <CloseButton style={{ float: 'right' }} onClick={handleHide} />
          </div>

          <Form>
            {input({
              handleChange,
              errorShow,
              value: initialValues.plantName,
              errors: formErrors.plantName,
              name: 'plantName',
              label: 'Plant Name',
              disabled: isDisable,
            })}

            {input({
              handleChange,
              errorShow,
              value: initialValues.type,
              name: 'type',
              label: 'Type',
              disabled: true,
            })}

            {input({
              handleChange,
              errorShow,
              value: initialValues.location,
              errors: formErrors.location,
              name: 'location',
              label: 'Location',
              disabled: isDisable,
            })}

            {input({
              handleChange,
              errorShow,
              value: initialValues.latitude,
              errors: formErrors.latitude,
              name: 'latitude',
              label: 'Latitude',
              disabled: isDisable,
            })}
            {input({
              handleChange,
              errorShow,
              value: initialValues.longtitude,
              errors: formErrors.longtitude,
              name: 'longtitude',
              label: 'Longtitude',
              disabled: isDisable,
            })}

            {input({
              handleChange,
              errorShow,
              value: initialValues.unitRate,
              errors: formErrors.unitRate,
              name: 'unitRate',
              label: 'Unit Rate',
              disabled: isDisable,
            })}

            {/*  eslint-disable-next-line */}
            {continent === 'IN' &&
              input({
                handleChange,
                errorShow,
                value: initialValues.penaltyRate,
                errors: formErrors.penaltyRate,
                name: 'penaltyRate',
                label: 'Penalty Rate',
                disabled: isDisable,
              })}
            {input({
              handleChange,
              errorShow,
              value:
                initialValues.keyString === '' ? ' ' : initialValues.keyString,
              errors: formErrors.keyString,
              name: 'keyString',
              label: 'Key String',
              disabled: true,
            })}

            {input({
              handleChange,
              errorShow,
              value:
                initialValues.dbServer === '' ? ' ' : initialValues.dbServer,
              errors: formErrors.dbServer,
              name: 'dbServer',
              label: 'DB Server',
              disabled: true,
            })}

            {input({
              handleChange,
              errorShow,
              value:
                initialValues.dbPassword === ''
                  ? ' '
                  : initialValues.dbPassword,
              errors: formErrors.dbPassword,
              name: 'dbPassword',
              label: 'DB Password',
              disabled: true,
            })}

            {input({
              handleChange,
              errorShow,
              value:
                initialValues.socketLink === ''
                  ? ' '
                  : initialValues.socketLink,
              errors: formErrors.socketLink,
              name: 'socketLink',
              label: 'Socket Link',
              disabled: true,
            })}
          </Form>
          <div className="btn-reset">
            {/* <Button
              type="button"
              className="under-line"
              onClick={() => {
                if (method === 'plant') {
                  setMethod('database');
                } else setMethod('plant');
              }}
            >
              {`${method === 'plant' ? 'Database Details' : 'Plant Details'}`}
            </Button> */}
            {isDisable === true ? null : (
              <Button
                type="button"
                style={{ margin: '0 24px' }}
                onClick={handleSubmit}
              >
                SAVE
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PlantDetails;
