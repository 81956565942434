import React from 'react'
import { Banner, BotShowcase, CTA, Deliver, PlatformInfoSection, SuccessStories } from '../components/homePage'


const HomePage = () => {
    return (
        <div className='' id='homePage'>
            <Banner />
            <PlatformInfoSection />
            <BotShowcase />
            <SuccessStories />
            <Deliver />
            <CTA />

        </div>
    )
}

export default HomePage
