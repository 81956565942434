import React from "react";

const ContactForm = () => {
  return (
    <div className="mx-auto">
      <div className="flex flex-col items-center gap-8 xl:gap-6 sm:gap-4">
        <h1 className="text-center font-epilogue text-14xl font-semibold text-textColor xxl:text-11xl sm:text-6xl">
          Get in touch
        </h1>
        <p className="mx-auto text-center text-4xl font-normal text-textColor xxl:text-xl xxl:!leading-[1.5] sm:text-base">
          Just tell us what you need and we'll get back ASAP.
        </p>
      </div>
      <div className="mt-[72px] sm:mt-10">
        <div className="rounded-[32px] bg-secondary p-10 sm:rounded-2xl sm:p-6">
          <div className="grid grid-cols-2 gap-5 sm:grid-cols-1 sm:gap-4">
            <div className="flex flex-col gap-1">
              <label className="text-xs font-normal text-codGray">
                First Name
              </label>
              <input
                type="text"
                className="block w-full rounded-xl border border-[#CBD6E2] bg-inputBg px-3 py-2 !outline-none"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-xs font-normal text-codGray">
                Last Name
              </label>
              <input
                type="text"
                className="block w-full rounded-xl border border-[#CBD6E2] bg-inputBg px-3 py-2 !outline-none"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-xs font-normal text-codGray">
                Business Email<span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                className="block w-full rounded-xl border border-[#CBD6E2] bg-inputBg px-3 py-2 !outline-none"
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-xs font-normal text-codGray">
                Phone Number
              </label>
              <div className="flex gap-3">
                <select
                  name="countryCode"
                  id="countryCode"
                  className="block w-fit rounded-xl border border-[#CBD6E2] bg-inputBg px-3 py-2 text-xs font-normal text-codGray !outline-none"
                >
                  <option value="united-states">United States</option>
                  <option value="india">India</option>
                </select>
                <div className="relative block flex w-full items-center gap-3 overflow-hidden rounded-xl border border-[#CBD6E2] bg-inputBg px-3 py-2">
                  <span className="block h-full w-fit">+1</span>
                  <input
                    type="number"
                    className="block w-full bg-inputBg !outline-none"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-xs font-normal text-codGray">
                Can you describe your requirements?
              </label>
              <textarea
                name="message"
                id="message"
                className="block w-full rounded-xl border border-[#CBD6E2] bg-inputBg px-3 py-2 !outline-none"
                rows={3}
              ></textarea>
            </div>
          </div>
          <button className="btn-primary mt-5">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
