import React, { useState } from 'react'
import { Link } from 'react-router-dom';


const Navigation = ({ navItems, isLinkHover, setIsLinkHover }) => {
    const [subLinks, setSubLinks] = useState(null)

    const handleMouseHover = (e, item) => {
        setIsLinkHover(item.hasDropdown)
        if (item.hasDropdown) setSubLinks(item.subLinks)
    }
    const handleMouseOut = () => {
        setIsLinkHover(false)
    }
    return (
        <nav className='flex items-center'>
            {
                navItems.map((item, index) => (
                    <div onMouseOver={(e) => handleMouseHover(e, item)} onMouseOut={(e) => handleMouseOut(e, item)} key={index} className='group flex cursor-pointer items-center gap-2 border-b-2 border-b-transparent px-6 py-6 text-xl font-normal text-white mix-blend-difference duration-500 hover:border-b-white xxl:p-5'>
                        {
                            item.hasDropdown ? (

                                <span className='block'>{item.text}</span>
                            ) : (
                                <Link to={item.href} key={index} className='block'>
                                    <span >
                                        {item.text}
                                    </span>
                                </Link>
                            )
                        }
                        {item.hasDropdown && (
                            <>
                                <span className='flex items-center justify-center duration-500 group-hover:-rotate-180'>
                                    <img className='size-3' src="/assets/images/arrow-down.svg" alt="arrow" />
                                </span>
                                <div className={`px-22 invisible absolute left-0 top-full h-fit w-full origin-top scale-y-75 bg-wildSand py-10 text-black opacity-0 transition-all duration-500 group-hover:visible group-hover:scale-y-100 group-hover:opacity-100`}>
                                    <div className="container">
                                        <div className="grid grid-cols-3 gap-3">
                                            {
                                                item.subLinks && item.subLinks.map((link, index) => (
                                                    <Link to={link.href} key={index} className='flex rounded-radius-2 bg-white px-6 py-3 duration-500 hover:bg-whiteIce'>
                                                        <span className='text-base font-semibold text-black'>
                                                            {link.text}
                                                        </span>
                                                    </Link>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                ))
            }

            {/* <div onMouseOver={() => setIsLinkHover(true)} onMouseOut={handleMouseOut} className={`px-22 absolute left-0 top-[78px] w-full h-fit py-10 bg-wildSand text-black  transition-all duration-500  origin-top ${isLinkHover ? 'scale-y-100 opacity-100 animate-fadeShow' : 'scale-y-95 opacity-0 animate-fadeHide'}`}>
                <div className="container">
                    <div className="grid grid-rows-3 gap-3">
                        {
                            subLinks && subLinks.map((link, index) => (
                                <Link to={link.href} key={index} className='flex w-fit rounded-radius-2 bg-white px-6 py-3'>
                                    <span className='text-base font-semibold text-black'>
                                        {link.text}
                                    </span>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div> */}


        </nav>
    )
}

export default Navigation
