import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, CloseButton } from 'react-bootstrap';
import moment from 'moment';
import Image from 'react-bootstrap/Image';
import { editEmployee, employeeView } from '../../Stores/Action';
import input from '../../components/Form/input';

function EmployeeDetail(props) {
  const dispatch = useDispatch();
  const { employeeId, isDisable, onHide } = props;
  useEffect(() => {
    dispatch(employeeView(employeeId));
  }, []);
  const employeestate = useSelector((state) => state.employee);
  const data = employeestate?.Employee?.data;
  const successMessage = employeestate?.edit;
  const [preview, setpreview] = useState();

  const [profileimage, setprofileimage] = useState('');
  // const baseUrl = 'http://localhost:5000/';
  useEffect(() => {
    setprofileimage(data?.profileUrl);
  }, [profileimage]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    data?.profileUrl ? setpreview(profileimage) : setpreview(undefined);
  }, [profileimage]);

  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    setFormValues({
      userName: data?.userName,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      joiningDate: data?.joiningDate,
      phone: data?.phone,
      // gender: data?.gender,
      // aadharNumber: data?.aadharNumber,
      // gstNumber: data?.gstNumber,
      // panNumber: data?.panNumber,
      address: data?.address,
      pinCode: data?.pinCode,
      permissions: data?.permissions,
      profileUrl: data?.profileUrl,
    });
  }, [data]);
  const uploadSingleFile = async () => {
    const formData = new FormData();
    formData.append('firstName', formValues.firstName);
    formData.append('lastName', formValues.lastName);
    formData.append('phone', formValues.phone);
    // formData.append('gender', formValues.gender);
    // formData.append('aadhar', formValues.aadharNumber);
    // formData.append('gst', formValues.gstNumber);
    // formData.append('pan', formValues.panNumber);
    formData.append('address', formValues.address);
    formData.append('pinCode', formValues.pinCode);
    formData.append('employeeId', employeeId);
    await dispatch(editEmployee(formData));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  useEffect(() => {
    // eslint-disable-next-line
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      uploadSingleFile();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const Name = /^[A-Za-z ]*$/;
    const Phone = /^(([1-9]{2,4})[ -]*)*?[0-9]{3,6}?[ -]*[0-9]{3,6}?$/;
    const pinCode = /^[0-9]{1,5}-?[0-9]{1,5}/;
    // const gstNumber = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    // const panNumber = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;

    if (!values.firstName) {
      errors.firstName = 'First Name is required!';
    } else if (!Name.test(values.firstName)) {
      errors.firstName = 'This is not a valid Name format!';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required!';
    } else if (!Name.test(values.lastName)) {
      errors.lastName = 'This is not a valid Name format!';
    }
    // if (!values.gstNumber) {
    //   errors.gstNumber = 'GST Number is required!';
    // } else if (!gstNumber.test(values.gstNumber)) {
    //   errors.gstNumber = 'This is not a valid GST format!';
    // } else if (values.gstNumber.length < 15) {
    //   errors.gstNumber = 'GST number is not Valid';
    // } else if (values.gstNumber.length > 15) {
    //   errors.gstNumber = 'GST number is not Valid';
    // }

    // if (!values.panNumber) {
    //   errors.panNumber = 'PAN Number is required!';
    // } else if (!panNumber.test(values.panNumber)) {
    //   errors.panNumber = 'This is not a valid PAN format!';
    // } else if (values.panNumber.length < 10) {
    //   errors.panNumber = 'PAN number is not Valid';
    // } else if (values.panNumber.length > 10) {
    //   errors.panNumber = 'PAN number is not Valid';
    // }

    if (!values.phone) {
      errors.phone = 'Phone is required';
    } else if (values.phone.length < 10) {
      errors.phone = 'Phone cannot exceed less than 10 characters';
    } else if (values.phone.length > 10) {
      errors.phone = ' Phone number is not correct';
    } else if (!Phone.test(values.phone)) {
      errors.phone = 'This is not a valid GST format!';
    }
    if (!values.pinCode) {
      errors.pinCode = 'Pin Code is required';
    } else if (values.pinCode.length < 6) {
      errors.pinCode = 'Pin Code cannot exceed less than 6 characters';
    } else if (values.pinCode.length > 6) {
      errors.pinCode = ' Pin Code number is not correct';
    } else if (!pinCode.test(values.pinCode)) {
      errors.pinCode = 'This is not a valid GST format!';
    }

    if (!values.address) {
      errors.address = 'Address is required!';
    }
    // if (!values.aadharNumber) {
    //   errors.aadharNumber = 'Aadhar Number is required';
    // } else if (values.aadharNumber.length < 12) {
    //   errors.aadharNumber = 'Aadhar Number cannot exceed less than 10 characters';
    // } else if (values.aadharNumber.length > 12) {
    //   errors.aadharNumber = 'Aadhar number is not correct';
    // }

    return errors;
  };
  const handleHide = async () => {
    setFormErrors(false);
    onHide();
    setIsSubmit(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (isSubmit && successMessage) onHide();
  });
  const ImageAvailable = (
    <Image
      className="in-image"
      style={{ height: '82px', width: '82px', borderRadius: '50%' }}
      crossOrigin="*"
      src={preview}
      alt="Update preview"
    />
  );
  const notProfile = <div className="__image" />;

  const profilePhoto = preview ? ImageAvailable : notProfile;

  return (
    <Modal {...props} dialogClassName="employee-modal">
      <Modal.Body
        dialogClassName="employee-form"
        style={{ display: 'block', padding: '0 1rem', height: '100%' }}
      >
        <div className="employeeHeader">
          <h2
            style={{
              fontWeight: 'bolder',
              color: 'GrayText',
            }}
          >
            Employee Details
          </h2>
          <CloseButton style={{ float: 'right' }} onClick={handleHide} />
        </div>
        <Form
          method="POST"
          id="EmployeeDetail"
          onSubmit={handleSubmit}
          style={{ paddingBottom: '10px' }}
        >
          <div className="profile-image" style={{ display: 'flex' }}>
            <label htmlFor="photo-upload" className="___image">
              <div>{profilePhoto}</div>
            </label>
          </div>
          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.userName,
            name: 'userName',
            label: 'User Name',
            disabled: true,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.firstName,
            errors: formErrors.firstName,
            name: 'firstName',
            label: 'First Name',
            disabled: isDisable,
            maxLength: 50,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.lastName,
            errors: formErrors.lastName,
            name: 'lastName',
            label: 'Last Name',
            disabled: isDisable,
            maxLength: 50,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.email,
            name: 'email',
            label: 'Email',
            disabled: true,
          })}
          {input({
            handleChange,
            errorShow: isSubmit,
            value: moment(formValues.joiningDate).format('DD-MM-YYYY'),
            name: 'joiningDate',
            label: 'Joining Date',
            disabled: true,
          })}
          {/*
          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.gstNumber,
            errors: formErrors.gstNumber,
            name: 'gstNumber',
            label: 'GST Number',
            disabled: isDisable,
            maxLength: 15,
          })}

          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.panNumber,
            errors: formErrors.panNumber,
            name: 'panNumber',
            label: 'PAN Number',
            disabled: isDisable,
            maxLength: 10,
          })} */}
          {/* {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.aadharNumber,
            errors: formErrors.aadharNumber,
            name: 'aadharNumber',
            label: 'Aadhar Number',
            disabled: disable,
            maxLength: 12,
          })} */}
          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.phone,
            errors: formErrors.phone,
            name: 'phone',
            label: 'Phone Number',
            disabled: isDisable,
            maxLength: 10,
          })}
          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.pinCode,
            errors: formErrors.pinCode,
            name: 'pinCode',
            label: 'Pincode',
            disabled: isDisable,
            maxLength: 6,
          })}
          {input({
            handleChange,
            errorShow: isSubmit,
            value: formValues.address,
            errors: formErrors.address,
            name: 'address',
            label: 'Address',
            disabled: isDisable,
          })}
          {isDisable ? (
            ''
          ) : (
            <div className="profileFooter">
              <Button
                type="submit"
                id="submit-button"
                style={{ marginBottom: '10px' }}
              >
                SAVE
              </Button>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EmployeeDetail;
