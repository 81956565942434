import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";
import React, { useRef } from "react";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

const OurStory = () => {
  const textRef = useRef(null);
  useGSAP(() => {
    const text = new SplitType(textRef.current, { types: "chars, words" });
    gsap.from(text.chars, {
      scrollTrigger: {
        trigger: textRef.current,
        start: "top 80%",
        end: "bottom 20%",
        scrub: true,
        markers: false,
      },
      opacity: 0.2,
      stagger: 0.1,
    });
  });

  return (
    <div className="relative py-20 sm:py-10">
      <div className="container">
        <div className="mb-12">
          <h3 className="text-left font-epilogue text-10xl font-semibold text-textColor xl:text-7xl sm:text-6xl">
            Our Story
          </h3>
        </div>

        <div>
          <p
            ref={textRef}
            className="text-6xl font-medium leading-[1.5] text-textColor md:text-5xl"
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
            <br />
            <br />
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
            <br />
            <br />
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
            <br />
            <br />
            It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was
            popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum.
            <br />
            <br /> It has survived not only five centuries, but also the leap
            into electronic typesetting, remaining essentially unchanged. It was
            popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
