
import { createContext, useEffect, useState } from "react";
import { ReactLenis, useLenis } from "lenis/react";
import { useLocation } from "react-router-dom";

export const ScrollContext = createContext();
function SmoothScroll({ children }) {
    const [scrollDirection, setScrollDirection] = useState(1);
    const [scrollAmount, setScrollAmount] = useState(0);
    const lenis = useLenis(({ scroll, direction }) => {
        setScrollAmount(scroll);
        setScrollDirection(direction);
    });
    const pathName = useLocation().pathname
    useEffect(() => {
        lenis?.scrollTo(0, {
            immediate: true,
        });
    }, [pathName]);

    return (
        <ScrollContext.Provider value={{ scrollAmount, scrollDirection, lenis }}>
            <ReactLenis
                root
                options={{
                    lerp: 0.1,
                    duration: 1.5,
                    smoothTouch: true,
                    normalizeWheel: true,
                }}
            >
                {children}
            </ReactLenis>
        </ScrollContext.Provider>
    );
}

export default SmoothScroll;