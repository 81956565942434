import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, CloseButton } from 'react-bootstrap';
import input from '../../components/Form/input';
import { addEmployee } from '../../Stores/Action';
// import {upload} from '../../components/assets/upload.svg';

function AddEmployee(props) {
  const dispatch = useDispatch();
  const { onHide } = props;
  const employeestate = useSelector((state) => state.employeeList);
  const successMessage = employeestate?.add;
  const initialValues = {
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    // gstNumber: '',
    // panNumber: '',
    address: '',
    pinCode: '',
    companyName: '',
    aadharNumber: '',
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorShow, setErrorShow] = useState(false);

  const addEmployeeData = async () => {
    const formData = new FormData();
    formData.append('userName', formValues.userName);
    formData.append('firstName', formValues.firstName);
    formData.append('lastName', formValues.lastName);
    formData.append('email', formValues.email);
    formData.append('phone', formValues.phone);
    // formData.append('aadhar', formValues.aadharNumber);
    // formData.append('gst', formValues.gstNumber);
    // formData.append('pan', formValues.panNumber);
    formData.append('address', formValues.address);
    formData.append('pinCode', formValues.pinCode);
    // formData.append('companyName', formValues.companyName);
    await dispatch(addEmployee(formData));
  };
  const validate = (values) => {
    const errors = {};
    const Name = /^[A-Za-z ]*$/;
    const Phone = /^(([1-9]{2,4})[ -]*)*?[0-9]{3,6}?[ -]*[0-9]{3,6}?$/;
    const pinCode = /^[0-9]{1,5}-?[0-9]{1,5}/;
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})/;
    // const gstNumber = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    // const panNumber = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (!values.userName) {
      errors.userName = 'User Name is required!';
    }
    if (!values.firstName) {
      errors.firstName = 'First Name is required!';
    } else if (!Name.test(values.firstName)) {
      errors.firstName = 'This is not a valid Name format!';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required!';
    } else if (!Name.test(values.lastName)) {
      errors.lastName = 'This is not a valid Name format!';
    }
    // if (!values.gstNumber) {
    //   errors.gstNumber = 'GST Number is required!';
    // } else if (!gstNumber.test(values.gstNumber)) {
    //   errors.gstNumber = 'This is not a valid GST format!';
    // } else if (values.gstNumber.length < 15) {
    //   errors.gstNumber = 'GST number is not Valid';
    // } else if (values.gstNumber.length > 15) {
    //   errors.gstNumber = 'GST number is not Valid';
    // }

    // if (!values.panNumber) {
    //   errors.panNumber = 'PAN Number is required!';
    // } else if (!panNumber.test(values.panNumber)) {
    //   errors.panNumber = 'This is not a valid PAN format!';
    // } else if (values.panNumber.length < 10) {
    //   errors.panNumber = 'PAN number is not Valid';
    // } else if (values.panNumber.length > 10) {
    //   errors.panNumber = 'PAN number is not Valid';
    // }
    if (!values.email) {
      errors.email = 'email is required';
    } else if (!regex.test(values.email)) {
      errors.email = 'This email is not valid!';
    }

    if (!values.phone) {
      errors.phone = 'Phone is required';
    } else if (values.phone.length < 10) {
      errors.phone = 'Phone cannot exceed less than 10 characters';
    } else if (values.phone.length > 10) {
      errors.phone = ' Phone number is not correct';
    } else if (!Phone.test(values.phone)) {
      errors.phone = 'This is not a valid GST format!';
    }
    if (!values.pinCode) {
      errors.pinCode = 'Pin Code is required';
    } else if (values.pinCode.length < 6) {
      errors.pinCode = 'Pin Code cannot exceed less than 6 characters';
    } else if (values.pinCode.length > 6) {
      errors.pinCode = ' Pin Code number is not correct';
    } else if (!pinCode.test(values.pinCode)) {
      errors.pinCode = 'This is not a valid GST format!';
    }
    if (!values.address) {
      errors.address = 'Address is required!';
    }
    // if (!values.aadharNumber) {
    //   errors.aadharNumber = 'Aadhar Number is required';
    // } else if (values.aadharNumber.length < 12) {
    //   errors.aadharNumber = 'Aadhar Number cannot exceed less than 10 characters';
    // } else if (values.aadharNumber.length > 12) {
    //   errors.aadharNumber = 'Aadhar number is not correct';
    // }
    return errors;
  };

  useEffect(() => {
    setFormErrors(validate(formValues));
  }, [formValues]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsSubmit(false);
    setErrorShow(false);
    if (name === 'panNumber' || name === 'gstNumber') {
      setFormValues({ ...formValues, [name]: value.toUpperCase() });
    } else setFormValues({ ...formValues, [name]: value });
  };
  // useEffect(() => {
  //   // eslint-disable-next-line
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     addEmployeeData();
  //   }
  // }, [formErrors]);

  const handleHide = async () => {
    setFormErrors(false);
    onHide();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      addEmployeeData();
      setErrorShow(false);
      setFormErrors('');
      setIsSubmit(true);
    }
  };
  useEffect(() => {
    if (isSubmit && successMessage) onHide();
  });

  return (
    <Modal {...props} dialogClassName="employee-modal">
      <Modal.Body
        dialogClassName="employee-form"
        style={{
          display: 'block',
          padding: '1 1rem',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <div className="employeeHeader">
          <h2
            style={{
              fontWeight: 'bolder',
              color: 'GrayText',
            }}
          >
            Add Employee
          </h2>
          <CloseButton style={{ float: 'right' }} onClick={handleHide} />
        </div>
        <Form id="employeedetail" onSubmit={handleSubmit}>
          {input({
            handleChange,
            errorShow,
            value: formValues.userName,
            errors: formErrors.userName,
            name: 'userName',
            label: 'User Name',
            maxLength: 20,
          })}

          {input({
            handleChange,
            errorShow,
            value: formValues.firstName,
            errors: formErrors.firstName,
            name: 'firstName',
            label: 'First Name',
            maxLength: 50,
          })}

          {input({
            handleChange,
            errorShow,
            value: formValues.lastName,
            errors: formErrors.lastName,
            name: 'lastName',
            label: 'Last Name',
            maxLength: 50,
          })}

          {input({
            handleChange,
            errorShow,
            value: formValues.email,
            errors: formErrors.email,
            name: 'email',
            label: 'Email',
          })}
          {input({
            handleChange,
            errorShow,
            value: formValues.phone,
            errors: formErrors.phone,
            name: 'phone',
            label: 'Phone Number',
            maxLength: 10,
          })}
          {/*
          {input({
            handleChange,
            errorShow,
            value: formValues.gstNumber,
            errors: formErrors.gstNumber,
            name: 'gstNumber',
            label: 'GST Number',
            maxLength: 16,
          })}

          {input({
            handleChange,
            errorShow,
            value: formValues.panNumber,
            errors: formErrors.panNumber,
            name: 'panNumber',
            label: 'PAN Number',
            maxLength: 10,
          })} */}
          {input({
            handleChange,
            errorShow,
            value: formValues.address,
            errors: formErrors.address,
            name: 'address',
            label: 'Address',
          })}
          {input({
            handleChange,
            errorShow,
            value: formValues.pinCode,
            errors: formErrors.pinCode,
            name: 'pinCode',
            label: 'Zip Code',
            maxLength: 6,
          })}
          {/* {input({
            handleChange,
            errorShow,
            value: formValues.aadharNumber,
            errors: formErrors.aadharNumber,
            name: 'aadharNumber',
            label: 'Aadhar Number',
            disabled: disable,
            maxLength: 12,
          })} */}
          {/* {input({
            handleChange,
            errorShow,
            value: formValues.companyName,
            errors: formErrors.companyName,
            name: 'companyName',
            label: 'Company',
          })} */}
          <div className="employeeFooter">
            {/* <h6>Multiple Client</h6> */}
            <Button type="submit" id="submit-button" style={{ float: 'right' }}>
              Add
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEmployee;
