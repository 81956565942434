import React from 'react'

const Phone = () => {
    return (
        <>
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.9557 30.4834C34.9557 31.0234 34.8357 31.5784 34.5807 32.1184C34.3257 32.6584 33.9957 33.1684 33.5607 33.6485C32.8256 34.4585 32.0156 35.0435 31.1006 35.4185C30.2006 35.7935 29.2256 35.9885 28.1755 35.9885C26.6455 35.9885 25.0105 35.6285 23.2854 34.8935C21.5604 34.1585 19.8353 33.1684 18.1253 31.9234C16.4003 30.6634 14.7652 29.2684 13.2052 27.7233C11.6602 26.1633 10.2651 24.5282 9.02009 22.8182C7.79006 21.1082 6.80004 19.3981 6.08003 17.7031C5.36001 15.993 5 14.358 5 12.798C5 11.7779 5.18 10.8029 5.54001 9.90288C5.90002 8.98786 6.47003 8.14784 7.26505 7.39783C8.22507 6.4528 9.2751 5.98779 10.3851 5.98779C10.8051 5.98779 11.2251 6.07779 11.6002 6.2578C11.9902 6.4378 12.3352 6.70781 12.6052 7.09782L16.0853 12.003C16.3553 12.378 16.5503 12.723 16.6853 13.053C16.8203 13.368 16.8953 13.683 16.8953 13.968C16.8953 14.328 16.7903 14.688 16.5803 15.033C16.3853 15.378 16.1003 15.738 15.7402 16.098L14.6002 17.2831C14.4352 17.4481 14.3602 17.6431 14.3602 17.8831C14.3602 18.0031 14.3752 18.1081 14.4052 18.2281C14.4502 18.3481 14.4952 18.4381 14.5252 18.5281C14.7952 19.0231 15.2602 19.6681 15.9203 20.4481C16.5953 21.2282 17.3153 22.0232 18.0953 22.8182C18.9053 23.6132 19.6853 24.3482 20.4804 25.0233C21.2604 25.6833 21.9054 26.1333 22.4154 26.4033C22.4904 26.4333 22.5804 26.4783 22.6854 26.5233C22.8054 26.5683 22.9254 26.5833 23.0604 26.5833C23.3154 26.5833 23.5104 26.4933 23.6754 26.3283L24.8155 25.2033C25.1905 24.8282 25.5505 24.5432 25.8955 24.3632C26.2405 24.1532 26.5855 24.0482 26.9605 24.0482C27.2455 24.0482 27.5455 24.1082 27.8755 24.2432C28.2055 24.3782 28.5505 24.5732 28.9256 24.8282L33.8907 28.3533C34.2807 28.6233 34.5507 28.9383 34.7157 29.3134C34.8657 29.6884 34.9557 30.0634 34.9557 30.4834Z" stroke="#141414" stroke-width="2" stroke-miterlimit="10" />
                <path d="M29.7501 16.484C29.7501 15.584 29.0451 14.204 27.9951 13.0789C27.0351 12.0439 25.76 11.2339 24.5 11.2339" stroke="#141414" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M35.0002 16.4881C35.0002 10.6829 30.3051 5.98779 24.5 5.98779" stroke="#141414" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </>
    )
}

export default Phone
