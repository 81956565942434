import { useSelector } from 'react-redux';

export default function tabManage() {
  const pers = useSelector((state) => state.permissions.data);
  // const role = useSelector((state) => state.userDetails?.user?.rolename);
  /*
    The keys for sideItems are permissions Name
          'DASHBOARD',
          'PLANT',
          'CLIENT'
          'EMPLOYEE'
          'PROFILE',
          'PREDICTION',
          'CHART'
          'MANAGE'
          'REQUEST'
          'SETTING'
  */
  const DASHBOARD = {
    permission: 'DASHBOARD',
    display: 'Dashboard',
    icon: <img className="dashboard menu_image" alt="menuItem" />,
    to: '',
    section: '',
  };
  // const PLANT = {
  //   permission: 'PLANT',
  //   display: 'Plant',
  //   icon: <img className="plants menu_image" alt="menuItem" />,
  //   to: 'plant',
  //   section: 'plant',
  // };
  const EMPLOYEE = {
    permission: 'EMPLOYEE',
    display: 'Employee',
    icon: <img className="employee menu_image" alt="menuItem" />,
    to: 'employee',
    section: 'employee',
  };
  const COMPANY = {
    permission: 'CLIENT_COMPANY',
    display: 'Company',
    icon: <img className="company menu_image" alt="menuItem" />,
    to: 'company',
    section: 'company',
  };
  const CLIENT = {
    permission: 'CLIENT',
    display: 'Client',
    icon: <img className="client menu_image" alt="menuItem" />,
    to: 'client',
    section: 'client',
  };
  const MANAGE = {
    permission: 'ROLE_PERMISSIONS',
    display: 'Manage',
    icon: <img className="manage menu_image" alt="menuItem" />,
    to: 'manage',
    section: 'manage',
  };
  const REQUEST = {
    permission: 'REQUEST',
    display: 'Request',
    icon: <img className="request menu_image" alt="menuItem" />,
    to: 'request',
    section: 'request',
  };
  const SETTING = {
    // permission: 'SETTING',
    display: 'Setting',
    icon: <img className="setting menu_image" alt="menuItem" />,
    to: 'setting',
    section: 'setting',
  };

  const sideItems = [
    DASHBOARD,
    // PLANT,
    EMPLOYEE,
    COMPANY,
    CLIENT,
    MANAGE,
    REQUEST,
    SETTING,
  ];
  // const tabs = pers
  //   ? sideItems.filter((i) => pers?.includes(i.permission))
  //   : [DASHBOARD];
  const filter = sideItems.filter((i) => {
    if (!i.permission) return true;
    // if (role === 'client' && i.permission === 'CLIENT_COMPANY') return false;
    return pers?.map((p) => p.name).includes(i.permission);
  });
  const tabs = pers ? filter : [DASHBOARD];
  return tabs;
}
