import React from 'react';

export default function Setting() {
  return (
    <div className="notFound">
      <h1 style={{ color: '#84BD18' }}>Under Maintenance</h1>
      <div className="notFound-gif" />
    </div>
  );
}
