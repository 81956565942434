import React from 'react';
import { formatCash } from '../../Functions';

function PieTooltip({ datum }) {
  return (
    <p
      style={{
        backgroundColor: datum.color,
        padding: 4,
        borderRadius: 8,
        color: 'white',
      }}
    >
      &#8377;&nbsp;
      {formatCash(datum.value)}
    </p>
  );
}

function LineTooltip({ point }) {
  const { serieColor, data } = point;
  return (
    <p
      style={{
        backgroundColor: serieColor,
        padding: '4px 8px 0px 8px',
        // paddingTop: 4,
        // paddingBottom: 0,
        // paddingLeft: 5,
        // paddingRight: 5,
        borderRadius: 5,
        lineHeight: 1.5,
        color: 'white',
      }}
    >
      &#8377;&nbsp;
      {formatCash(data.y)}
      <p style={{ fontSize: 'small', paddingTop: 1, paddingBottom: 1 }}>
        {data.x}
      </p>
    </p>
  );
}

function SGAGTooltip({ point }) {
  const { serieColor, data } = point;
  return (
    <p
      style={{
        backgroundColor: serieColor,
        padding: '4px 8px 0px 8px',
        borderRadius: 5,
        lineHeight: 1.5,
        color: 'white',
      }}
    >
      {`${formatCash(data.y)} Units`}
      <p style={{ fontSize: 'small', paddingTop: 1, paddingBottom: 1 }}>
        {data.x}
      </p>
    </p>
  );
}

function MAETooltip({ point }) {
  const { serieColor, data } = point;
  return (
    <p
      style={{
        backgroundColor: serieColor,
        padding: '4px 8px 0px 8px',
        borderRadius: 5,
        lineHeight: 1.5,
        color: 'white',
      }}
    >
      {`${formatCash(data.y)}%`}
      <p style={{ fontSize: 'small', paddingTop: 1, paddingBottom: 1 }}>
        {data.x}
      </p>
    </p>
  );
}

function BarTooltip({ data, color }) {
  // const { penalty } = data;
  return (
    <p
      style={{
        color: 'white',
        padding: 4,
        borderRadius: 8,
        border: '1px solid rgb(128,128,128,0.5)',
        backgroundColor: color,
      }}
    >
      &#8377;&nbsp;
      {formatCash(data.penalty)}
      <p
        style={{
          fontSize: 'small',
          paddingTop: 1,
          paddingBottom: 1,
          margin: 0,
        }}
      >
        {data.block}
      </p>
    </p>
  );
}

export { LineTooltip, PieTooltip, BarTooltip, SGAGTooltip, MAETooltip };
