import { Route, Routes } from 'react-router-dom'
import HomePage from '../pages/HomePage'
import ContactPage from '../pages/ContactPage'
import PricingPage from '../pages/PricingPage'
import AboutUsPage from '../pages/AboutUsPage'

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
        </Routes>
    )
}

export default AppRoutes
