import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
// import axios from 'axios';
import { ResponsiveBar } from '@nivo/bar';
import ReactGA from 'react-ga';
// import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Plant from '../Plant/index';
import PenaltyChart from '../../components/dashboard/graphs/Line';
import PieChart from '../../components/dashboard/graphs/Pie';
// import BarChart from '../../components/dashboard/graphs/Bar';

// import DashboardClientTable from '../../components/dashboard/ClientTable';
import {
  clientList,
  employeeList,
  penaltyCards,
  penaltyChart,
  penaltyPieChart,
  plantType,
  plantList,
  companyList,
  employeeFilter,
  companyFilter,
} from '../../Stores/Action';
// import moreOption from '../../components/assets/moreOption.svg';
// import LineChart from '../../components/dashboard/graphs/Line';
// import { agsg, mae } from '../../services/DummyDatas';
import { BarTooltip } from '../../components/dashboard/graphs/tooltip';
import { formatCash } from '../../components/Functions';
// import Company from '../../components/dashboard/CompanyTable';
import Filter from './Filter';
import PredictionTable from '../Plant/PlantPrediction/PredictionTable';
import LoadingSpinner from '../../components/LoadingSpinner/Loading';
import {
  DummyBar,
  DummyLine,
  DummyPie,
} from '../../components/dashboard/Dummies';

export default function Dashboard() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const permission = useSelector((state) => state.permissions.data);
  const role = useSelector((state) => state.userDetails.user?.rolename);
  const [isLoading, setIsLoading] = useState(false);
  const [lineChart, setLineChart] = useState({
    type: 'tdp',
    color: 'rgb(22, 163, 74)',
  });
  const [svalue, setSValue] = useState({
    plant: '',
    employee: '',
    client: '',
    company: '',
    type: '',
  });
  const plantTypes = useSelector((state) => state?.plantType?.plantType?.data);
  const region = useSelector((state) => state?.continent);

  const typesData = plantTypes?.map((t) => {
    const i = { value: t.type, label: t.label };
    return i;
  });
  const [types, setType] = useState('');
  const [singles, setSingles] = useState(false);
  // const [plantid, setPlantId] = useState('');
  const cardsData = useSelector((state) => state.penaltyCards?.data);
  const cardsLoading = useSelector((state) => state.penaltyCards?.loading);
  const chartData = useSelector((state) => state.penaltyChart?.data);
  const mainPenaltyLoading = useSelector(
    (state) => state.penaltyChart?.loading,
  );
  const xData = useSelector((state) => state.prediction?.data);
  const predictionData = xData ? xData?.data : [];
  const coordinationData = useSelector(
    (state) => state.penaltyPieChart?.data,
  ) || [{ id: '', value: 0 }];
  const coordinationLoading = useSelector(
    (state) => state.penaltyPieChart?.loading,
  );
  useEffect(() => {
    ReactGA.pageview('/dashboard', ['Dashboard Page'], 'dashboard');
    setIsLoading(true);
    dispatch(plantType({ continent: region }));
    dispatch(companyList({ pageSize: 100, pageCount: 0 }));
    if (role === 'company' || role === 'employee') dispatch(clientList());
    if (role === 'company') dispatch(employeeList());
    // setPlantId('');
    setSingles(false);
    // if (typesData?.length) {
    //   setSValue({ ...svalue, type: typesData?.[0] });
    //   // setType(plantTypes?.[0]?.type);
    // }
    setSValue({ ...svalue, type: typesData?.[0] });
  }, [region]);
  useEffect(() => {
    if (plantTypes?.length) {
      setSValue({ ...svalue, type: typesData?.[0] });
      setType(plantTypes?.[0]?.type);
      if (role === 'company') {
        dispatch(
          employeeFilter({ type: typesData?.[0]?.value, continent: region }),
        );
      }
      dispatch(
        companyFilter({ type: typesData?.[0]?.value, continent: region }),
      );
    }
  }, [plantTypes, region]);
  const plants = useSelector((state) => state?.plantFilter?.plantFilter?.data);
  // const plants = useSelector((state) => state.plantData.plant?.data?.count);
  // useEffect(() => {
  //   if (plants?.length === 1) {
  //     // dispatch(penaltyCards({ type: types, plantId: plantid }));
  //     // dispatch(penaltyPieChart({ type: types, plantId: plantid }));
  //   }
  // }, [plants]);

  const [pieLegendHidden, setPieLegendHidden] = useState([]);
  const [ActiveCordData, setActiveCordData] = useState(coordinationData);

  useEffect(() => {
    if (types?.length) {
      dispatch(
        penaltyChart({
          chartType: lineChart.type,
          plantId: svalue.plant?.value,
          employeeId: svalue.employee?.value,
          clientId: svalue.client?.value,
          companyId: svalue.company?.value,
          type: types,
        }),
      );
    }
  }, [lineChart, region]);
  useEffect(() => {
    if (types?.length) {
      setIsLoading(false);
      dispatch(penaltyCards({ type: types }));
      dispatch(penaltyPieChart({ type: types }));
      dispatch(
        plantList({
          continent: region,
          type: types,
          pageCount: 0,
          pageSize: 10,
        }),
      );
      dispatch(
        penaltyChart({
          chartType: lineChart.type,
          plantId: svalue.plant?.value,
          employeeId: svalue.employee?.value,
          clientId: svalue.client?.value,
          companyId: svalue.company?.value,
          type: types,
        }),
      );
    }
  }, [types, region]);
  const color = ['#2FBA8E', '#CC2266', '#6A4EE7', '#FFAB1F'];
  const pieSpec = {
    toggleSerie: true,
    anchor: 'bottom',
    direction: 'row',
    // justify: false,
    // translateX: 0,
    itemWidth: 100,
    itemHeight: 10,
    itemsSpacing: 10,
    symbolSize: 10,
    symbolShape: 'circle',
    itemDirection: 'left-to-right',
  };
  const pieDataHalf = Math.ceil(coordinationData.length / 2);
  const pieLegendClick = (d) => {
    if (!d.hidden) setPieLegendHidden([...pieLegendHidden, d.id]);
    else if (coordinationData?.find((i) => i.id === d.id).value === '0') {
      setPieLegendHidden(pieLegendHidden);
    } else setPieLegendHidden(pieLegendHidden.filter((i) => i !== d.id));
  };
  useEffect(() => {
    setActiveCordData(
      coordinationData.filter((i) => !pieLegendHidden.includes(i.id)),
    );
  }, [coordinationData, pieLegendHidden]);

  useEffect(() => {
    setPieLegendHidden([
      ...coordinationData.map((i) => {
        if (i.value === '0') return i.id;
        return false;
      }),
    ]);
  }, [coordinationData, svalue]);
  const pieSplitLegend = [
    {
      data: coordinationData?.slice(0, pieDataHalf).map((i, n) => {
        const temp = {
          id: i.id,
          label: `${i.id} ${i.year}`,
          color: color[n],
          hidden: pieLegendHidden.includes(i.id),
        };
        return temp;
      }),
      onClick: pieLegendClick,
      translateY: 20,
      ...pieSpec,
    },
    {
      data: coordinationData
        ?.slice(pieDataHalf, coordinationData.length)
        .map((i, n) => {
          const temp = {
            id: i.id,
            label: `${i.id} ${i.year}`,
            color: color[pieDataHalf + n],
            hidden: pieLegendHidden.includes(i.id),
          };
          return temp;
        }),
      onClick: pieLegendClick,
      translateY: 50,
      ...pieSpec,
    },
  ];
  const barChartData = chartData?.map((i) => {
    const formated = moment(i.block).format('DD-MM-YYYY');
    const xValue = lineChart.type === 'tdp' ? i.block : formated;
    const f = {
      block: xValue,
      penalty: i.penalty,
    };
    return f;
  });
  const penaltyChartData = chartData?.map((i) => {
    const formated = moment(i.block).format('DD-MM-YYYY');
    const xValue = lineChart.type === 'tdp' ? i.block : formated;
    const f = {
      x: xValue,
      y: i.penalty,
    };
    return f;
  });

  const typCardBar = cardsData?.typChart?.map((i) => {
    const formated = moment(i.block).format('DD-MM-YYYY');
    const f = {
      block: formated,
      penalty: i.penalty,
    };
    return f;
  });
  const tmpCardBar = cardsData?.tmpChart?.map((i) => {
    const formated = moment(i.block).format('DD-MM-YYYY');
    const f = {
      block: formated,
      penalty: i.penalty,
    };
    return f;
  });
  const twpCardBar = cardsData?.twpChart?.map((i) => {
    const formated = moment(i.block).format('DD-MM-YYYY');
    const f = {
      block: formated,
      penalty: i.penalty,
    };
    return f;
  });
  const penalty = [
    {
      id: lineChart.type.toUpperCase(),
      color: lineChart.color,
      data: chartData?.length ? penaltyChartData : [{ x: '', y: 0 }],
    },
  ];
  const PenaltyTitle = () => {
    switch (lineChart.type) {
      case 'typ':
        return 'Yearly Penalty';
      case 'tmp':
        return 'Monthly Penalty';
      case 'twp':
        return 'Weekly Penalty';
      case 'tdp':
        return 'Daily Penalty';
      default:
        return 'Daily Penalty';
    }
  };

  const AgSg = [
    {
      id: 'AG',
      color: 'rgb(0, 0, 255)',
      data: predictionData?.map((i) => ({
        x: i['Time Block'],
        y: parseFloat(i.AG).toFixed(2),
      })),
    },
    {
      id: 'SG',
      color: 'rgb(0, 255, 0)',
      data: predictionData?.map((i) => ({
        x: i['Time Block'],
        y: parseFloat(i.SG).toFixed(2),
      })),
    },
  ];

  const mae = [
    {
      id: 'MAE',
      color: 'rgb(0, 0, 255)',
      data: predictionData?.map((i) => ({
        x: i['Time Block'],
        y: parseFloat(i.MAE).toFixed(2),
      })),
    },
  ];

  // const checkCardPermission = permission.includes('CARDS');
  // const checkChartPermission = permission.includes('CHART');
  const checkPlantPermission = permission?.includes('PLANT') || true;
  // const checkClientCompanyPermission = permission?.includes('CLIENT_PERMISSION') || true;
  // const checkCompanyPermission = permission?.includes('COMPANY') || true;
  // const checkEmployeePermission = permission?.includes('EMPLOYEE') || true;
  // const predNav = () => {
  //   navigate('../plant/predictionTable');
  // };
  // const [card, setCard] = useState(true);

  // eslint-disable-next-line
  const mainPenaltyChart = !chartData?.length ? (
    <DummyLine place="main" />
  ) : penalty[0].data.length !== 1 ? (
    <PenaltyChart data={penalty} />
  ) : (
    chartData && (
      <ResponsiveBar
        data={barChartData}
        keys={['penalty']}
        indexBy="block"
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        valueScale={{ type: 'symlog' }}
        indexScale={{ type: 'band', round: true }}
        colors={[lineChart.color]}
        borderRadius={2}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        tooltip={BarTooltip}
        padding={0.9}
      />
    )
  );
  return (
    <>
      <Filter
        continent={region}
        single={setSingles}
        // setPlantId={setPlantId}
        // plantId={plantid}
        chartType={lineChart.type}
        type={setType}
        svalue={svalue}
        setSValue={setSValue}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Row>
          <div className="cards">
            <Card
              className="card-item one"
              onClick={() => {
                setLineChart({ type: 'typ', color: 'rgb(0, 140, 204)' });
              }}
            >
              <Col lg={7} md={7} xs={7}>
                <Card className="name">TYP</Card>
                <div className="title">Total Yearly Penalty</div>
                {cardsLoading || (
                  <div className="penalty">
                    {cardsData && cardsData?.typ !== '0' ? (
                      formatCash(cardsData?.typ)
                    ) : (
                      <p style={{ color: 'rgb(168, 168, 168)' }}>No data</p>
                    )}
                  </div>
                )}
              </Col>

              <Col lg={5} md={5} xs={5} className="graph-col">
                {cardsLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="card-graph">
                    {cardsData?.typChart?.length !== 0 ? (
                      <ResponsiveBar
                        data={typCardBar}
                        keys={['penalty']}
                        indexBy="block"
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        valueScale={{ type: 'symlog' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={['rgb(0, 140, 204)']}
                        borderRadius={2}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridY={false}
                        enableLabel={false}
                        tooltip={BarTooltip}
                        padding={0.6}
                      />
                    ) : (
                      <DummyBar />
                    )}
                  </div>
                )}
              </Col>
            </Card>
            <Card
              className="card-item two"
              onClick={() => {
                setLineChart({ type: 'tmp', color: 'rgb(126, 34, 206)' });
              }}
            >
              <Col lg={7} md={7} xs={7}>
                <Card className="name">TMP</Card>
                <div className="title">Total Monthly Penalty</div>
                {cardsLoading || (
                  <div className="penalty">
                    {cardsData && cardsData?.tmp !== '0' ? (
                      formatCash(cardsData?.tmp)
                    ) : (
                      <p style={{ color: 'rgb(168, 168, 168)' }}>No data</p>
                    )}
                  </div>
                )}
              </Col>

              <Col lg={5} md={5} xs={5} className="graph-col">
                {cardsLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="card-graph">
                    {cardsData?.tmpChart?.length ? (
                      <ResponsiveBar
                        data={tmpCardBar}
                        keys={['penalty']}
                        indexBy="block"
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        valueScale={{ type: 'symlog' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={['rgb(126, 34, 206)']}
                        borderRadius={2}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridY={false}
                        enableLabel={false}
                        tooltip={BarTooltip}
                        padding={0.6}
                      />
                    ) : (
                      <DummyBar />
                    )}
                  </div>
                )}
              </Col>
            </Card>
            <Card
              className="card-item four"
              onClick={() => {
                setLineChart({ type: 'twp', color: 'rgb(165, 50, 107)' });
              }}
            >
              <Col lg={7} md={7} xs={7}>
                <Card className="name">TWP</Card>
                <div className="title">Total Weekly Penalty</div>
                {cardsLoading || (
                  <div className="penalty">
                    {cardsData && cardsData?.twp !== '0' ? (
                      formatCash(cardsData?.twp)
                    ) : (
                      <p style={{ color: 'rgb(168, 168, 168)' }}>No data</p>
                    )}
                  </div>
                )}
              </Col>
              <Col lg={5} md={5} xs={5} className="graph-col">
                {cardsLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="card-graph">
                    {cardsData?.twpChart?.length ? (
                      <ResponsiveBar
                        data={twpCardBar}
                        keys={['penalty']}
                        indexBy="block"
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        valueScale={{ type: 'symlog' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={['rgb(165, 50, 107)']}
                        borderRadius={2}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridY={false}
                        enableLabel={false}
                        tooltip={BarTooltip}
                        padding={0.6}
                      />
                    ) : (
                      <DummyBar />
                    )}
                  </div>
                )}
              </Col>
            </Card>
            <Card
              className="card-item three"
              onClick={() => {
                setLineChart({ type: 'tdp', color: 'rgb(22, 163, 74)' });
              }}
            >
              <Col lg={7} md={7} xs={7}>
                <Card className="name">TDP</Card>
                <div className="title">
                  <b>Total Daily </b>
                  Penalty
                </div>
                {cardsLoading || (
                  <div className="penalty">
                    {cardsData && cardsData?.tdp !== '0' ? (
                      formatCash(cardsData?.tdp)
                    ) : (
                      <p style={{ color: 'rgb(168, 168, 168)' }}>No data</p>
                    )}
                  </div>
                )}
              </Col>

              <Col lg={5} md={5} xs={5} className="graph-col">
                {cardsLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="card-graph">
                    {cardsData?.tdpChart?.length ? (
                      <ResponsiveBar
                        data={cardsData?.tdpChart}
                        keys={['penalty']}
                        indexBy="block"
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        valueScale={{ type: 'symlog' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={['rgb(22, 163, 74)']}
                        borderRadius={2}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridY={false}
                        enableLabel={false}
                        tooltip={BarTooltip}
                        padding={0.6}
                      />
                    ) : (
                      <DummyBar />
                    )}
                  </div>
                )}
              </Col>
            </Card>
          </div>
          <div className="charts">
            <Col xs={12} lg={8} className="centerGraph">
              <div className="Graph-div">
                <Col className="penalty-label">
                  {PenaltyTitle()}
                  {/* <button
                    type="button"
                    onClick={() => setCard(!card)}
                    className="none-btn"
                  >
                    ...
                  </button> */}
                </Col>
                <Col className="penalty-graph">
                  {mainPenaltyLoading ? <LoadingSpinner /> : mainPenaltyChart}
                </Col>
              </div>
            </Col>
            <Col xs={12} lg={4} className="pieChart">
              <div className="Graph-div">
                <Col className="penalty-label">
                  Quarterly Penalty
                  <div className="sub">Financial year</div>
                  {/* <button
                    type="button"
                    onClick={() => navigate('../plant/predictionTable')}
                    className="none-btn"
                  >
                    <img src={moreOption} alt="moreOption" />
                  </button> */}
                </Col>
                <Col className="penalty-graph">
                  {coordinationLoading ? (
                    <LoadingSpinner />
                  ) : (
                    coordinationData && (
                      <>
                        <div className="center-total">
                          &#8377;&nbsp;
                          {formatCash(
                            ActiveCordData.reduce(
                              (p, i) => p + parseFloat(i.value),
                              0,
                            ),
                          )}
                          <div>QP</div>
                        </div>
                        {coordinationData.find((i) => i.value !== '0') ? (
                          <PieChart
                            colors={color}
                            margin={{ bottom: 70 }}
                            data={coordinationData}
                            legend={pieSplitLegend}
                          />
                        ) : (
                          <DummyPie />
                        )}
                      </>
                    )
                  )}
                </Col>
              </div>
            </Col>
          </div>
        </Row>
      )}
      {/* <Row className="MonthBar">
        <div className="Graph-div">
          <Col className="penalty-label">
            Monthly Penalty
            <button
              type="button"
              onClick={() => predNav()}
              className="none-btn"
            >
              <img src={moreOption} alt="moreOption" />
            </button>
          </Col>
          <Col className="penalty-graph">
            <BarChart data={MonthsBar} />
          </Col>
        </div>
      </Row> */}
      {!singles ? (
        <div className="table-co">
          {checkPlantPermission && (
            <Row className="dashboardTable">
              <Col>
                <div>
                  <Plant
                    continent={region}
                    svalue={svalue}
                    setSValue={setSValue}
                    types={types}
                    singles={setSingles}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <div>
          <Row>
            <Col xl={12} xs={12} className="Charts">
              <Row>
                <Col xl={6} xs={12} className="centerGraph">
                  <div className="Graph-div">
                    <Col className="penalty-label">
                      Prediction & Actual Data
                      {/* <button
                        type="button"
                        onClick={() => predNav()}
                        className="none-btn"
                      >
                        <img src={moreOption} alt="moreOption" />
                      </button> */}
                    </Col>
                    <Col className="penalty-graph">
                      {predictionData ? (
                        <PenaltyChart tip="AGSG" data={AgSg} />
                      ) : (
                        <DummyLine place="AGSG" />
                      )}
                    </Col>
                  </div>
                </Col>
                <Col xl={6} xs={12} className="centerGraph">
                  <div className="Graph-div">
                    <Col className="penalty-label" title="Mean Absolute Error">
                      MAE
                      {/* <button
                        type="button"
                        onClick={() => predNav()}
                        className="none-btn"
                      >
                        <img src={moreOption} alt="moreOption" />
                      </button> */}
                    </Col>
                    <Col className="penalty-graph">
                      {predictionData ? (
                        <PenaltyChart tip="MAE" data={mae} />
                      ) : (
                        <DummyLine place="MAE" />
                      )}
                    </Col>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="dashboardTable">
            <Col>
              <div>
                <PredictionTable plantPredictionId={svalue.plant?.value} />
              </div>
            </Col>
          </Row>
          {plants?.length === 1 && (
            <Row className="dashboardTable">
              <Col>
                <div>
                  <Plant
                    continent={region}
                    svalue={svalue}
                    setSValue={setSValue}
                    types={types}
                    singles={setSingles}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
      {/* {checkClientPermission && (
        <Row className="dashboardTable">
          <Col>
            <div>
              <DashboardCompanyTable />
            </div>
          </Col>
        </Row>
      )}
      {/* {checkClientPermission && (
        <Row className="dashboardTable">
          <Col>
            <div>
              <DashboardClientTable />
            </div>
          </Col>
        </Row>
      )}
      {checkEmployeePermission && (
        <Row className="dashboardTable">
          <Col>
            <div>
              <DashboardEmployeeTable />
            </div>
          </Col>
        </Row>
      )} */}
    </>
    // <div >
    //   <div >
    //     <div className="card one">
    //       {/* <img src={card1} alt="jala" className="images" /> */}
    //     </div>
    //     <div className="card two">
    //       {/* <img src={card2} alt="jala" className="images" /> */}
    //     </div>
    //     <div className="card three">
    //       {/* <img src={card3} alt="jala" className="images" /> */}
    //     </div>
    //     {/* <div className="card four" /> */}
    //   </div>
    //   <div >
    //     {/* <img src={centerGraph} alt="jala" className="images" /> */}
    //   </div>
    //   <div className="pieChart yearlysectioned">
    //     {/* <img src={pieChart} alt="jala" className="images" /> */}
    //   </div>
    //   <div className="plantsTable">
    //     {/* <img src={table} alt="jala" className="images" /> */}
    //   </div>
    // </div>
  );
}
