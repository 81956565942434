import React from "react";

const LandingStats = () => {
  return (
    <div className="relative z-[1] py-20 sm:py-10">
      <div className="container">
        <div className="mb-[200px] flex flex-col items-center gap-8 xl:mb-24 xl:gap-6 md:mb-10 sm:gap-4">
          <h1 className="text-center font-epilogue text-14xl font-semibold text-textColor xxl:text-11xl sm:text-6xl">
            About Indite
          </h1>
        </div>

        <div className="flex items-center justify-center gap-24 xl:gap-10 md:flex-col sm:gap-8">
          <div className="bg-darkGradient flex flex-col items-center justify-center gap-2 rounded-radius-4 border border-secondary/50 p-10 xl:p-8 md:w-full sm:p-6">
            <h3 className="text-[88px] font-extrabold leading-normal text-textColor xl:text-11xl">
              26
            </h3>
            <p className="text-center text-xl font-normal text-textColor">
              employees
            </p>
          </div>
          <div className="bg-darkGradient flex flex-col items-center justify-center gap-2 rounded-radius-4 border border-secondary/50 p-10 xl:p-8 md:w-full sm:p-6">
            <h3 className="text-[88px] font-extrabold leading-normal text-textColor xl:text-11xl">
              3000+
            </h3>
            <p className="text-center text-xl font-normal text-textColor">
              customers
            </p>
          </div>
          <div className="bg-darkGradient flex flex-col items-center justify-center gap-2 rounded-radius-4 border border-secondary/50 p-10 xl:p-8 md:w-full sm:p-6">
            <h3 className="text-[88px] font-extrabold leading-normal text-textColor xl:text-11xl">
              2024
            </h3>
            <p className="text-center text-xl font-normal text-textColor">
              founded
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingStats;
