import axios from 'axios';
import { toast } from 'react-toastify';

import { config } from '../../services/configHeader';
import reduxStore from '../Store';
import {
  PLANT_PENDING_REQUEST,
  PLANT_PENDING_SUCCESS,
  PLANT_PENDING_FAIL,
  CLIENT_COMPANY_PENDING_REQUEST,
  CLIENT_COMPANY_PENDING_SUCCESS,
  CLIENT_COMPANY_PENDING_FAIL,
  PLANT_APPROVE_REJECT_REQUEST,
  PLANT_APPROVE_REJECT_SUCCESS,
  PLANT_APPROVE_REJECT_FAIL,
  COMPANY_APPROVE_REJECT_REQUEST,
  COMPANY_APPROVE_REJECT_SUCCESS,
  COMPANY_APPROVE_REJECT_FAIL,
  PLANT_REQUEST_DETAIL_REQUEST,
  PLANT_REQUEST_DETAIL_SUCCESS,
  PLANT_REQUEST_DETAIL_FAIL,
  COMPANY_REQUEST_DETAIL_REQUEST,
  COMPANY_REQUEST_DETAIL_SUCCESS,
  COMPANY_REQUEST_DETAIL_FAIL,
  CLIENT_REQUEST_DETAIL_REQUEST,
  CLIENT_REQUEST_DETAIL_SUCCESS,
  CLIENT_REQUEST_DETAIL_FAIL,
  CLIENT_PENDING_REQUEST,
  CLIENT_PENDING_SUCCESS,
  CLIENT_PENDING_FAIL,
  CLIENT_APPROVE_REJECT_REQUEST,
  CLIENT_APPROVE_REJECT_SUCCESS,
  CLIENT_APPROVE_REJECT_FAIL,
} from '../Types';

const requestAPI = `${process.env.REACT_APP_API_URL}request`;

const plantRequestList = () => async (dispatch) => {
  try {
    dispatch({ type: PLANT_PENDING_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(`${requestAPI}/plantRequestList`, config);

    await dispatch({ type: PLANT_PENDING_SUCCESS, payload: { data } });
  } catch (error) {
    dispatch({
      type: PLANT_PENDING_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const plantRequestDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PLANT_REQUEST_DETAIL_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${requestAPI}/plantRequestDetail?plantId=${id}`,
      config,
    );

    await dispatch({ type: PLANT_REQUEST_DETAIL_SUCCESS, payload: { data } });
  } catch (error) {
    dispatch({
      type: PLANT_REQUEST_DETAIL_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const clientCompanyRequestList = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_COMPANY_PENDING_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${requestAPI}/clientCompanyRequestList`,
      config,
    );

    await dispatch({ type: CLIENT_COMPANY_PENDING_SUCCESS, payload: { data } });
  } catch (error) {
    dispatch({
      type: CLIENT_COMPANY_PENDING_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const companyRequestDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_REQUEST_DETAIL_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${requestAPI}/clientCompanyRequestDetail?companyId=${id}`,
      config,
    );

    await dispatch({ type: COMPANY_REQUEST_DETAIL_SUCCESS, payload: { data } });
  } catch (error) {
    dispatch({
      type: COMPANY_REQUEST_DETAIL_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const approveRejectPlantRequest = (plantData) => async (dispatch) => {
  try {
    dispatch({ type: PLANT_APPROVE_REJECT_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${requestAPI}/actionPlantRequest`,
      plantData,
      config,
    );

    await dispatch({ type: PLANT_APPROVE_REJECT_SUCCESS, payload: { data } });
    toast.success(data.message);
    dispatch(plantRequestList());
  } catch (error) {
    dispatch(plantRequestList());
    dispatch({
      type: PLANT_APPROVE_REJECT_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const approveRejectCompanyRequest = (companyData) => async (dispatch) => {
  try {
    dispatch({ type: COMPANY_APPROVE_REJECT_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${requestAPI}/actionClientCompanyRequest`,
      companyData,
      config,
    );

    await dispatch({ type: COMPANY_APPROVE_REJECT_SUCCESS, payload: { data } });
    toast.success(data.message);
    dispatch(clientCompanyRequestList());
  } catch (error) {
    dispatch(clientCompanyRequestList());

    dispatch({
      type: COMPANY_APPROVE_REJECT_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const clientRequestList = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_PENDING_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(`${requestAPI}/clientRequestList`, config);

    await dispatch({ type: CLIENT_PENDING_SUCCESS, payload: { data } });
  } catch (error) {
    dispatch({
      type: CLIENT_PENDING_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};
const clientRequestDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_REQUEST_DETAIL_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${requestAPI}/clientRequestDetail?clientId=${id}`,
      config,
    );

    await dispatch({ type: CLIENT_REQUEST_DETAIL_SUCCESS, payload: { data } });
  } catch (error) {
    dispatch({
      type: CLIENT_REQUEST_DETAIL_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const approveRejectClientRequest = (plantData) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_APPROVE_REJECT_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${requestAPI}/actionClientRequest`,
      plantData,
      config,
    );

    await dispatch({ type: CLIENT_APPROVE_REJECT_SUCCESS, payload: { data } });
    toast.success(data.message);
    dispatch(clientRequestList());
  } catch (error) {
    dispatch(clientRequestList());
    dispatch({
      type: CLIENT_APPROVE_REJECT_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const contactUsRequest = (userInfo) => async () => {
  try {
    const { data } = await axios.post(
      'http://18.216.70.130/api/v1/auth/inquiry',
      userInfo,
      config,
    );

    toast.success(data.message);
  } catch (error) {
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

export {
  plantRequestList,
  clientCompanyRequestList,
  approveRejectCompanyRequest,
  approveRejectPlantRequest,
  plantRequestDetails,
  companyRequestDetails,
  clientRequestList,
  clientRequestDetail,
  approveRejectClientRequest,
  contactUsRequest,
};
