import React from 'react';
import { Carousel } from 'react-bootstrap';
import ReactGA from 'react-ga';
import back1 from '../../components/assets/oneBack.png';
import back2 from '../../components/assets/twoBack.jpg';
import back3 from '../../components/assets/threeBack.jpg';
import stock from '../../components/assets/stock.svg';

function Home({ setActive }) {
  ReactGA.event({
    action: 'home page',
    value: setActive,
    label: 'home page',
  });
  const item1 = {
    back: back1,
    heading: 'Energy sector roles',
    info: 'We help companies from the production, management and commercialization of energy to trading and investment.',
  };
  const item2 = {
    back: back2,
    heading: 'Energy forecasting',
    info: 'Automatic forecasting solutions of the demand and renewable energy at short, mid and long term',
  };
  const item3 = {
    back: back3,
    heading: 'Price forecasting',
    info: 'Automatic forecasting solutions of the prices of the energy market, at short, mid and long term.',
  };
  const slideItems = [item1, item2, item3];

  return (
    <section className="content-wrapper">
      <div id="hero">
        <div className="toper">
          <Carousel>
            {slideItems.map((i) => (
              <Carousel.Item style={{ backgroundImage: `url(${i.back})` }}>
                <Carousel.Caption>
                  <h3>{i.heading}</h3>
                  <p>{i.info}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="buttons">
            <button
              type="button"
              className="book btn"
              onClick={() => setActive('contact')}
            >
              Book Demo
            </button>
            <button
              type="button"
              className="watch btn"
              onClick={() => {
                window.open('https://www.youtube.com/watch?v=sd0cBox2ZI0');
              }}
            >
              Watch Video
            </button>
          </div>
        </div>
        <div className="forecasting">
          <div className="title">Energy Forecasting</div>
          <div className="info">
            <div className="left">
              <div className="section">
                <img src={stock} alt="" />
                <h5>Energy price forecasting</h5>
                <p>
                  Automatic forecasting solutions of the prices of the energy
                  market, at short, mid and long term; available as a product
                  and as a service.
                </p>
              </div>
              <div className="section">
                <img src={stock} alt="" />
                <h5>Demand and renewable energy forecasting</h5>
                <p>
                  Automatic forecasting solutions of the demand and renewable
                  energy at short, mid and long term; available as a product and
                  as a service.
                </p>
              </div>
            </div>
            <div className="right">
              <div className="section">
                <div className="card">
                  <h5>Energy forecasting services </h5>
                  <p>
                    Our products and services include highly reliable forecasts
                    for prices, demand and renewable energy adapted to the needs
                    of the energy market for the short, medium and long-term
                    horizons.
                  </p>
                </div>
              </div>
              <div className="section">
                <div className="card">
                  <h5>Consulting and application cases</h5>
                  <p>
                    Over the course of 23 years these energy forecasts have been
                    used as products or as services, constituting the
                    fundamental input of a set of cases of applications,
                    solutions or consultancies applied to various energy sectors
                    such as utilities , traders, renewable developers, large
                    consumers, traders, investment funds and banks.
                  </p>
                </div>
              </div>
              <div className="section">
                <div className="card">
                  <h5>Energy sector roles</h5>
                  <p>
                    We help companies from the production, management and
                    commercialization of energy to trading and investment. We
                    provide information, forecasting and consulting services for
                    all companies in the energy sector: TSOs, utilities,
                    traders, retailers, large and electro intensive consumers,
                    renewable energies, investment funds and banks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
