import React, { useState } from 'react'

const Deliver = () => {
    const [activeTab, setActiveTab] = useState(1)
    return (
        <div className='py-28 xl:py-20 sm:py-16'>
            <div className="container">

                <div className="mx-auto mb-14 flex max-w-[70%] flex-col items-center justify-center gap-4 sm:mb-8 sm:max-w-none">
                    <h3 className='font-epilogue text-10xl font-semibold text-textColor xl:text-7xl sm:text-6xl'>
                        How we deliver
                    </h3>
                    <p className='text-center text-4xl font-normal text-textColor xl:text-xl sm:text-base'>We offer you the best of both worlds – either build chatbots yourself using our no-
                        code interface or have our bot experts build the project for you with our professional </p>
                </div>

                <div className="mx-auto flex w-full items-center justify-center border-b border-b-silverChalice sm:w-full">
                    <button onClick={() => setActiveTab(1)} className={`${activeTab === 1 ? 'after:scale-x-100' : 'after:scale-x-0'} w-full relative px-9 pb-5 sm:pb-4  sm:text-xs text-2xl text-textColor font-semibold duration-500 after:absolute after:bottom-[-1px] after:left-0 after:h-[3px] after:w-full after:bg-primary after:duration-500 after:origin-right`}>YOU BUILD</button>
                    <button onClick={() => setActiveTab(2)} className={`${activeTab === 2 ? 'after:scale-x-100' : 'after:scale-x-0'} w-full relative px-9 pb-5 sm:pb-4  sm:text-xs text-2xl text-textColor font-semibold duration-500 after:absolute after:bottom-[-1px] after:left-0 after:h-[3px] after:w-full after:bg-primary after:duration-500 after:origin-left`}>WE BUILD</button>
                </div>

                <div className="mt-16 sm:mt-10">
                    {
                        activeTab === 1 && (
                            <div className="grid grid-cols-2 gap-10 sm:grid-cols-1 sm:gap-4">
                                <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                    <div className="p-3">

                                        <div className='overflow-hidden rounded-radius-4'>
                                            <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-1.png" alt="deliver-1" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                        <h4 className='text-5xl font-semibold text-codGray'>No-code bot builder</h4>
                                        <p className='text-base font-normal text-codGray'>Easily build powerful chatbots for your customers and users using our no-code bot builder.</p>

                                    </div>
                                </div>
                                <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                    <div className="p-3">
                                        <div className='overflow-hidden rounded-radius-4'>
                                            <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-2.png" alt="deliver-2" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                        <h4 className='text-5xl font-semibold text-codGray'>40+ chat elements</h4>
                                        <p className='text-base font-normal text-codGray'>Be creative in designing your chatbot conversation by picking from 40+ chat elements.</p>

                                    </div>
                                </div>

                                <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                    <div className="p-3">
                                        <div className='overflow-hidden rounded-radius-4'>
                                            <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-3.png" alt="deliver-3" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                        <h4 className='text-5xl font-semibold text-codGray'>Unlimited everything</h4>
                                        <p className='text-base font-normal text-codGray'>Scale your chatbots across your organization with unlimited bots and users to build them.</p>

                                    </div>
                                </div>

                                <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                    <div className="p-3">
                                        <div className='overflow-hidden rounded-radius-4'>
                                            <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-4.png" alt="deliver-4" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                        <h4 className='text-5xl font-semibold text-codGray'>Integrations</h4>
                                        <p className='text-base font-normal text-codGray'>Discover and integrate with your favourite apps using our app market, or fire HTTP requests.</p>

                                    </div>
                                </div>

                                <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                    <div className="p-3">
                                        <div className='overflow-hidden rounded-radius-4'>
                                            <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-5.png" alt="deliver-5" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                        <h4 className='text-5xl font-semibold text-codGray'>Security & compliance</h4>
                                        <p className='text-base font-normal text-codGray'>Your data is safely stored on our cloud servers in an encrypted format along with other measures to ensure total data security.</p>

                                    </div>
                                </div>

                                <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                    <div className="p-3">
                                        <div className='overflow-hidden rounded-radius-4'>
                                            <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-6.png" alt="deliver-6" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                        <h4 className='text-5xl font-semibold text-codGray'>Support assistance</h4>
                                        <p className='text-base font-normal text-codGray'>Get answers to questions from our rich help articles and product videos or create a ticket to get help from bot experts.</p>

                                    </div>
                                </div>

                            </div>
                        )
                    }

                    {activeTab === 2 && (
                        <div className="grid grid-cols-2 gap-10">
                            <div className='col-span-2 overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                <div className="p-3">
                                    <div className='overflow-hidden rounded-radius-4'>
                                        <img className='w-full object-cover' src="/assets/images/deliver-7.png" alt="deliver-7" />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                    <h4 className='text-5xl font-semibold text-codGray'>Dedicated account manager</h4>
                                    <p className='text-base font-normal text-codGray'>You get a go-to person that you can reach out to anytime for product, finance, or any other queries.</p>

                                </div>
                            </div>
                            <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                <div className="p-3">
                                    <div className='overflow-hidden rounded-radius-4'>
                                        <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-8.png" alt="deliver-8" />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                    <h4 className='text-5xl font-semibold text-codGray'>Kick-off and onboarding sessions</h4>
                                    <p className='text-base font-normal text-codGray'>Knowing is everything. Our kick-off meetings involve multiple stakeholders who understand everything about your project.</p>

                                </div>
                            </div>

                            <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                <div className="p-3">
                                    <div className='overflow-hidden rounded-radius-4'>
                                        <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-9.png" alt="deliver-9" />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                    <h4 className='text-5xl font-semibold text-codGray'>Q review meetings</h4>
                                    <p className='text-base font-normal text-codGray'>Our account manager meets you every Q to discuss the chatbot performance, overall feedback and explore new use cases.</p>

                                </div>
                            </div>

                            <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                <div className="p-3">
                                    <div className='overflow-hidden rounded-radius-4'>
                                        <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-10.png" alt="deliver-10" />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                    <h4 className='text-5xl font-semibold text-codGray'>5-day delivery</h4>
                                    <p className='text-base font-normal text-codGray'>Our team swiftly works towards understanding, building and delivering your chatbot project in a week so you can launch soon.</p>

                                </div>
                            </div>

                            <div className='overflow-hidden rounded-radius-4 bg-babyPurpleGradient shadow-lightGrayBorder'>
                                <div className="p-3">
                                    <div className='overflow-hidden rounded-radius-4'>
                                        <img className='aspect-video h-full w-full object-cover' src="/assets/images/deliver-5.png" alt="deliver-5" />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 px-8 py-12 text-center sm:py-8">
                                    <h4 className='text-5xl font-semibold text-codGray'>Dedicated slack channel</h4>
                                    <p className='text-base font-normal text-codGray'>Think of us as an extension to your team. We are a Slack channel away if you have questions or need help with anything.</p>

                                </div>
                            </div>



                        </div>
                    )}
                </div>

            </div>

        </div>
    )
}

export default Deliver
