import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  userSigninReducer,
  profileUpdateReducer,
  resetPasswordReducer,
  penaltyChartReducer,
  penaltyPieChartReducer,
  penaltyCardsReducer,
  plantReducer,
  plantDetailReducer,
  clientListReducer,
  clientReducer,
  employeeListReducer,
  employeeReducer,
  predictionReducer,
  permissionsReducer,
  welcomeReducer,
  companyListReducer,
  companyReducer,
  permissionsListReducer,
  permissionsViewReducer,
  plantFilterReducer,
  employeeFilterReducer,
  clientFilterReducer,
  companyFilterReducer,
  plantTypeFilterReducer,
  rolePermissionsReducer,
  clientCompanyRequestReducer,
  plantRequestReducer,
  dashboardFiltersReducer,
  plantDetailRequestReducer,
  clientCompanyDetailRequestReducer,
  clientListRequestReducer,
  clientDetailRequestReducer,
  profileImageUpdateReducer,
  forecastingCardsReducer,
  continentReducer,
} from './Reducer';

const persistConfig = {
  key: 'root',
  storage,
};
const reducer = combineReducers({
  welcome: welcomeReducer,
  continent: continentReducer,
  dashboardFilters: dashboardFiltersReducer,
  userDetails: userSigninReducer,
  permissions: permissionsReducer,
  userUpdate: profileUpdateReducer,
  userReset: resetPasswordReducer,
  penaltyChart: penaltyChartReducer,
  penaltyPieChart: penaltyPieChartReducer,
  penaltyCards: penaltyCardsReducer,
  forecastingCards: forecastingCardsReducer,
  plantData: plantReducer,
  singlePlant: plantDetailReducer,
  client: clientListReducer,
  clientDetails: clientReducer,
  employee: employeeReducer,
  employeeList: employeeListReducer,
  prediction: predictionReducer,
  company: companyListReducer,
  companyDetails: companyReducer,
  permissionList: permissionsListReducer,
  permissionDetails: permissionsViewReducer,
  plantFilter: plantFilterReducer,
  employeeFilter: employeeFilterReducer,
  clientFilter: clientFilterReducer,
  companyFilter: companyFilterReducer,
  plantType: plantTypeFilterReducer,
  rolePermissions: rolePermissionsReducer,
  plantRequest: plantRequestReducer,
  clientCompanyRequest: clientCompanyRequestReducer,
  plantRequestDetail: plantDetailRequestReducer,
  companyrequestDetail: clientCompanyDetailRequestReducer,
  clientRequestDetail: clientDetailRequestReducer,
  clientRequest: clientListRequestReducer,
  imageUpdate: profileImageUpdateReducer,
});

const initialValue = {
  userDetails: {
    success: false,
    data: {},
    loading: false,
  },
  dashboardFilters: {
    type: '',
    employee: '',
    company: '',
    client: '',
    plant: '',
    failed: '',
  },
  permissions: {
    success: false,
    data: {},
    loading: false,
  },
  userUpdate: {
    success: false,
    data: {},
    loading: false,
  },
  userReset: {
    success: false,
    data: {},
    loading: false,
  },
  penaltyChart: {
    success: false,
    data: [],
    loading: false,
  },
  penaltyPieChart: {
    success: false,
    data: [],
    loading: false,
  },
  penaltyCards: {
    success: false,
    data: {},
    loading: false,
  },
  forecastingCards: {
    success: false,
    data: {},
    loading: false,
  },
  plantData: {
    success: false,
    data: [],
    loading: false,
  },
  singlePlant: {
    success: false,
    data: {},
    loading: false,
  },
  client: {
    success: false,
    data: {},
    loading: false,
  },
  clientDetails: {
    success: false,
    data: {},
    loading: false,
  },
  employee: {
    success: false,
    data: {},
    loading: false,
  },
  employeeList: {
    success: false,
    data: {},
    loading: false,
  },
  prediction: {
    success: false,
    data: {},
    loading: false,
  },
};

const persistedReducer = persistReducer(persistConfig, reducer);

const middlewares = [thunk];
const store = createStore(
  persistedReducer,
  initialValue,
  composeWithDevTools(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);

export default store;
