import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import details from '../../components/Form/requestDetails';
import {
  approveRejectCompanyRequest,
  approveRejectPlantRequest,
  companyRequestDetails,
  plantRequestDetails,
  clientRequestDetail,
  approveRejectClientRequest,
} from '../../Stores/Action';
import './index.scss';

function RequestConfirmation(props) {
  const role = useSelector((state) => state.userDetails?.user?.rolename);
  const { onHide, plantId, companyId, exists, clientId, sentance } = props;
  const dispatch = useDispatch();
  const pers = useSelector((state) => state.permissions?.data);
  let i = [];
  pers?.map((p) => {
    if (p.name === 'EMPLOYEE') {
      i = p.actions;
      return i;
    }
    return null;
  });
  useEffect(() => {
    if (plantId) {
      dispatch(plantRequestDetails(plantId));
    }
    if (companyId) {
      dispatch(companyRequestDetails(companyId));
    }
    if (clientId) {
      dispatch(clientRequestDetail(clientId));
    }
  }, [plantId, companyId]);

  const [initialValues, setInitialValues] = useState({
    ownername: '',
    type: '',
    status: '',
    action: '',
    address: '',
    employeeName: '',
    email: '',
    companyname: '',
  });

  const [formErrors, setformErrors] = useState({});
  const [errorShow, setErrorShow] = useState(false);
  const [reason, setReason] = useState({
    texts: '',
  });
  const singlePlantData = useSelector((state) => state?.plantRequestDetail);
  const plantdata = singlePlantData?.plant?.data;

  const companyRequestData = useSelector(
    (state) => state?.companyrequestDetail,
  );
  const companydata = companyRequestData?.company?.data;

  const clientRequestData = useSelector((state) => state?.clientRequestDetail);
  const clientdata = clientRequestData?.client?.data;

  useEffect(() => {
    if (companydata) {
      setInitialValues({ ...initialValues, ...companydata });
    }
  }, [companydata]);

  const validate = (values) => {
    const errors = {};
    if (!values.texts) {
      errors.texts = 'Provide Reason for Rejection';
    }
    return errors;
  };

  useEffect(() => {
    setformErrors(validate(reason));
  }, [reason]);

  const handleRequest = (data) => {
    onHide();
    if (plantId) {
      dispatch(
        approveRejectPlantRequest({
          plantId: data.id,
          remark: reason.texts,
          status: data.action,
          exists,
        }),
      );
    } else if (companyId) {
      dispatch(
        approveRejectCompanyRequest({
          companyId: data.id,
          remark: reason.texts,
          status: data.action,
          exists,
        }),
      );
    } else if (clientId) {
      dispatch(
        approveRejectClientRequest({
          clientId: data.id,
          remark: reason.texts,
          status: data.action,
          exists,
        }),
      );
    }
  };

  const handleSuccess = () => {
    if (plantId) {
      handleRequest({ id: plantId, action: 'approve' });
    } else if (companyId) {
      handleRequest({ id: companyId, action: 'approve' });
    } else if (clientId) {
      handleRequest({ id: clientId, action: 'approve' });
    }
  };
  const handleReject = (e) => {
    e.preventDefault();
    setformErrors(validate(reason));
    setErrorShow(true);
    if (Object.keys(formErrors).length === 0) {
      if (plantId) {
        handleRequest({ id: plantId, action: 'reject' });
      } else if (companyId) {
        handleRequest({ id: companyId, action: 'reject' });
      } else if (clientId) {
        handleRequest({ id: clientId, action: 'reject' });
      }
      setErrorShow(false);
      setformErrors('');
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorShow(false);
    setReason({ [name]: value });
  };

  const change = (a) => {
    if (a === 'reject') return 'Rejected';
    if (a === 'approve') return 'Approved';
    if (a === 'pending') return 'Pending';
    return '';
  };

  return (
    <Modal
      {...props}
      // style={{width:'500px'}}
      backdropClassName="delete-modal"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          fontWeight: 'bolder',
          paddingLeft: '30px',
          color: 'gray',
        }}
        closeButton
      >
        <Modal.Title style={{ fontWeight: '600' }}>{sentance}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {companyId && (
          <Form className="request_form">
            {role ===
              'company' /* eslint operator-linebreak: ["error", "after"] */ &&
              details({
                value: initialValues.employeeName,
                name: 'employeeName',
                label: 'Request from',
              })}
            {details({
              value: initialValues.companyname,
              name: 'companyname',
              label: 'Company Name',
            })}

            {details({
              value: change(initialValues.status),
              name: 'status',
              label: 'Status',
            })}

            {details({
              value: initialValues.action,
              name: 'action',
              label: 'Action',
            })}

            {details({
              value: initialValues.email,
              name: 'email',
              label: 'Email',
            })}

            {details({
              value: initialValues.address,
              name: 'address',
              label: 'Address',
            })}

            {details({
              value: moment(initialValues.createdAt).format('DD-MM-YYYY hh:mm'),
              name: 'createdAt',
              label: 'Requested Date',
            })}
          </Form>
        )}
        {plantId && (
          <Form className="request_form">
            {role ===
              'company' /* eslint operator-linebreak: ["error", "after"] */ &&
              details({
                value: plantdata?.employeeName,
                name: 'employeeName',
                label: 'Request from',
              })}
            {details({
              value: plantdata?.companyName,
              name: 'companyName',
              label: 'Company Name',
            })}
            {details({
              value: plantdata?.plantName,
              name: 'plantName',
              label: 'Plant Name',
            })}
            {details({
              value: plantdata?.type,
              name: 'type',
              label: 'Plant Type',
            })}

            {details({
              value: plantdata?.unitRate,
              name: 'unitRate',
              label: 'Unit Rate',
            })}
            {details({
              value: plantdata?.penaltyRate,
              name: 'penaltyRate',
              label: 'Penalty Rate',
            })}
            {details({
              value: plantdata?.location,
              name: 'location',
              label: 'Location',
            })}
            {details({
              value: change(plantdata?.status),
              name: 'status',
              label: 'Status',
            })}

            {details({
              value: plantdata?.action ? plantdata?.action : ' ',
              name: 'action',
              label: 'Action',
            })}

            {details({
              value: moment(plantdata?.createdAt).format('DD-MM-YYYY hh:mm'),
              name: 'createdAt',
              label: 'Requested Date',
            })}
          </Form>
        )}
        {clientId && (
          <Form className="request_form">
            {role ===
              'company' /* eslint operator-linebreak: ["error", "after"] */ &&
              details({
                value: clientdata?.employeeName,
                name: 'employeeName',
                label: 'Request from',
              })}
            {details({
              value: clientdata?.clientName,
              name: 'clientName',
              label: 'Client Name',
            })}

            {clientdata?.address &&
              details({
                value: clientdata?.address,
                name: 'address',
                label: 'Address',
              })}

            {details({
              value: change(clientdata?.status),
              name: 'status',
              label: 'Status',
            })}

            {details({
              value: clientdata?.action,
              name: 'action',
              label: 'Action',
            })}

            {details({
              value: clientdata?.email,
              name: 'email',
              label: 'Email',
            })}

            {details({
              value: moment(clientdata?.createdAt).format('DD-MM-YYYY hh:mm'),
              name: 'createdAt',
              label: 'Requested Date',
            })}
          </Form>
        )}
        {role === 'company' &&
          (clientdata?.status === 'pending' ||
            plantdata?.status === 'pending' ||
            initialValues?.status === 'pending') &&
          i.map((action) => {
            if (action === 'edit') {
              return (
                <div>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>Reason</label>
                    <textarea
                      className="request-area"
                      name="texts"
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="text-danger">
                      {errorShow ? formErrors.texts : null}
                    </p>
                  </div>
                  <div style={{ float: 'right', padding: '10px' }}>
                    <Button
                      className="common-approve"
                      variant="success"
                      onClick={() => handleSuccess()}
                    >
                      Approve
                    </Button>
                    <Button
                      className="common-approve"
                      variant="danger"
                      onClick={(e) => handleReject(e)}
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              );
            }
            return null;
          })}
      </Modal.Body>
    </Modal>
  );
}

export default RequestConfirmation;
