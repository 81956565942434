import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import RightArrow from '../icons/RightArrow';

const BotShowcase = () => {
    return (
        <div className='py-28 xl:py-20 sm:py-16'>
            <div className="container">
                <div className="flex items-center justify-between">
                    <h4 className="text-left font-epilogue text-7xl font-semibold text-textColor sm:text-center sm:text-6xl">
                        See what we have built
                    </h4>
                    <Link to={'/'} className='btn-secondary flex w-fit items-center gap-2 sm:hidden'>
                        <span className='block'>See all</span>
                        <span className='flex items-center justify-center'>
                            <RightArrow />
                        </span>
                    </Link>
                </div>
            </div>
            <div className="mt-14 sm:mt-12">
                <Swiper
                    init={true}
                    speed={6000}
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                        stopOnLastSlide: false
                    }}
                    loop={true}
                    freeMode={true}
                    centeredSlides={true}
                    slidesPerView={1.5}
                    slidesPerGroup={1}
                    minimumVelocity={1}
                    spaceBetween={20}
                    breakpoints={{

                        576: {
                            width: 576,
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                            spaceBetween: 40
                        },

                        1200: {
                            width: 1200,
                            slidesPerGroup: 3,
                            slidesPerView: 3,
                        },
                        1440: {
                            width: 1200,
                            slidesPerGroup: 4,
                            slidesPerView: 4,
                        }
                    }}
                    allowTouchMove={true}
                    disableOnInteraction={false}
                    modules={[Autoplay, FreeMode]}

                >
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((card, i) => (
                            <SwiperSlide key={i}>
                                <div className="py-1">
                                    <div className='rounded-radius-4 bg-sliderCard p-2 shadow-lightGrayBorder duration-500'>

                                        <div className='overflow-hidden rounded-radius-4'>
                                            <img className='w-full object-cover' src={`/assets/images/bot-showcase-1.png`} alt={`bot-showcase-${card}`} />
                                        </div>
                                        <div className="p-4">
                                            <Link to={'/'} className='text-base font-medium text-codGray sm:text-xs'>Assist visitors coming by your stall in an expo and automate lead generation →</Link>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>

        </div >
    )
}

export default BotShowcase
