/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import ReactGA from 'react-ga';
// import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { contactUsRequest } from '../../Stores/Action/Request';

function Contact() {
  const history = useLocation();
  const plans = [
    { value: 'basic', label: 'Basic' },
    { value: 'premium', label: 'Premium' },
    { value: 'gold', label: 'Gold' },
  ];

  const [selected, setSelected] = useState();
  const { register, reset, handleSubmit, formState } = useForm();

  const { errors } = formState;
  useEffect(() => {
    ReactGA.pageview('/contact', ['Tracker 1'], 'contact');
    if (history.state?.selected) {
      setSelected(plans.find((i) => i.value === history.state.selected));
    }
  }, []);

  const dispatch = useDispatch();

  const onSubmit1 = async (data) => {
    // eslint-disable-next-line
    data.type = selected.value;
    data.phone = parseInt(data.phone, 10);
    dispatch(contactUsRequest(JSON.stringify(data)));
    reset({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  return (
    <section className="content-wrapper">
      <div id="hero">
        <div className="hero">
          <div className="row">
            <div className="col-left">
              <div className="background" />
              {/* <h5>Lorem ipsum dolor</h5> */}
              <h1>Contact us</h1>
              <p>
                Disrupt the way people interact with machines to speed-up the
                world. Our mission is to provide AI solutions for business
                growth of our customers with creative design, development and to
                deliver a market defining high quality solutions that create
                value and reliable competitive advantage to customers around the
                globe.
              </p>
            </div>
            <div className="col-right">
              <div className="background" />
              <form
                //    onSubmit={handleSubmit((data) => setResult(JSON.stringify(data)))}>
                // onSubmit={handleSubmit(onSubmit)}
                onSubmit={handleSubmit(onSubmit1)}
              >
                <div className="form">
                  <div className="field">
                    {/* eslint-disable-next-line */}
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Enter Full Name"
                      name="name"
                      {...register('name', { required: true })}
                      className={`form-control ${
                        errors.name ? 'is-invalid' : ''
                      }`}
                    />
                    {/* <div className="invalid-feedback">{errors.name?.message}</div> */}
                    {errors.name && errors.name.type === 'required' && (
                      <div className="invalid-feedback">Name is required*</div>
                    )}
                    {/* {errors.name &&
                      errors.name.type === 'required' &&
                      errorMessage(required)} */}
                  </div>
                  <div className="field">
                    {/* eslint-disable-next-line */}
                    <label>Position</label>
                    <input
                      type="text"
                      placeholder="Enter your position"
                      name="name"
                      {...register('position', { required: true })}
                      className={`form-control ${
                        errors.name ? 'is-invalid' : ''
                      }`}
                    />
                    {/* <div className="invalid-feedback">{errors.name?.message}</div> */}
                    {errors.name && errors.name.type === 'required' && (
                      <div className="invalid-feedback">
                        position is required*
                      </div>
                    )}
                    {/* {errors.name &&
                      errors.name.type === 'required' &&
                      errorMessage(required)} */}
                  </div>
                  <div className="field">
                    {/* eslint-disable-next-line */}
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder="Enter Email"
                      name="email"
                      {...register('email', {
                        required: true,
                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      })}
                      className={`form-control ${
                        errors.email ? 'is-invalid' : ''
                      }`}
                    />
                    {errors.email && errors.email.type === 'required' && (
                      <div className="invalid-feedback">Email is required*</div>
                    )}
                    {errors.email && errors.email.type === 'pattern' && (
                      <div className="invalid-feedback">
                        Email is not valid*
                      </div>
                    )}
                  </div>
                  <div className="field">
                    {/* eslint-disable-next-line */}
                    <label>Number</label>
                    <input
                      type="number"
                      placeholder="Enter Number"
                      name="number"
                      {...register('phone', {
                        pattern: /^\d{10}$/,
                        required: true,
                        maxLength: 10,
                      })}
                      className={`form-control ${
                        errors.phone ? 'is-invalid' : ''
                      }`}
                    />
                    {errors.phone && errors.phone.type === 'required' && (
                      <div className="invalid-feedback">
                        Phone phone is required*
                      </div>
                    )}
                    {errors.phone && errors.phone.type === 'maxLength' && (
                      <div className="invalid-feedback">
                        Phone number must be 10 digits*
                      </div>
                    )}

                    {errors.phone && errors.phone.type === 'pattern' && (
                      <div className="invalid-feedback">
                        Phone number not should be in alphabets*
                      </div>
                    )}
                  </div>
                  <div className="field">
                    {/* eslint-disable-next-line */}
                    <label>Company Name</label>
                    <input
                      type="text"
                      placeholder="Enter Company Name"
                      name="company"
                      {...register('company', { required: true })}
                      className={`form-control ${
                        errors.name ? 'is-invalid' : ''
                      }`}
                    />
                    {/* <div className="invalid-feedback">{errors.name?.message}</div> */}
                    {errors.company && errors.company.type === 'required' && (
                      <div className="invalid-feedback">
                        Company Name is required*
                      </div>
                    )}
                    {/* {errors.name &&
                                            errors.name.type === "required" &&
                                            errorMessage(required)} */}
                  </div>
                  <div className="field plan">
                    {/* <h3>Select plan :</h3> */}
                    <ReactSelect
                      name="plan"
                      id="plan"
                      required
                      className="select"
                      options={plans}
                      value={selected}
                      // {...register('company', { required: true })}
                      onChange={(e) => {
                        setSelected(e);
                      }}
                    />
                  </div>
                  <div className="field">
                    {/* eslint-disable-next-line */}
                    <label>Description</label>
                    <textarea
                      rows="4"
                      cols="auto"
                      placeholder="Description"
                      name="description"
                      {...register('detail', { required: true })}
                      className={`form-control ${
                        errors.message ? 'is-invalid' : ''
                      }`}
                    />
                    {errors.message && errors.message.type === 'required' && (
                      <div className="invalid-feedback">
                        Description is required*
                      </div>
                    )}
                  </div>
                  <div className="field">
                    {/* eslint-disable-next-line */}
                    <label>Message</label>
                    <textarea
                      rows="4"
                      cols="auto"
                      placeholder="Message"
                      name="message"
                      {...register('message', { required: true })}
                      className={`form-control ${
                        errors.message ? 'is-invalid' : ''
                      }`}
                    />
                    {errors.message && errors.message.type === 'required' && (
                      <div className="invalid-feedback">
                        Message is required*
                      </div>
                    )}
                  </div>
                  <div className="submit">
                    <button type="submit">Submit</button>
                  </div>
                </div>
              </form>
              {/* <p>{JSON.stringify(errors)}</p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
