import axios from 'axios';
import { toast } from 'react-toastify';
import reduxStore from '../Store';
import { config } from '../../services/configHeader';
import {
  EMPLOYEE_LIST_VIEW_REQUEST,
  EMPLOYEE_LIST_VIEW_SUCCESS,
  EMPLOYEE_LIST_VIEW_FAIL,
  EMPLOYEE_VIEW_REQUEST,
  EMPLOYEE_VIEW_SUCCESS,
  EMPLOYEE_VIEW_FAIL,
  EMPLOYEE_ADD_REQUEST,
  EMPLOYEE_ADD_SUCCESS,
  EMPLOYEE_ADD_FAIL,
  EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAIL,
  MULTI_EMPLOYEE_ADD_REQUEST,
  MULTI_EMPLOYEE_ADD_SUCCESS,
  MULTI_EMPLOYEE_ADD_FAIL,
} from '../Types';

const employeeAPI = `${process.env.REACT_APP_API_URL}employee`;

const employeeList = (pageData) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_LIST_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const page = pageData
      ? `?pageSize=${pageData.pageSize}&pageCount=${pageData.pageCount}`
      : '';
    const { data } = await axios.get(
      `${employeeAPI}/employeeList${page}`,
      config,
    );

    await dispatch({ type: EMPLOYEE_LIST_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_LIST_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const employeeView = (employeeID) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_VIEW_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.get(
      `${employeeAPI}/employeeDetail?employeeId=${employeeID}`,
      config,
    );
    await dispatch({ type: EMPLOYEE_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_VIEW_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const addEmployee = (employeeData) => async (dispatch) => {
  try {
    dispatch({ type: EMPLOYEE_ADD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.post(
      `${employeeAPI}/addEmployee`,
      employeeData,
      config,
    );
    await dispatch({ type: EMPLOYEE_ADD_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(employeeList());
  } catch (error) {
    dispatch(employeeList());
    dispatch({
      type: EMPLOYEE_ADD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const editEmployee = (employeeData) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_EMPLOYEE_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.put(
      `${employeeAPI}/editEmployee`,
      employeeData,
      config,
    );
    await dispatch({ type: EDIT_EMPLOYEE_SUCCESS, payload: data });
    toast.success(data.message);
    dispatch(employeeList());
  } catch (error) {
    dispatch(employeeList());

    dispatch({
      type: EDIT_EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const deleteEmployee = (employeeData) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_EMPLOYEE_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.delete(`${employeeAPI}/deleteEmployee`, {
      ...config,
      data: employeeData,
    });

    await dispatch({ type: DELETE_EMPLOYEE_SUCCESS, payload: data });
    dispatch(employeeList());
    toast.success(data.message);
  } catch (error) {
    dispatch({
      type: DELETE_EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

const addMultiEmployee = (employeeData) => async (dispatch) => {
  try {
    dispatch({ type: MULTI_EMPLOYEE_ADD_REQUEST });

    config.headers.authorization = `Bearer ${
      reduxStore.getState().userDetails.user.token
    }`;
    const { data } = await axios.post(
      `${employeeAPI}/addMultiEmployee`,
      employeeData,
      config,
    );
    dispatch(employeeList());

    await dispatch({ type: MULTI_EMPLOYEE_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch(employeeList());
    dispatch({
      type: MULTI_EMPLOYEE_ADD_FAIL,
      payload:
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.error.message
        ? error.response.data.error.message
        : error.message,
    );
  }
};

export {
  employeeList,
  employeeView,
  addEmployee,
  editEmployee,
  deleteEmployee,
  addMultiEmployee,
};
