/* eslint-disable default-param-last */
import {
  FORECAST_CARDS_REQUEST,
  FORECAST_CARDS_SUCCESS,
  FORECAST_CARDS_FAIL,
  PERMISSION_REQUEST,
  PERMISSION_SUCCESS,
  PERMISSION_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_FORGOT_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_UPDATE_FAIL,
  // USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_OTP_REQUEST,
  USER_OTP_SUCCESS,
  USER_OTP_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_VIEW_FAIL,
  USER_VIEW_REQUEST,
  USER_VIEW_SUCCESS,
  PENALTY_CHART_REQUEST,
  PENALTY_CHART_SUCCESS,
  PENALTY_CHART_FAIL,
  PENALTY_CARDS_REQUEST,
  PENALTY_CARDS_SUCCESS,
  PENALTY_CARDS_FAIL,
  PENALTY_PIE_CHART_REQUEST,
  PENALTY_PIE_CHART_SUCCESS,
  PENALTY_PIE_CHART_FAIL,
  PREDICTION_REQUEST,
  PREDICTION_SUCCESS,
  PREDICTION_FAIL,
  PLANT_TYPE_REQUEST,
  PLANT_TYPE_SUCCESS,
  PLANT_TYPE_FAIL,
  PLANT_LIST_VIEW_REQUEST,
  PLANT_LIST_VIEW_SUCCESS,
  PLANT_LIST_VIEW_FAIL,
  PLANT_VIEW_REQUEST,
  PLANT_VIEW_SUCCESS,
  PLANT_VIEW_FAIL,
  // EDIT_PLANT_VIEW_REQUEST,
  EDIT_PLANT_VIEW_SUCCESS,
  EDIT_PLANT_VIEW_FAIL,
  CLIENT_LIST_VIEW_REQUEST,
  CLIENT_LIST_VIEW_SUCCESS,
  CLIENT_LIST_VIEW_FAIL,
  CLIENT_ADD_REQUEST,
  CLIENT_ADD_SUCCESS,
  CLIENT_ADD_FAIL,
  // PLANT_ADD_REQUEST,
  PLANT_ADD_SUCCESS,
  // IMAGE_UPDATE_REQUEST,
  IMAGE_UPDATE_SUCCESS,
  IMAGE_UPDATE_FAIL,
  PLANT_ADD_FAIL,
  // EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAIL,
  CLIENT_VIEW_REQUEST,
  CLIENT_VIEW_SUCCESS,
  CLIENT_VIEW_FAIL,
  EMPLOYEE_ADD_REQUEST,
  EMPLOYEE_ADD_SUCCESS,
  EMPLOYEE_ADD_FAIL,
  // EDIT_EMPLOYEE_REQUEST,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAIL,
  EMPLOYEE_LIST_VIEW_REQUEST,
  EMPLOYEE_LIST_VIEW_SUCCESS,
  EMPLOYEE_LIST_VIEW_FAIL,
  EMPLOYEE_VIEW_REQUEST,
  EMPLOYEE_VIEW_SUCCESS,
  EMPLOYEE_VIEW_FAIL,
  COMPANY_LIST_VIEW_REQUEST,
  COMPANY_LIST_VIEW_SUCCESS,
  COMPANY_LIST_VIEW_FAIL,
  COMPANY_VIEW_REQUEST,
  COMPANY_VIEW_SUCCESS,
  COMPANY_VIEW_FAIL,
  COMPANY_ADD_REQUEST,
  COMPANY_ADD_SUCCESS,
  COMPANY_ADD_FAIL,
  EDIT_COMPANY_SUCCESS,
  EDIT_COMPANY_FAIL,
  PERMISSION_LIST_REQUEST,
  PERMISSION_LIST_SUCCESS,
  PERMISSION_LIST_FAIL,
  PERMISSION_VIEW_REQUEST,
  PERMISSION_VIEW_SUCCESS,
  PERMISSION_VIEW_FAIL,
  WELCOME_SUCCESS,
  WELCOME_FAIL,
  COMPANY_FILTER_REQUEST,
  COMPANY_FILTER_SUCCESS,
  COMPANY_FILTER_FAIL,
  PLANT_FILTER_REQUEST,
  PLANT_FILTER_SUCCESS,
  PLANT_FILTER_FAIL,
  EMPLOYEE_FILTER_REQUEST,
  EMPLOYEE_FILTER_SUCCESS,
  EMPLOYEE_FILTER_FAIL,
  CLIENT_FILTER_REQUEST,
  CLIENT_FILTER_SUCCESS,
  CLIENT_FILTER_FAIL,
  ROLE_PERMISSION_REQUEST,
  ROLE_PERMISSION_SUCCESS,
  ROLE_PERMISSION_FAIL,
  ROLE_PERMISSION_EDIT_SUCCESS,
  ROLE_PERMISSION_EDIT_FAIL,
  PLANT_PENDING_REQUEST,
  PLANT_PENDING_SUCCESS,
  PLANT_PENDING_FAIL,
  CLIENT_COMPANY_PENDING_REQUEST,
  CLIENT_COMPANY_PENDING_SUCCESS,
  CLIENT_COMPANY_PENDING_FAIL,
  PLANT_TYPE_CHANGE,
  EMPLOYEE_CHANGE,
  COMPANY_CHANGE,
  CLIENT_CHANGE,
  PLANT_CHANGE,
  FILTER_FAIL,
  PLANT_REQUEST_DETAIL_REQUEST,
  PLANT_REQUEST_DETAIL_SUCCESS,
  PLANT_REQUEST_DETAIL_FAIL,
  COMPANY_REQUEST_DETAIL_REQUEST,
  COMPANY_REQUEST_DETAIL_SUCCESS,
  COMPANY_REQUEST_DETAIL_FAIL,
  CLIENT_REQUEST_DETAIL_REQUEST,
  CLIENT_REQUEST_DETAIL_SUCCESS,
  CLIENT_REQUEST_DETAIL_FAIL,
  CLIENT_PENDING_REQUEST,
  CLIENT_PENDING_SUCCESS,
  CLIENT_PENDING_FAIL,
  CHANGE_CONTINENT,
} from './Types';

export const welcomeReducer = (state = {}, action) => {
  switch (action.type) {
    case WELCOME_SUCCESS:
      return { loading: false, message: action.payload, success: true };
    case WELCOME_FAIL:
      return { loading: false, error: action.payload, success: false };
    // case USER_LOGOUT:
    //   return { success: true, loading: false };
    default:
      return state;
  }
};

export const dashboardFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case PLANT_TYPE_CHANGE:
      return { ...state, type: action.payload };
    case EMPLOYEE_CHANGE:
      return { ...state, employee: action.payload };
    case COMPANY_CHANGE:
      return { ...state, company: action.payload };
    case CLIENT_CHANGE:
      return { ...state, client: action.payload };
    case PLANT_CHANGE:
      return { ...state, plant: action.payload };
    case FILTER_FAIL:
      return { ...state, failed: action.payload };
    default:
      return state;
  }
};

export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true, success: false };
    case USER_LOGIN_SUCCESS:
      return { loading: false, user: action.payload.data, success: true };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_FORGOT_PASSWORD_REQUEST:
      return { loading: true, success: false };
    case USER_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, forgot: action.payload, success: true };
    case USER_FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_OTP_REQUEST:
      return { loading: true, success: false };
    case USER_OTP_SUCCESS:
      return { loading: false, otp: action.payload, success: true };
    case USER_OTP_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const permissionsReducer = (state = {}, action) => {
  switch (action.type) {
    case PERMISSION_REQUEST:
      return { loading: true, success: false };
    case PERMISSION_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case PERMISSION_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const rolePermissionsReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_PERMISSION_REQUEST:
      return { loading: true, success: false };
    case ROLE_PERMISSION_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case ROLE_PERMISSION_FAIL:
      return { loading: false, error: action.payload, success: false };
    case ROLE_PERMISSION_EDIT_SUCCESS:
      return { ...state, loading: false, data: action.payload, success: true };
    case ROLE_PERMISSION_EDIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const penaltyChartReducer = (state = {}, action) => {
  switch (action.type) {
    case PENALTY_CHART_REQUEST:
      return { loading: true, success: false };
    case PENALTY_CHART_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case PENALTY_CHART_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const penaltyCardsReducer = (state = {}, action) => {
  switch (action.type) {
    case PENALTY_CARDS_REQUEST:
      return { loading: true, success: false };
    case PENALTY_CARDS_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case PENALTY_CARDS_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const forecastingCardsReducer = (state = {}, action) => {
  switch (action.type) {
    case FORECAST_CARDS_REQUEST:
      return { loading: true, success: false };
    case FORECAST_CARDS_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case FORECAST_CARDS_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const penaltyPieChartReducer = (state = {}, action) => {
  switch (action.type) {
    case PENALTY_PIE_CHART_REQUEST:
      return { loading: true, success: false };
    case PENALTY_PIE_CHART_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case PENALTY_PIE_CHART_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const predictionReducer = (state = {}, action) => {
  switch (action.type) {
    case PREDICTION_REQUEST:
      return { loading: true, success: false };
    case PREDICTION_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case PREDICTION_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { loading: true, success: false };
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, reset: action.payload.data, success: true };
    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const profileUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VIEW_REQUEST:
      return { loading: true, success: false };
    case USER_VIEW_SUCCESS:
      return { loading: false, user: action.payload, success: true };
    case USER_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    // case USER_UPDATE_REQUEST:
    //   return { loading: true, success: false };
    case USER_UPDATE_SUCCESS:
      return { ...state, loading: false, edit: action.payload, success: true };
    case USER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const profileImageUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_VIEW_REQUEST:
      return { loading: true, success: false };
    case USER_VIEW_SUCCESS:
      return { loading: false, user: action.payload, success: true };
    case USER_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    // case IMAGE_UPDATE_REQUEST:
    //   return { loading: true, success: false };
    case IMAGE_UPDATE_SUCCESS:
      return { ...state, loading: false, edit: action.payload, success: true };
    case IMAGE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const plantReducer = (state = {}, action) => {
  switch (action.type) {
    case PLANT_LIST_VIEW_REQUEST:
      return { loading: true, success: false };
    case PLANT_LIST_VIEW_SUCCESS:
      return { loading: false, plant: action.payload, success: true };
    case PLANT_LIST_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    // case PLANT_ADD_REQUEST:
    //   return { loading: true, success: false };
    case PLANT_ADD_SUCCESS:
      return { ...state, loading: false, plant: action.payload, success: true };
    case PLANT_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const plantTypeFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case PLANT_TYPE_REQUEST:
      return { loading: true, success: false };
    case PLANT_TYPE_SUCCESS:
      return { loading: false, plantType: action.payload, success: true };
    case PLANT_TYPE_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const employeeFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_FILTER_REQUEST:
      return { loading: true, success: false };
    case EMPLOYEE_FILTER_SUCCESS:
      return { loading: false, employeeFilter: action.payload, success: true };
    case EMPLOYEE_FILTER_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const clientFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_FILTER_REQUEST:
      return { loading: true, success: false };
    case CLIENT_FILTER_SUCCESS:
      return { loading: false, clientFilter: action.payload, success: true };
    case CLIENT_FILTER_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const companyFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_FILTER_REQUEST:
      return { loading: true, success: false };
    case COMPANY_FILTER_SUCCESS:
      return { loading: false, companyFilter: action.payload, success: true };
    case COMPANY_FILTER_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const plantFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case PLANT_FILTER_REQUEST:
      return { loading: true, success: false };
    case PLANT_FILTER_SUCCESS:
      return { loading: false, plantFilter: action.payload, success: true };
    case PLANT_FILTER_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const plantDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case PLANT_VIEW_REQUEST:
      return { loading: true, success: false };
    case PLANT_VIEW_SUCCESS:
      return { loading: false, Plant: action.payload, success: true };
    case PLANT_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    // case EDIT_PLANT_VIEW_REQUEST:
    // return { loading: true, success: false };
    case EDIT_PLANT_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        edit: action.payload,
        success: true,
      };
    case EDIT_PLANT_VIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const clientListReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_LIST_VIEW_REQUEST:
      return { loading: true, success: false };
    case CLIENT_LIST_VIEW_SUCCESS:
      return { loading: false, client: action.payload, success: true };
    case CLIENT_LIST_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    case CLIENT_ADD_REQUEST:
      return { loading: true, success: false };
    case CLIENT_ADD_SUCCESS:
      return { ...state, loading: false, add: action.payload, success: true };
    case CLIENT_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const clientReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_VIEW_REQUEST:
      return { loading: true, success: false };
    case CLIENT_VIEW_SUCCESS:
      return { loading: false, Client: action.payload, success: true };
    case CLIENT_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    // case EDIT_CLIENT_REQUEST:
    // return { loading: true, success: false };
    case EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        edit: action.payload,
        success: true,
      };
    case EDIT_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const employeeListReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_LIST_VIEW_REQUEST:
      return { loading: true, success: false };
    case EMPLOYEE_LIST_VIEW_SUCCESS:
      return { loading: false, employee: action.payload, success: true };
    case EMPLOYEE_LIST_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    case EMPLOYEE_ADD_REQUEST:
      return { loading: true, success: false };
    case EMPLOYEE_ADD_SUCCESS:
      return { ...state, loading: false, add: action.payload, success: true };
    case EMPLOYEE_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const employeeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_VIEW_REQUEST:
      return { loading: true, success: false };
    case EMPLOYEE_VIEW_SUCCESS:
      return { loading: false, Employee: action.payload, success: true };
    case EMPLOYEE_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    // case EDIT_EMPLOYEE_REQUEST:
    //   return { loading: true, success: false };
    case EDIT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        edit: action.payload,
        success: true,
      };
    case EDIT_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const companyListReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_LIST_VIEW_REQUEST:
      return { loading: true, success: false };
    case COMPANY_LIST_VIEW_SUCCESS:
      return { loading: false, company: action.payload, success: true };
    case COMPANY_LIST_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    case COMPANY_ADD_REQUEST:
      return { loading: true, success: false };
    case COMPANY_ADD_SUCCESS:
      return { ...state, loading: false, add: action.payload, success: true };
    case COMPANY_ADD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_VIEW_REQUEST:
      return { loading: true, success: false };
    case COMPANY_VIEW_SUCCESS:
      return { loading: false, Company: action.payload, success: true };
    case COMPANY_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    // case EDIT_COMPANY_REQUEST:
    // return { loading: true, success: false };
    case EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        edit: action.payload,
        success: true,
      };
    case EDIT_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const permissionsListReducer = (state = {}, action) => {
  switch (action.type) {
    case PERMISSION_LIST_REQUEST:
      return { loading: true, success: false };
    case PERMISSION_LIST_SUCCESS:
      return { loading: false, permission: action.payload, success: true };
    case PERMISSION_LIST_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const permissionsViewReducer = (state = {}, action) => {
  switch (action.type) {
    case PERMISSION_VIEW_REQUEST:
      return { loading: true, success: false };
    case PERMISSION_VIEW_SUCCESS:
      return { loading: false, permission: action.payload, success: true };
    case PERMISSION_VIEW_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const plantRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case PLANT_PENDING_REQUEST:
      return { loading: true, success: false };
    case PLANT_PENDING_SUCCESS:
      return { loading: false, request: action.payload.data, success: true };
    case PLANT_PENDING_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const clientCompanyRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_COMPANY_PENDING_REQUEST:
      return { loading: true, success: false };
    case CLIENT_COMPANY_PENDING_SUCCESS:
      return { loading: false, request: action.payload.data, success: true };
    case CLIENT_COMPANY_PENDING_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const plantDetailRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case PLANT_REQUEST_DETAIL_REQUEST:
      return { loading: true, success: false };
    case PLANT_REQUEST_DETAIL_SUCCESS:
      return { loading: false, plant: action.payload.data, success: true };
    case PLANT_REQUEST_DETAIL_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const clientCompanyDetailRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_REQUEST_DETAIL_REQUEST:
      return { loading: true, success: false };
    case COMPANY_REQUEST_DETAIL_SUCCESS:
      return { loading: false, company: action.payload.data, success: true };
    case COMPANY_REQUEST_DETAIL_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const clientDetailRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_REQUEST_DETAIL_REQUEST:
      return { loading: true, success: false };
    case CLIENT_REQUEST_DETAIL_SUCCESS:
      return { loading: false, client: action.payload.data, success: true };
    case CLIENT_REQUEST_DETAIL_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};
export const clientListRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_PENDING_REQUEST:
      return { loading: true, success: false };
    case CLIENT_PENDING_SUCCESS:
      return { loading: false, client: action.payload.data, success: true };
    case CLIENT_PENDING_FAIL:
      return { loading: false, error: action.payload, success: false };
    case USER_LOGOUT:
      return { success: true, loading: false };
    default:
      return state;
  }
};

export const continentReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_CONTINENT:
      return action.payload;
    default:
      return state;
  }
};
