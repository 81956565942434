// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from '@nivo/bar';
import { BarTooltip } from './tooltip';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
function MyResponsiveBar({ data /* see data tab */ }) {
  return (
    <ResponsiveBar
      data={data}
      keys={['penalty']}
      indexBy="block"
      margin={{ top: 10, right: 10, bottom: 60, left: 50 }}
      groupMode="grouped"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'category10' }}
      borderRadius={8}
      borderColor={{
        from: 'color',
        modifiers: [['darker', '1.4']],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        legend: '',
        ticksPosition: 'before',
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 4,
        tickRotation: 0,
        legend: '',
      }}
      enableGridY={false}
      enableLabel={false}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={[]}
      tooltip={BarTooltip}
      role="application"
      ariaLabel="Nivo bar chart demo"
      padding={0.7}
      // barAriaLabel={function (e) {
      //   return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
      // }}
    />
  );
}

export default MyResponsiveBar;
