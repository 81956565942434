import React from 'react';

export default function details(props) {
  const { value, name, label } = props;
  return (
    <div className="flex form-input-controller">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={`${name}-box`}
        id={`${name}-label`}
        className={`${name}-label`}
      >
        {label}
      </label>
      <div>
        <p style={{ bottom: '-13px', left: '0' }}>:</p>
        <input id={`${name}-box`} type={name} name={name} value={value} />
      </div>
    </div>
  );
}
