import React from "react";
import { LandingStats, OurStory, Team } from "../components/aboutPage";

const AboutUsPage = () => {
  return (
    <div id="aboutUsPage" className="relative bg-codGray">
      <div className="pointer-events-none absolute inset-0 z-[0]">
        <img
          src="/assets/images/bg-abstract-pattern.svg"
          alt="hero-bg-pattern"
          className="w-full object-center"
        />
      </div>
      <LandingStats />
      <OurStory />
      <Team />
    </div>
  );
};

export default AboutUsPage;
