import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, CloseButton, Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { addMultiEmployee } from '../../Stores/Action';
import './index.scss';

function AddMultiEmployee(props) {
  const [bulkEmployeeData, setBulkEmployeeData] = useState([]);
  const dispatch = useDispatch();
  const { onHide, setFile } = props;

  const handleHide = async () => {
    setBulkEmployeeData('');
    onHide();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // setErrorShow(true);
    // if (Object.keys(formErrors).length === 0) {
    dispatch(addMultiEmployee(bulkEmployeeData));
    // setErrorShow(false);
    // setformErrors('');
    onHide();
    // }
  };
  const readExcel = (file) => {
    // if (
    //   file.type === 'application/vnd.ms-excel' ||
    //   file.type === '.csv' ||
    //   file.type === '.xlsx'
    // ) {
    const promise = new Promise((resolve, reject) => {
      const filrReader = new FileReader();
      filrReader.readAsArrayBuffer(file);
      filrReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);

        FileReader.onerror = (error) => {
          reject(error);
        };
      };
    });

    promise.then((dt) => {
      setBulkEmployeeData(dt);
    });
    // }
  };
  useEffect(() => {
    const files = setFile.files[0];
    readExcel(files);
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Modal
        {...props}
        backdropClassName="back-drop-plant"
        className="multi-modal"
        style={{ height: '100vh' }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="multi">
          <div className="multi-header">
            <h2
              style={{
                fontWeight: 'bolder',
                color: 'GrayText',
              }}
            >
              Add Employee
            </h2>
            {/* <AddBulkPlant /> */}
            <CloseButton style={{ float: 'right' }} onClick={handleHide} />
          </div>

          {bulkEmployeeData.length >
            0 /* eslint operator-linebreak: ["error", "after"] */ && (
            <Table className="multi-employee-page" hover>
              <thead>
                <div className="tabHead">
                  <tr>
                    <th>User Name</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>GST Number</th>
                    <th>Pan Number</th>
                    <th>Address</th>
                    <th>Zip Code</th>
                  </tr>
                </div>
              </thead>
              <tbody>
                <div className="tabBody">
                  {bulkEmployeeData.map((item) => (
                    <tr>
                      {/* <tr onClick={() => handlePlantNav(item.plantuuid)}> */}
                      <td>{item.userName}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.gst}</td>
                      <td>{item.pan}</td>
                      <td>{item.address}</td>
                      <td>{item.pinCode}</td>
                    </tr>
                  ))}
                </div>
              </tbody>
            </Table>
          )}
          <div className="btn-reset">
            <Button
              type="button"
              style={{ margin: '0 24px' }}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddMultiEmployee;
