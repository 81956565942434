import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import ReactGA from 'react-ga';
import Pages from './pages';
import Home from './pages/Home';
import SignIn from './pages/Auth/SignIn';
import ForgotPassword from './pages/Auth/ForgotPassword';
import NotFound from './components/NotFound';
import Otp from './pages/Auth/OTP/otp';
import ServerNotFound from './components/ServerNotFound';

import './styles/style.scss';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/*" element={<Home />} />
        <Route exact path="/login" element={<SignIn />} />
        <Route exact path="/forgot" element={<ForgotPassword />} />
        <Route exact path="/otp" element={<Otp />} />
        <Route exact path="/dashboard/*" element={<Pages />} />
        <Route exact path="/ServerNotFound" element={<ServerNotFound />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
export default App;
